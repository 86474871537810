import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CardNFT extends Component {
    render() {
        const { image, title, description, link, style } = this.props;

        return (
            <div style={style} className="card_nft">
                <div className="img_nft">
                    <img
                        style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:30}}
                        src={image}
                        alt={`NFT titled ${title}`}
                    />
                </div>

                <div className="text_nft">
                    <p className="h3_nft">{title}</p>
                    <p className="p_nft">{description}</p>

                    <a href={link} target='_blank' className="icon-box_nft">
                        <p className="span_nft">Go</p>
                    </a>
                </div>
            </div>
        );
    }
}

CardNFT.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    style: PropTypes.string
};

export default CardNFT;
