import React, { Component } from 'react';
import Header from "../HeaderSection/Header";
import Breadcrumb from "../Blogs/Breadcrumb";
import CardNFT from "../GlobalCard/CardNFT";
import Footer from "../FooterSection/Footer";
import {Helmet} from "react-helmet";

const initData = {
    preHeading: "Awesome",
    preHeadingspan: "Interface",
    heading: "Discover Collect and Sell WOOOO NFT",
    headingText: "WOOOO NFT A non-fungible token (NFT) is a unique identifier that can cryptographically assign and prove ownership of WOOOO digital coin.",
}

const data = [
    {
        image: "/img/nft1.jpeg"
    },
    {
        image: "/img/nft2.jpeg"
    },
    {
        image: "/img/nft3.jpeg"
    },
    {
        image: "/img/nft4.jpeg"
    },
    {
        image: "/img/nft5.jpeg"
    },
    {
        image: "/img/nft6.jpeg"
    },
    {
        image: "/img/nft7.jpeg"
    },
    {
        image: "/img/nft8.jpeg"
    },
    {
        image: "/img/nft9.jpeg"
    },

]

class NFT extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up"/>
                </div>
                <div className="main">
                    <Helmet>
                        <title>Woooo - NFT</title>
                        <link rel="canonical" href="https://woooo.world/nft" />
                    </Helmet>
                    <Header imageData={"/img/logo-white.png"}/>
                    <Breadcrumb title="WOOOO NFT"/>
            <section id="screenshots" className="section screenshots-area ptb_100">
                <div className="container">
                    <div className="row">

                        {/* Content Section (35% width) */}
                        <div className="col-12 col-lg-4">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10">
                                    {/* Section Heading */}
                                    <div className="section-heading text-center">
                                        <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize">{this.state.initData.heading}</h2>
                                        <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p>
                                        <p className="d-block d-sm-none mt-4">{this.state.initData.headingTexttwo}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Slider Section (65% width) */}
                        <div className="col-12 col-lg-8">
                            {/* App Screenshot Slider Area */}
                            <div className="app-screenshots">
                                {/* Single Screenshot Item */}
                                {data.map((item, idx) => {
                                    return (
                                        <div key={`so_${idx}`} className="single-screenshot">
                                            <a href={item.image} data-fancybox="images">
                                                <img src={item.image} alt="" />
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
                    <h2 style={{textAlign:'center',color:"rgb(32, 166, 232)"}}>WOOOO NFT Only In Limited Numbers!
                    </h2>
                    <p style={{width:'80%',textAlign:'center',margin:'10px auto', fontSize:18}}>WOOOO NFT A non-fungible token (NFT) is a unique identifier that can cryptographically assign and prove ownership of WOOOO digital coin.
                    </p>
                    <div style={{width:'100%',maxWidth:'80%',margin:'60px auto',}}>
                        <h2 style={{textAlign:'center',color:"rgb(32, 166, 232)"}}>Market Place
                        </h2><h3 style={{textAlign:'center',marginBottom:40}}>Trade them live on these Market Places     </h3>
                        <p style={{width:'80%',margin:'10px auto', fontSize:18,textAlign:'left'}}>WOOOO NFT is a modern-day collectible that can be bought and sold online in the existing marketplace of open Sea and Binance. It represents the ownership of WOO Asset, securely recorded on the blockchain to ensure a one-of-a-kind asset. </p>
                        <p style={{width:'80%',margin:'30px auto', fontSize:18,textAlign:'left'}}>
                            WOOOO launches only a limited amount of NFT based on our WOO Asset, WOOOO NFT are available in 10 different colors, shades, and designs. ALL available in marketplaces. Considering the future target of Woooo users, only one million NFT will become very rare in the market and will be sought by our users worldwide. <br/>

                            So, buy now while it lasts
                        </p>


                        <div className='flex-direction-mobile' style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <CardNFT image='/img/opersea.jpeg' title="OpenSea" description="The world’s first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items. Buy, Sell without paying Gas Fee." link='https://opensea.io/Woooo_Company' style={{marginRight: 40}} />
                            <CardNFT image='/img/Binance.jpg' title="Binance" description="Binance offers a relatively secure, versatile way to invest in and trade cryptocurrencies. Binance could be overwhelming for beginners and experienced traders alike." link='https://bscscan.com/address/0x89a1a8644463917fae1b5125a03450b5f7fcfe0a' />
                        </div>
                    </div>

                    <div style={{width:'100%',maxWidth:'80%',margin:'100px auto'}}>
                        <h2 style={{textAlign:'center',color:"rgb(32, 166, 232)"}}>Supported Wallets
                        </h2><h3 style={{textAlign:'center',marginTop:10,marginBottom:40}}>Store your Valuable NFT on your Favourite Wallet
                    </h3>
                        <div className='flex-direction-mobile' style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <CardNFT image='/img/metamask.png' title="MetaMask" description="MetaMask is a software cryptocurrency wallet used to interact with the Ethereum blockchain. It allows users to access their Ethereum wallet through a browser extension or mobile app, which can then be used to interact with decentralized applications." link='https://metamask.io/' style={{marginRight: 40}} />
                            <CardNFT image='/img/trustwallet.png' title="Trust Wallet" description="Trust Wallet is crypto wallet. You can send, receive and store Bitcoin and many other cryptocurrencies including NFTs safely and securely with the Trust Wallet mobile app. You can even use Trust Wallet to earn interest on your crypto, play blockchain games.You can send, receive and store Bitcoin." link='https://trustwallet.com/'  style={{marginRight: 40}} />
                            <CardNFT image='/img/coinomi.jpg' title="Coinomi" description="Coinomi is a security-first multi-asset wallet that provides native support and true ownership for a total of total of 1770 coins and tokens, available in 168 fiat currency representations and 25 languages.Coinomi is a security-first multi-asset wallet." link='https://www.coinomi.com/en/' />
                        </div>
                        <div className='flex-direction-mobile' style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:40}}>
                            <CardNFT image='/img/Numio.jpg' title="Numio" description="Numio provides crypto-asset storage and payment solutions for consumers. It features Numio Pay, a mobile wallet app for identity management, P2P transfers, and two-factor authentication.Numio provides crypto-asset storage and payment solutions." link='https://www.numio.one/' style={{marginRight: 40}} />
                            <CardNFT image='/img/mathwallet.jpg' title="Math Wallet" description="MathWallet is a multi-platform universal crypto wallet that enables storage of all BTC, ETH, Polkadot, Filecoin, EOS, Solana, BinanceChain, Cosmos tokens. Math Wallet also offers cross-chain token exchanges and a multi-chain DApp shop. You can create wallets in-app." link='https://mathwallet.org/en-us/'  style={{marginRight: 40}} />
                            <CardNFT image='/img/MyCrypto.png' title="MyCrypto" description="Binance offers a relatively secure, versatile way to invest in and trade cryptocurrencies. Binance could be overwhelming for beginners and experienced traders alike.MyCrypto is an open-source, client-side tool for generating ether wallets, handling ERC-20 tokens, and interacting with the blockchain more easily. " link='https://mycrypto.com/' />
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default NFT;
