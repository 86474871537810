import React, {Component} from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import FooterSection from '../FooterSection/Footer';
import {Helmet} from "react-helmet";

class Industries extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up"/>
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Helmet>
                        <title>Woooo - Industries (Education, Healthcare and Government)</title>
                        <link rel="canonical" href="https://woooo.world/industries" />
                    </Helmet>
                    <Header imageData={"/img/logo-white.png"}/>
                    <Breadcrumb title="Industries (Education, Healthcare and Government)"/>
                    <div style={{
                        padding: '2em',
                        width: '100%',
                        maxWidth: '1440px',
                        background: '#fff',
                        borderRadius: 20,
                        position: 'relative',
                        zIndex: 9,
                        margin: "0px auto",
                        marginTop: '-6em',
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column"
                    }}>

                        <section style={{position:'relative',zIndex:4}} className="section service-area overflow-hidden ptb_100">
                            <div className="container">
                                <div style={{flexDirection:'row-reverse'}} className="row justify-content-center align-items-center flex-direction-mobile">
                                    <div data-aos="fade-right" className="col-12 col-lg-6 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-5 pt-lg-0 px-0 px-lg-4">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-4">INTEGRATIONS TO MAKE IT BETTER</h2>

                                            <div className="service-text">
                                                Bring the functionality of the office to your place with HD smooth video meetings, phone calls, screen sharing, and live messaging on your personal collaboration device with highly secured medium. Reach a wider audience, communicate nation-wide with live translation, and simplify training and onboarding with WOOOO webinars.
                                            </div>


                                        </div>
                                    </div>
                                    <div data-aos="zoom-out-up" className="col-12 col-md-8 col-lg-6 order-1 order-lg-2">
                                        {/* Service Thumb */}
                                        <div  className="service-thumb service-thumb-image mx-auto">
                                            <img src='/img/icons.png' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section id='health' style={{position:'relative',zIndex:4}} className="section service-area overflow-hidden ptb_100">
                            <div className="container">
                                <div  className="row justify-content-center align-items-center flex-direction-mobile">
                                    <div data-aos="fade-right" className="col-12 col-lg-6 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-5 pt-lg-0 px-0 px-lg-4">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-4">Health Care</h2>

                                            <div className="service-text">
                                                WOOOO Plans to collaborate with the Hospitals in Japan to provide live medical support to the patients and visitors.

                                            </div>


                                        </div>
                                    </div>
                                    <div data-aos="zoom-out-up" className="col-12 col-md-8 col-lg-6 order-1 order-lg-2">
                                        {/* Service Thumb */}
                                        <div  className="service-thumb service-thumb-image mx-auto">
                                            <img src='/img/health_2.jpg' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section id='gov' style={{position:'relative',zIndex:4}} className="section service-area overflow-hidden ptb_100">
                            <div className="container">
                                <div style={{flexDirection:'row-reverse'}} className="row justify-content-center align-items-center flex-direction-mobile">
                                    <div data-aos="fade-right" className="col-12 col-lg-6 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-5 pt-lg-0 px-0 px-lg-4">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-4">Smart City</h2>

                                            <div className="service-text">
                                                WOOOO together with smart city Japan, plans to provide live information services to the visitors on the street side as a city guide.
                                            </div>


                                        </div>
                                    </div>
                                    <div data-aos="zoom-out-up" className="col-12 col-md-8 col-lg-6 order-1 order-lg-2">
                                        {/* Service Thumb */}
                                        <div  className="service-thumb service-thumb-image mx-auto">
                                            <img src='/img/SmartCity.png' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section id='education' style={{position:'relative',zIndex:4}} className="section service-area overflow-hidden ptb_100">
                            <div className="container">
                                <div  className="row justify-content-center align-items-center flex-direction-mobile">
                                    <div data-aos="fade-right" className="col-12 col-lg-6 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-5 pt-lg-0 px-0 px-lg-4">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-4">Education</h2>

                                            <div className="service-text">
                                                No matter where the training is conducted, Woooo can help students, faculty, and staff participate in education collaboration and management from their place.
                                            </div>


                                        </div>
                                    </div>
                                    <div data-aos="zoom-out-up" className="col-12 col-md-8 col-lg-6 order-1 order-lg-2">
                                        {/* Service Thumb */}
                                        <div  className="service-thumb service-thumb-image mx-auto">
                                            <img src='/img/Education.png' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <FooterSection/>
                </div>
            </div>
        );
    }
}

export default Industries;
