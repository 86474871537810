import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import ThemeFour from "../themes/theme-four";
import BlogTwoColumn from "../components/Blogs/BlogTwoColumn";
import BlogThreeColumn from "../components/Blogs/BlogThreeColumn";
import BlogLeftSidebar from "../components/Blogs/BlogLeftSidebar";
import BlogRightSidebar from "../components/Blogs/BlogRightSidebar";
import BlogDetailsLeftSidebar from "../components/Blogs/BlogDetailsLeftSidebar";
import BlogDetailsRightSidebar from "../components/Blogs/BlogDetailsRightSidebar";
import Reviews from "../components/ReviewSection/Reviews";
import Pricing from "../components/PricingSection/Pricing";
import DownloadPage from "../components/DownloadSection/DownloadPage";
import SubscribePage from "../components/SubscribeSection/SubscribePage";
import ThankYou from "../components/InnerSection/ThankYou";
import ComingSoon from "../components/InnerSection/ComingSoon";
import Login from "../components/Accounts/Login";
import Signup from "../components/Accounts/Signup";
import Forgot from "../components/Accounts/Forgot";
import Faq from "../components/FaqSection/Faq";
import ErrorPage from "../components/ErrorPage/404";
import ContactPage from "../components/ContactSection/ContactPage";
import Privacy from "../components/PrivacySection/Privacy";
import Roadmap from "../components/RoadmapSection/Roadmap";
import PressRelease from "../components/PressReleaseSection/PressRelease";
import GettingStarted from "../components/GettingStartedSection/GettingStarted";
import Blockchain from "../components/BlockchainSection/Blockchain";
import NFT from "../components/NFTSection/NFT";
import Industries from "../components/IndustriesSection/Industries";
import UserGuide from "../components/UserGuide/UserGuide";
import CEO from "../components/CEO/CEO";
import WooooDexSection from "../components/WooooDexSection/WooooDexSection";
import WooooExplorerSection from "../components/WooooExplorerSection/WooooExplorerSection";
import WooooCoinSection from "../components/WooooCoinSection/WooooCoinSection";
import Terms from "../components/TermsSection/Terms";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={ThemeFour} />
            <Route path="/blog-two-column" component={BlogTwoColumn} />
            <Route path="/blog" component={BlogThreeColumn} />
            <Route path="/blog-left-sidebar" component={BlogLeftSidebar} />
            <Route path="/blog-right-sidebar" component={BlogRightSidebar} />
            <Route path="/blog-details-left-sidebar" component={BlogDetailsLeftSidebar} />
            <Route path="/blog-details-right-sidebar" component={BlogDetailsRightSidebar} />
            <Route path="/reviews" component={Reviews} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/download-page" component={DownloadPage} />
            <Route path="/subscribe-page" component={SubscribePage} />
            <Route path="/thank-you" component={ThankYou} />
            <Route path="/coming-soon" component={ComingSoon} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/faq" component={Faq} />
            <Route path="/error-page" component={ErrorPage} />
            <Route path="/contact-page" component={ContactPage} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="/roadmap" component={Roadmap} />
            <Route path="/press-release" component={PressRelease} />
            <Route path="/getting-started" component={GettingStarted} />
            <Route path="/blockchain" component={Blockchain} />
            <Route path="/woooo-dex" component={WooooDexSection} />
            <Route path="/woooo-explorer" component={WooooExplorerSection} />
            <Route path="/woooo-coin" component={WooooCoinSection} />
            <Route path="/nft" component={NFT} />
            <Route path="/industries" component={Industries} />
            <Route path="/user-guide" component={UserGuide} />
            {/*<Route path="/ceo" component={CEO} />*/}
            <Route path="*" component={ErrorPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;
