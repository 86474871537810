import React, {Component} from 'react';
import $ from 'jquery';
import CEO from "../CEO/CEO";
import {  logEvent } from "firebase/analytics";
import { analytics } from '../../utils/firebaseConfig'

class Header extends Component {
    componentDidMount() {
        this.handleScrolling();
    }

    handleScrolling() {
        $('a.scroll').on('click', function (event) {
            event.preventDefault();
            const hash = this.getAttribute('href').split('/').pop();
            let target = $(hash);
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset()?.top
                }, 1000);
            } else {
                window.location.href = '/' + hash;
            }
        });
    }

    handleClick = () => {
        window.open("https://app.woooo.world/", "_blank");
        logEvent(analytics, 'sign_up_clicked', {
            source: window.location.hostname || 'woooo.world',
            timestamp: new Date().toISOString()
        });
    };

    handleClickMeeting = () => {
        window.open("https://app.woooo.world/meeting-features", "_blank");
    };

    render() {
        return (
            <header data-aos="fade-down" className="navbar navbar-sticky navbar-expand-lg navbar-dark">
                <div className="container position-relative">
                    <a className="navbar-brand" href="/">
                        <div className='d-flex justify-content-between align-items-center'>
                            <img className="navbar-brand-regular" style={{width: 150}} src="/img/logowhite.png"
                                 alt="brand-logo"/>
                        </div>
                        <img className="navbar-brand-sticky" style={{width: 150}} src="/img/logoDar.png"
                             alt="sticky brand-logo"/>
                    </a>
                    <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="navbar-inner">
                        {/*  Mobile Menu Toggler */}
                        <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <nav>
                            <ul className="navbar-nav" id="navbar-nav">

                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Solutions
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-solution"
                                        aria-labelledby="navbarDropdownMenuLink">
                                        <div className="dropdown-item-solution">
                                            <h5 style={{padding: '.5em'}}>Features</h5>
                                            <a className="dropdown-item dropdown-item-cus" href="/#features">Live
                                                Translation
                                                <p style={{textTransform: 'capitalize', fontSize: 12}}>Meeting and Chat
                                                    services with AI based <br/>live translation</p>
                                            </a>
                                            <a className="dropdown-item dropdown-item-cus" href="/#features">Video
                                                Conferencing
                                                <p style={{textTransform: 'capitalize', fontSize: 12}}>The futuristic
                                                    smart calling and video <br/> conferencing application</p>
                                            </a>
                                            <a className="dropdown-item dropdown-item-cus" href="/#features">Direct
                                                Calling
                                                <p style={{textTransform: 'capitalize', fontSize: 12}}>One to one direct
                                                    calls</p>
                                            </a>
                                            <a className="dropdown-item dropdown-item-cus" href="/#features">Chat &
                                                Messaging
                                                <p style={{textTransform: 'capitalize', fontSize: 12}}>Simple chat and
                                                    messaging makes sure <br/> you are connected to the world
                                                    all <br/> the time every time</p>
                                            </a>
                                            <a className="dropdown-item dropdown-item-cus" href="/woooo-coin">Woooo
                                                Coin
                                                <p style={{textTransform: 'capitalize', fontSize: 12}}>Be a part of
                                                    WOOOO by <br/> mining WOOOO</p>
                                            </a>
                                        </div>
                                        <div className="dropdown-item-solution">
                                            <h5 style={{padding: '.5em'}}>Industries</h5>

                                            <a className="dropdown-item dropdown-item-cus" href="/industries/#education">Education
                                                <p style={{textTransform: 'capitalize', fontSize: 12}}>Expand
                                                    traditional classrooms in the <br/> cloud</p>
                                            </a>
                                            <a className="dropdown-item dropdown-item-cus" href="/industries/#health">Health
                                                Care
                                                <p style={{textTransform: 'capitalize', fontSize: 12}}>Collaborative
                                                    telehealth technology <br/> & workflows
                                                </p>
                                            </a>
                                            <a className="dropdown-item dropdown-item-cus" href="/industries/#gov">Government
                                                <p style={{textTransform: 'capitalize', fontSize: 12}}>Increase
                                                    productivity & engagement for <br/> all agencies</p>
                                            </a>
                                        </div>
                                    </ul>
                                </li>

                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        About
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li>
                                            <a className="dropdown-item" href="/roadmap">Road Map</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/getting-started">Getting Started</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item scroll" href="/#contact">Contact</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item scroll" href="/press-release">Press Release</a>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <a className="dropdown-item scroll" href="/ceo">Meet Our CEO</a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <a className="dropdown-item scroll" href="/#faq">FAQ's</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/privacy">Privacy</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Blockchain
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-cus"
                                        aria-labelledby="navbarDropdownMenuLink">
                                        <div className="dropdown-row-cus">
                                            <li>
                                                <a className="dropdown-item dropdown-item-cus" href="/woooo-coin">WOOOO
                                                    Coin
                                                    <p style={{textTransform: 'capitalize', fontSize: 12}}>Woooo
                                                        introduces the mining <br/> concept with a reward given <br/> to
                                                        the miners</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item dropdown-item-cus" href="/nft">WOOOO NFT
                                                    <p style={{textTransform: 'capitalize', fontSize: 12}}>Discover
                                                        Collect and Sell WOOOO <br/> NFT</p>
                                                </a>
                                            </li>
                                        </div>
                                        <div className="dropdown-row-cus">
                                            <li>
                                                <a className="dropdown-item dropdown-item-cus" href="/blockchain">WOOOO
                                                    BlockChain
                                                    <p style={{textTransform: 'capitalize', fontSize: 12}}>Ignite global privacy with <br/> Woooo Blockchain - join the  <br/>Woooo chain to change  <br/> the world
                                                    </p>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item dropdown-item-cus" href="/user-guide">WOOOO
                                                    Wallet
                                                    <p style={{textTransform: 'capitalize', fontSize: 12}}>Neural
                                                        Our wallet approach for a <br/>  better
                                                        and faster execution</p>
                                                </a>
                                            </li>
                                        </div>
                                        <div className="dropdown-row-cus">
                                            <li>
                                                <a className="dropdown-item dropdown-item-cus" href="/woooo-dex">WOOOO
                                                    Dex
                                                    <p style={{textTransform: 'capitalize', fontSize: 12}}>Effortless, secure, and pure magic. <br/> Experience the future of <br/> decentralized trading.
                                                    </p>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item dropdown-item-cus" href="/woooo-explorer">WOOOO
                                                   Explorer
                                                    <p style={{textTransform: 'capitalize', fontSize: 12}}>our explorer is your window into <br/> the blockchain's <br/> heartbeat.
                                                    </p>
                                                </a>
                                            </li>

                                        </div>

                                    </ul>

                                </li>
                                {/*<li className="nav-item">*/}
                                {/*    <a className="nav-link scroll" href="/blog-three-column">Blogs</a>*/}
                                {/*</li>*/}
                            </ul>
                        </nav>
                    </div>
                    <ul style={{justifyContent:'center',alignItems:'center'}} className="navbar-nav navbar-nav-extended" id="navbar-nav">
                        <button onClick={this.handleClick} style={{padding:'0.6em 2em', border:'1px solid rgba(255,255,255,0.2)',borderRadius:8, marginRight:'1em',outline:'none',background:'#fff'}}>
                            Sign Up Free
                        </button>
                        <li className="nav-item">
                            <a className="nav-link" href="https://app.woooo.world/meeting-features" onClick={()=>{
                                logEvent(analytics, 'meeting_button_clicked', {
                                    source: window.location.hostname || 'woooo.world',
                                    timestamp: new Date().toISOString()
                                });
                            }} target={'_blank'}>Meeting</a>
                        </li>
                    </ul>
                </div>
            </header>
        );
    }
}

export default Header;


{/*<li className="nav-item dropdown">*/
}
{/*    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/
}
{/*    Pages*/
}
{/*    </a>*/
}
{/*    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">*/
}
{/*        <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="/#" data-toggle="dropdown">Inner Pages<span className="badge badge-pill badge-warning ml-2">New</span></a>*/
}
{/*            <ul className="dropdown-menu">*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/pricing">Pricing</a>*/
}
{/*                </li>*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/download-page">Download</a>*/
}
{/*                </li>*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/subscribe-page">Newsletter</a>*/
}
{/*                </li>*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/thank-you">Thank you</a>*/
}
{/*                </li>*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/coming-soon">Coming Soon</a>*/
}
{/*                </li>*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/error-page">404</a>*/
}
{/*                </li>*/
}
{/*            </ul>*/
}
{/*        </li>*/
}
{/*        <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="/#" data-toggle="dropdown">Blog Pages</a>*/
}
{/*            <ul className="dropdown-menu">*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/blog-two-column">Blog- 2 Column</a>*/
}
{/*                </li>*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/blog-three-column">Blog- 3 Column</a>*/
}
{/*                </li>*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/blog-left-sidebar">Blog- Left Sidebar</a>*/
}
{/*                </li>*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/blog-right-sidebar">Blog- Right Sidebar</a>*/
}
{/*                </li>*/
}
{/*            </ul>*/
}
{/*        </li>*/
}
{/*        <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="/#" data-toggle="dropdown">Blog Details</a>*/
}
{/*            <ul className="dropdown-menu">*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/blog-details-left-sidebar">Blog Details- Left Sidebar</a>*/
}
{/*                </li>*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/blog-details-right-sidebar">Blog Details- Right Sidebar</a>*/
}
{/*                </li>*/
}
{/*            </ul>*/
}
{/*        </li>*/
}
{/*        <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="/#" data-toggle="dropdown">Accounts<span className="badge badge-pill badge-warning ml-2">New</span></a>*/
}
{/*            <ul className="dropdown-menu">*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/login">Login</a>*/
}
{/*                </li>*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/signup">Signup</a>*/
}
{/*                </li>*/
}
{/*                <li>*/
}
{/*                    <a className="dropdown-item" href="/forgot">Reset Password</a>*/
}
{/*                </li>*/
}
{/*            </ul>*/
}
{/*        </li>*/
}
{/*        <li>*/
}
{/*            <a className="dropdown-item" href="/reviews">Reviews</a>*/
}
{/*        </li>*/
}
{/*        <li>*/
}
{/*            <a className="dropdown-item" href="/faq">FAQ</a>*/
}
{/*        </li>*/
}
{/*        <li>*/
}
{/*            <a className="dropdown-item" href="/contact-page">Contact</a>*/
}
{/*        </li>*/
}
{/*        <li>*/
}
{/*            <a className="dropdown-item disabled" href="/#">More Coming Soon</a>*/
}
{/*        </li>*/
}
{/*    </ul>*/
}
{/*</li>*/
}
