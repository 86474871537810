import React, {Component} from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import FooterSection from '../FooterSection/Footer';
import {Helmet} from "react-helmet";

class Privacy extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up"/>
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Helmet>
                        <title>Woooo - PRIVACY POLICY</title>
                        <link rel="canonical" href="https://woooo.world/privacy" />
                    </Helmet>
                    <Header imageData={"/img/logo-white.png"}/>
                    <Breadcrumb title="PRIVACY POLICY"/>
                    <div style={{
                        padding: '2em',
                        width: '100%',
                        maxWidth: '1440px',
                        background: '#fff',
                        borderRadius: 20,
                        position: 'relative',
                        zIndex: 9,
                        margin: "0px auto",
                        marginTop: '-6em',
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <h3 style={{textAlign: 'center', margin: 20,color:"rgb(32, 166, 232)"}}>1.Introduction</h3>
                        <p>We, Woooo Corporation (“Woooo,” “we,” “us,” or “our”), will collect information related to
                            individuals (“Personal Data”) in providing our Woooo communication app (“Woooo App”) and all
                            products, apps, services and websites related to the Woooo App (collectively, the
                            “Services”). We consider the protection of Personal Data to be one of the most important
                            management issues as a responsibility of an Internet content service company. We will act in
                            compliance with the laws and regulations applicable in countries and regions where we are
                            conducting business (such laws and regulations are hereinafter collectively referred to as
                            the “Applicable Laws”), and will make efforts to properly protect Personal Data based on
                            this Privacy Policy (this “Policy”).
                            This “Policy” explains what kind of Personal Data we will collect, and how we will use and
                            share such Personal Data, and how you can manage your own Personal Data.</p>

                        <h3 style={{textAlign: 'center', margin: 20,color:"rgb(32, 166, 232)"}}>2.Applicable scope of this Policy</h3>
                        <p>This Policy applies to all Personal Data that is collected in relation to our Services
                            regardless of the country or region where our Services are being used.
                            <b> Target services and service provider </b>
                            This Policy applies to all Personal Data that is collected in relation to our Services.
                            We may apply individual privacy policies or additional provisions to terms of use or this
                            Policy (collectively, “Individual Policies”) to specific services. The Individual Policies
                            explain what kind of Personal Data we will collect, and how we will use and share such
                            Personal Data, for such specific services, and the other policies that are applicable to
                            such specific services. If there is any inconsistency between the subject matter of the
                            Individual Policies and this Policy, the Individual Policies shall prevail.
                            In connection with the application of this Policy, Woooo is the controller of the processing
                            of Personal Data. <b> Applicable countries and regions</b>
                            We will process Personal Data only to the extent permitted under the Applicable Laws
                            according to this Policy and only within the scope described in this Policy.
                            While we may provide various translations of this Policy as a reference, to the extent
                            permitted under Applicable Laws, the English version applies to users using our Services in
                            all countries or regions.</p>


                        <h3 style={{textAlign: 'center', margin: 20,color:"rgb(32, 166, 232)"}}>3.Personal Data to be collected</h3>
                        <p>We will collect the following Personal Data:</p>
                        <li>Information that you provide to us such as your phone number/email that you register at the
                            time of creating an account, and profile information that you set at the time of using our
                            Services.
                        </li>
                        <li>Information related to your status of use of our Services such as the text, image, video and
                            other contents that you post, and your access log.
                        </li>
                        <li> Public information containing Personal Data such as on Woooo published on the internet We
                            will collect the following types of Personal Data in providing our Services. If you do not
                            provide certain types of information such as your phone number which needs to be registered
                            for using our Services, you may not be able to use all or a part of our Services.
                        </li>
                        <b>Personal Data to be provided by you</b>
                        <p>
                            Information to be registered at the time of creating an account Before you start using our
                            Services, we may ask you to provide your contact information for creating a Woooo account
                            (“Account”). For example:
                        </p>
                        <li>In order to create an Account, you need to register your email / phone number.</li>
                        <p>
                            Please note that we assign an internal identification code (“Internal Identifier”) to each
                            Account created by our users.
                            Information to be registered for accessing your Account from multiple devices or
                            transferring your Account from one device to another.
                            If you wish to access your Account from multiple devices, or wish to transfer your Account
                            from one device to another, you may need to register your phone number or email address in
                            addition to the information that you registered at the time that you created your Account.
                            Information required for communication and transaction
                            When you wish to use a specific service to purchase a product, make an inquiry or
                            participate in a promotional event or survey, we may ask you to provide information required
                            for communicating with you and conducting transactions with you depending on the nature of
                            the transaction, and collect and store such information.
                        </p>
                        <p>For example:</p>
                        <li>In order for us to answer any requests or questions that you send to our customer support,
                            we may ask you to provide information required for identifying and investigating the event
                            or resolving the problem, such as your contact information including your phone number or
                            email address, and details of the trouble that you have encountered.
                        </li>
                        <li>If you choose to participate in surveys or promotional events, we may ask you to provide
                            information such as your name, address, phone number, email address, gender, and date of
                            birth in order to verify your identity, enable you to participate in a drawing, and to
                            deliver any prizes you may win.
                        </li>
                        <li>If you purchase a product or a service, you will be required to provide information for
                            making the payment of such product or service. Since credit card settlements are processed
                            by appropriate third-party payment agencies, we will not retain your credit card number or
                            security code.
                            Profile and information related to your friend relationship
                        </li>
                        <li>When you use our Services, we will collect the profile that you set, and information related
                            to your friend relationship.
                        </li>
                        <p><b>Profile information </b>Profile information includes information such as the icon image,
                            display name, status message, search ID (“Woooo ID”) and birthday that you set or disclose
                            in order to connect to other users. Profile information (excluding information that you set
                            as “private”) will be disclosed and made available to other users and third parties such as
                            companies operating an official account. A Woooo ID is an identification name set in your
                            Account to make it easier for your friends to find you in our Services, and your friends can
                            find you through your Woooo ID. You may also choose not to allow other users to contact you
                            using your Woooo ID.</p>

                        <p><b>Address book </b> We offer an “Auto Add Friends” feature which automatically adds other
                            users as your friends in our Services when you upload information of your friend in your
                            address book on your device. We will access only the phone numbers registered in your mobile
                            device’s address book only when you have enabled this feature.
                            We will use the phone numbers in your address book only for automatically adding your
                            friends, recommending possible friends, and preventing unauthorized use.</p>
                        <b>Personal Data to be collected in connection with your use of our Services</b>
                        <b>Status of use of our Services
                        </b>
                        <p>We automatically collect information related to when and how you used our Services. The
                            collected information includes the status of use of various features such as the date and
                            time that you activated our individual apps, length of time that you used our individual
                            services, results of searches conducted in the apps, additional services that were
                            purchased, and contents that were viewed or tapped.
                        </p>
                        <p>For example, standard contents such as emoji, stickers and location information and
                            non-standard contents such as text, image, video and audio that you post are all sent via
                            our server. In addition, external information such as the recipient of the contents, data
                            format, date and time of the posting, and other information on how you are interacting with
                            your counterparty is also recorded in the server. URL information that you tapped or clicked
                            in our Services is also recorded in the server.</p>
                        <p>Furthermore, in distributing reactions or replies to the posts of other users, we collect and
                            use information related to how you reacted to the posts of other users. This information
                            includes the action that you took against the distribution by other users, and the time and
                            date of such action, such as whether you viewed the text, image or video of messages that
                            you received, and whether you replied for the posts of users on your Woooo.
                        </p>
                        <p>In principle, we will not use private posts, such as messages exchanged between users, for
                            any purpose other than distributing such posts. However, in certain countries or regions
                            (*1) for specific services, we may use a part of such information upon obtaining your
                            additional consent for the provision and maintenance of services, development and
                            improvement of services, prevention of unauthorized use, and provision of optimized
                            contents</p>
                        <b>Location information
                        </b>
                        <p>We may collect the location information of your device when you share your location
                            information with your friends, or in order for us to provide optimized search results to
                            you. When you do not accept sending your location information by your mobile device
                            settings, location information will not be sent to us.
                            <br/> Even when you do not accept sending your location information, we may estimate your
                            approximate location from network information such as your IP address.
                        </p>
                        <b>Apps, browsers, devices and network information
                        </b>
                        <p>We will collect information related to the device, browser and the app that you are using (ad
                            identifier for identifying the device/browser, Cookie ID and device type, OS, language/time
                            zone setting, browser type, app version, etc.), and network information (telecommunications
                            carrier’s name, IP address, etc.).
                            <br/> We may also associate the collected ad identifier with the Internal Identifier.
                        </p>
                        <b>Personal Data collected from third parties
                        </b>
                        <p>
                            We may collect Personal Data from third parties including our group companies (referring to
                            our subsidiaries or affiliates, wherein the definition thereof includes domestic and
                            overseas subsidiaries and affiliates according to the “Regulation on Terminology, Forms, and
                            Preparation Methods of Financial Statements” of Japan; hereinafter the same).
                            <br/> For example, we will collect Personal Data from third parties in the following cases.

                        </p>
                        <b>Personal Data collected from group companies
                        </b>
                        <p>We may collect your Personal Data from our group companies according to their terms or
                            policies. For the privacy policy that is applicable to the individual services provided by
                            our group companies, please confirm the same in the individual services.
                        </p>
                        <b>Personal Data uploaded by other users
                        </b>
                        <p>There may be cases where a certain user, in using our Services, uploads information
                            containing Personal Data such as a photo that includes a third party other than that user,
                            and we collect such information.
                        </p>
                        <b>Personal Data that is directly collected from third-party services
                        </b>
                        <p>We may collect login records from plug-ins of third-party websites and apps equipped with a
                            plug-in feature such as “Send by Woooo” or “Like”, and URL information of websites equipped
                            with a plug-in feature that you visited while being logged onto the Woooo app on your
                            browser by associating such login records and URL information with your Woooo Account. Such
                            collected data will be used for understanding how you are using our Services, as well as for
                            the maintenance and improvement, security, and prevention of unauthorized or fraudulent use
                            or abuse of such Services. When you are logged out of the Woooo App, the foregoing
                            information will be used only for the purpose of security and prevention of unauthorized or
                            fraudulent use or abuse in a form where individuals cannot be specified. Furthermore, in
                            certain countries or regions, when our business partner uses our Services, we may collect
                            the following Personal Data.</p>
                        <b>Collection of Personal Data from public information
                        </b>
                        <p>We will collect public information, such as on Woooo news published on the internet, for use
                            in search services, etc. If the collected public information contains Personal Data, we may
                            use such information for registration in the search engine index or display of search
                            results.

                        </p>

                        <h3 style={{textAlign: 'center', margin: 20,color:"rgb(32, 166, 232)"}}>4.Purpose of use of Personal Data
                        </h3>

                        <p>We will use Personal Data for the following purposes:
                        </p>
                        <li>Provision and maintenance of services</li>
                        <li>Development and improvement of services and contents;</li>
                        <li>Prevention of unauthorized use such as unauthorized access; and/or</li>
                        <li>Provision of contents optimized for you.</li>
                        <p>We use Personal Data to provide our Services to users, for utilization in research and
                            development to provide safer and better services, and to provide services that are strongly
                            linked to users. We will not process the Personal Data we obtain beyond the scope of the
                            purposes of use, except to the extent that consent has been obtained from you or as
                            permitted by the Applicable Laws. In addition, we will take appropriate measures to ensure
                            that Personal Data is not used for any other purpose. The specific uses of Personal Data are
                            as follows.</p>
                        <b>Provision and maintenance of services
                        </b>
                        <p>We will use information required for providing services that you requested, answering your
                            inquiries, and hosting promotional events. For example, in the following cases, we will use
                            Personal Data for the provision and maintenance of services.
                        </p>
                        <li>To verify your identity, through comparison with your registered information, when you log
                            onto the Woooo App or succeed your Account in changing your device;
                        </li>
                        <li>To post messages or convey the status of “read” of posted messages;</li>
                        <li>To confirm the history of messages viewed with a certain device on a different device;</li>
                        <li>To set or disclose profiles for building and managing connections between users, and to
                            search for other users using the registered information, or enable such search;
                        </li>
                        <li>To process invoices when a user purchases a product or uses a paid service;</li>
                        <li>To examine your status of use of our Services in order to reply to your inquiry to our
                            customer support;
                        </li>
                        <li>To deliver any prizes you may have won in a promotional event to your designated address;
                            and/or
                        </li>
                        <li>To notify you of important changes to our Services such as the revision of terms, planned
                            discontinuation of services, or change of features.
                        </li>

                        <b>Development and improvement of services and contents
                        </b>
                        <p>We will use Personal Data for the development and improvement of our Services for providing
                            better services, products and contents to users toward the future. For example, in the
                            following cases, we will use Personal Data for the development and improvement of services
                            and contents:
                        </p>
                        <li>To improve our Services by creating statistics and models related to the use of our
                            Services;
                        </li>
                        <li>To develop new services and contents by understanding the trend from the purchase history of
                            stickers;
                        </li>
                        <li>To improve the screen design by understanding the frequency that each tab in the app is
                            being used; or
                        </li>
                        <li>To conduct surveys related to our services that we are currently providing, or may provide
                            in the future.
                        </li>
                        <b>Security and prevention of unauthorized use
                        </b>
                        <p>We will use Personal Data and promote security measures and measures against unauthorized use
                            to maintain an environment where our Services can be used at all times, and users can use
                            our Services with a sense of security on a daily basis. For example, in the following cases,
                            we will use Personal Data for the security and prevention of unauthorized use:
                        </p>
                        <li>To confirm whether there is any breach of terms of use, any illegal act, unauthorized access
                            or any other unauthorized use;
                        </li>
                        <li>To monitor and confirm the details of reports and illegal postings, etc., and to develop
                            tools for preventing unauthorized use by ways such as analyzing the details of such reports
                            and illegal postings, etc.; or
                        </li>
                        <li>To verify facts or contact you in the event of any unauthorized use or the possibility of
                            any unauthorized use.
                        </li>
                        <b>Provision of contents optimized for you
                        </b>
                        <p>We will utilize Personal Data for providing recommended content to each user. For example, in
                            the following cases, we will use Personal Data for providing optimized contents to users:
                        </p>
                        <li>To display recommendations of other stickers with a strong relevance with the purchased
                            sticker;
                        </li>
                        <li>When there is another user who has added as a friend an official account that is the same as
                            the official account that you have added as a friend, to introduce another official account
                            that such user has added as a friend;
                        </li>
                        <li>To measure the effectiveness of the distributed ad; or</li>
                        <li>To send and distribute official account messages and surveys, etc. to you by ways such as
                            associating or combining information obtained from our business partners, such as your
                            identifier (Internal Identifier, ad identifier, etc.), hashed telephone number and email
                            address, with your Personal Data that we possess. <br/>
                            Furthermore, in certain countries or regions (*3), we will use Personal Data for
                            distributing recommended contents, such as optimized ads, to users. Such use includes the
                            following examples: <li>To distribute information of strong relevance to your current
                                location regarding facilities, attractions, coupons, etc. to be provided through an
                                official account;</li></li>
                        <li>To introduce information of events being conducted and valuable coupons that can be used,
                            near your current location or places where you often visit;
                        </li>
                        <li>To display ads related to our business partner’s website that you accessed;</li>
                        <li>To estimate attributes such as the gender, age group, and interests of users from the
                            official accounts that have been added as a friend, purchased stickers, Services that are
                            often used, contents that were recently posted on Woooo , and history of service use such as
                            search words that were recently used, and distribute our ads or third-party ads that may of
                            interest to users having similar attributes;
                        </li>
                        <h3 style={{textAlign: 'center', margin: 20,color:"rgb(32, 166, 232)"}}>5.Provision of Personal Data</h3>
                        <p>We will not provide, disclose or share Personal Data to or with third parties unless we have
                            obtained your consent or such provision, disclosure or sharing of Personal Data is permitted
                            under Applicable Laws. We may transfer Personal Data to a third country without any data
                            protection laws which are equivalent to those of the country or region where you live when
                            we have obtained your consent or such transfer of Personal Data is permitted under
                            Applicable Laws. <br/>
                            In principle, we will not provide Personal Data to third parties unless we have obtained
                            your consent or such provision of Personal Data is permitted under Applicable Laws.
                            However, in the following cases, we may provide Personal Data to third parties. The
                            recipients of Personal Data that we provide include third parties such as our
                            subcontractors, subsidiaries, and affiliates located in countries or regions other than
                            where you are residing. (Please click here to confirm the information of the systems
                            regarding the protection of Personal Data in the countries or regions in which third-party
                            recipients of Personal Data are located.) <br/>
                            In order to provide our services in a smooth manner, subcontractors, subsidiaries and
                            affiliates, etc. may be added to the recipients of Personal Data that we provide. We will
                            notify you via this Policy upon the addition of any country or region in which the
                            third-party recipient of Personal Data is located.
                            In providing Personal Data, we may transfer Personal Data to a third country without any
                            data protection laws which are equivalent to those of the country or region where you live
                            (as of January 23, 2019, the European Commission has determined that Japan has secured
                            sufficient protection standards regarding Personal Data). In the foregoing case, we will
                            adopt standard contractual clauses or other means pertaining to data protection approved in
                            your country or region, and transfer Personal Data to a third country according to the
                            requirements of Applicable Laws.</p>
                        <b>Public disclosure by users themselves
                        </b>
                        <p>The profile information that you registered will be processed as public information.
                        </p>
                        <b>Messages that you posted, posts made on Woooo.
                        </b>
                        <p>The foregoing information may be copied, stored or spread by the recipient, and accessed by
                            an unintended third-party recipient, due to the nature of digital data. When setting your
                            profile or posting on our Services, please pay attention to the contents and scope of
                            disclosure of such posts.
                        </p>
                        <b>Sharing of Personal Data among group companies
                        </b>
                        <p>Woooo is the administrator in sharing Personal Data under the laws of Japan. We will take
                            measures to have our group companies with which the Personal Data is shared comply with the
                            eight (8) principles under the Privacy Guide Woooo by ways such as prescribing matters
                            related to confidentiality and data protection in the contract with such group companies,
                            and thereby establish a system for properly managing Personal Data.Countries and regions to
                            which Personal Data is transferred: Japan
                        </p>
                        <b>Succession of business
                        </b>
                        <p>If there is any transaction between companies pertaining to our business to which this Policy
                            is applicable, such as acquisition, merger or change of business entity, Personal Data may
                            also be transferred to the successor of the business to the extent permitted under
                            Applicable Laws.
                        </p>
                        <b>Provision of services and ad distribution through collaboration with other companies
                        </b>
                        <p>In cases where you use the services of our business partner, we may provide, to that business
                            partner, information to be used by the business partner for identifying you (in certain
                            countries or regions (*5), in addition to the Internal Identifier, including an ad
                            identifier, device information such as the language setting, and attribute information).
                        </p>
                        <li>When our business partner is equipped with an additional service such as the Woooo App login
                            function, your Internal Identifier, profile information, email address or phone number may
                            be shared with the business partner once you use such additional service. In the foregoing
                            case, we will ask for your permission regarding the provision of Personal Data.
                        </li>
                        <li>n certain countries or regions (*5), we may provide and disclose your Internal Identifier,
                            part of your device information (ad identifier, language setting, etc.), IP address, and
                            your attributes to our business partner, including companies located in the following
                            countries or regions, engaged in the distribution of ads, effectiveness measurement and
                            analysis to distribute information, with stronger relevance to you. Information such as your
                            name, address, and phone number, which can be used for communicating with you or confirming
                            your identity, will not be provided without your separate declaration of intent. For details
                            regarding such strongly relevant ads, please also read “Optimization of services by
                            attributes”.
                            The processing of Personal Data by our business partner shall be pursuant to the terms or
                            policies prescribed by each business partner. <br/>
                            Our business partners receiving Personal Data which are located in the following countries
                            and regions are taking measures in compliance with the eight (8) principles under the
                            Privacy Guide Woooo.
                        </li>
                        <b>Compliance and cooperation with public agencies
                        </b>
                        <p>If we receive a request pursuant to legal proceedings such as a warrant, or if there is
                            imminent danger to someone’s life or property such as a suicide threat or a bomb threat, we
                            may disclose Personal Data to third parties including law enforcement agencies such as the
                            police, or the court, pursuant to Applicable Laws. For details regarding the disclosure of
                            Personal Data to public agencies

                        </p>

                        <h3 style={{textAlign: 'center', margin: 20,color:"rgb(32, 166, 232)"}}>6.Safety control of Personal Data
                        </h3>

                        <p>We will take strict technical and organizational security measures in managing the collected
                            Personal Data. In order to continue to improve our protection of Personal Data, we will
                            review and bolster our internal rules and regulations in accordance with changes in laws and
                            social norms. <br/>
                            Unless you request the deletion of your Account, in principle, we will retain most of your
                            information. Once we receive your request to delete your Account, we will delete your
                            information according to Applicable Laws and internal rules after retaining such information
                            for a given period of time.</p>

                        <b>Security measures
                        </b>
                        <p>We are taking strict technical and organizational security measures in managing the collected
                            Personal Data for respecting and protecting your privacy and confidentiality of your
                            Personal Data, and preventing unauthorized access to your Personal Data. These measures
                            include safety control measures based on industry standards for preventing unauthorized
                            access to Personal Data, and disclosure, unauthorized use, falsification or destruction of
                            Personal Data.
                        </p>
                        <p>For example, we are taking the following measures.
                        </p>
                        <li>Provision of encryption feature of messages</li>
                        <li>Strict access control based on a need-to-know basis</li>
                        <li>24/7 security monitoring</li>
                        <li>External authentication for objectively evaluating our security measures</li>
                        <li>R&D of new security technologies</li>
                        <p>Furthermore, we will never provide any means for fraudulently accessing Personal Data to a
                            third party.However, because no method of electronic transmission or method of data storage
                            is perfect or impenetrable, we cannot guarantee that your Personal Data will be absolutely
                            safe from intrusion during transmission or while stored in our systems. <br/>
                            To help protect your privacy and confidentiality of your Personal Data, we also need your
                            help. Please do not share your password with others, or use the same passwords you use for
                            other services. Also, please notify us in the event you suspect any unauthorized use of your
                            Account or any other breach of security via our Contact Form.</p>

                        <b>Storage location of Personal Data
                        </b>
                        <p>In providing our Services, we may store your Personal Data outside the country or region
                            where you reside. When that happens, we will take measures to ensure your Personal Data is
                            transferred according to the applicable laws.
                        </p>
                        <b>Storage period of Personal Data
                        </b>
                        <p>Woooo collects and retains Personal Data to provide our Services according to your request,
                            fulfill any of the applicable purposes of use described in this Policy, or to comply with
                            Applicable Laws. Unless you request the deletion of your Account, in principle, we will
                            retain most of your Personal Data. Once your Account is deleted, we will process your
                            Personal Data properly according to Applicable Laws and our internal rules after retaining
                            such Personal Data for a given period of time for the following purposes:
                        </p>
                        <li>to reply to inquiries;</li>
                        <li>to resolve disputes or collect fees;</li>
                        <li>to detect and deter fraudulent use of, or abuse of, our Services; or</li>
                        <li>to comply with Applicable Laws.</li>
                        <p>Otherwise, we prescribe the storage period of Personal Data according to the following
                            standards.
                        </p>
                        <li>We will not in any way use highly private information such as texts, images, videos and
                            audio data sent in a talk room between users for any purpose other than the distribution of
                            messages (including retransmission in the event of a communication failure or
                            synchronization of messages from multiple devices) unless we have obtained your consent or
                            such use is permitted under Applicable Laws. While we will retain the foregoing information
                            for a minimum period, such information will subsequently be automatically deleted from our
                            server.
                        </li>
                        <li>If you have expressly declared your intent to store your Personal Data, we may respect your
                            intent and store your Personal Data for a long period. For example, we are providing a
                            feature, such as the Keep feature of our Woooo App, to users for expressly storing/deleting
                            information.
                        </li>
                        <li>If a storage period is prescribed under Applicable Laws or the like, we will store Personal
                            Data according to such storage period.
                        </li>
                        <li>We may continue to retain Personal Data in a format in which individuals cannot be
                            identified after the lapse of the storage period prescribed pursuant to Applicable Laws and
                            our internal rules.
                        </li>

                        <h3 style={{textAlign: 'center', margin: 20,color:"rgb(32, 166, 232)"}}>7. Your choices regarding Personal Data
                        </h3>
                        <p>You have rights regarding your Personal Data processed by us, such as the right to access
                            your Personal Data and the right to correct your Personal Data, to the extent permitted
                            under Applicable Laws. <br/>
                            We are exerting efforts to provide, in our Services, features which enable you to personally
                            edit your profile, and set and manage the collection, use and sharing of your Personal
                            Data. <br/>
                            To the extent permitted under Applicable Laws, regarding your Personal Data that we process,
                            you have the right to access, the right to rectification, the right to erasure, the right to
                            restriction of processing, the right to withdrawal of your consent, the right to data
                            portability, the right to confirm if we process any Personal Data of yours, and the right to
                            request a review of any automated decisions that may be made as a result of the processing.
                            In addition, to the extent permitted under Applicable Laws, you also have the right to
                            object to the processing of your Personal Data that we process in certain cases. <br/>
                            We are exerting efforts to provide, in our Services, features which enable you to personally
                            manage your Personal Data on the Woooo App, edit your profile, set the collection, use and
                            sharing of your Personal Data, and set and manage your Personal Data including the scope of
                            disclosure of posts, opt-out of ads, and deletion of your Account. Please periodically
                            confirm that no old information or inaccurate information is remaining, and properly update
                            your Personal Data.
                            Specifically, we provide the following features:</p>

                        <li>Confirmation and change of public profile information, and setting of the Woooo ID</li>
                        <li>Setting and change of phone number and email address, deletion of email address, and de-link
                            of your Facebook account
                        </li>
                        <li>Whether to allow other users to contact you by using your Woooo ID</li>
                        <li>Whether to allow other users, who already have your phone number, to reach you via the Woooo
                            App
                        </li>
                        <li>Whether to add contacts automatically using the address book in your device</li>
                        <li>Whether to allow third parties to view your Personal Data retained by your friend
                        </li>
                        <li>We also provide the following features in certain countries or regions:</li>
                        <li>Whether to allow the use of information for improving our services (*1)</li>
                        <li>Deletion of location information (*2)</li>
                        <li>Whether to receive strongly relevant ads (*3)</li>
                        <p>If you wish to exercise your right against us to disclose your Personal Data that cannot be
                            confirmed in our Services, correct or delete your Personal Data that is contrary to facts,
                            notify you of the purpose of use of your Personal Data, or other rights permitted under
                            Applicable Laws, you may contact us using the Contact Form. <br/>
                            After verification of your identity, we will respond to your request within a reasonable
                            period of time, and in accordance with relevant laws. If you would like to send us a written
                            request, please refer to our mailing address indicated later. <br/>
                            If, however, permitted under Applicable Laws, we may not be able to respond to your request
                            when any of the following applies:</p>
                        <li>disclosure is likely to harm the life, body, property, or other rights or interests of yours
                            or another individual;
                        </li>
                        <li>disclosure is likely to seriously impede the proper execution of our business;</li>
                        <li>disclosure violates other laws and regulations;</li>
                        <li>when you failed to verify your identity upon your disclosure request; or</li>
                        <li>we are otherwise permitted under Applicable Laws to refuse your request.</li>
                        <p>If you no longer wish to use our Services or if you withdraw your consent to Woooo’s
                            processing of your Personal Data which is necessary for us to provide our Services, you may
                            choose to delete your entire Account. However, the withdrawal of your consent will not in
                            any way affect the lawfulness of our processing of your Personal Data based on your consent
                            that was given before the foregoing withdrawal <br/>
                            If you have any requests or complaints related to our use of your Personal Data, please
                            contact us at any time using the Contact Form.Unless prohibited under Applicable Laws, if
                            you exercise any of your rights hereunder, we may charge a fee, in accordance with the
                            procedures defined separately.</p>
                        <h3 style={{textAlign: 'center', margin: 20,color:"rgb(32, 166, 232)"}}>8.Use of third-party modules and Cookies
                        </h3>
                        <p>We may use cookies and third-party SDK for fulfilling the purposes described in this Policy.
                        </p>
                        <b>Third-party modules
                        </b>
                        <p>We may install modules of a third-party software development kit (SDK) in our Services for
                            the following purposes:
                        </p>
                        <li>to analyze the status of use of our Services; and With regard to your Personal Data that is
                            processed by a third-party through a module provided by such a third party, the privacy
                            policy of such third party will apply. <b> Cookie and other analytical tools</b></li>
                        <p>A cookie is a small text file that is stored on your computer when visiting a website. This
                            text file stores information that can be read by the website operator when visiting the
                            website again at a later date. We may use cookies and similar web tracking technologies (web
                            beacon, pixel etc.) for the following purposes:</p>
                        <li>to store your login state, authenticate visitors, maintain sessions and take security
                            measures;
                        </li>
                        <li>to store language settings and latest date and time of access, and improve user-friend
                            Woooo;
                        </li>
                        <li>to provide better services by comprehending the number of accesses and the mode of use;</li>
                        <p>You can control whether or not to accept cookies based on browser settings or by using other
                            tools. If, however, you deactivate cookies or limit the ability to set cookies, you may not
                            be able to obtain the full benefit of the website or access all of its functions, which may
                            limit your overall user experience.</p>
                        <b>Information related to use of Google Analytics
                        </b>
                        <p>In order to improve our Services, we use Google Analytics to measure our Services such as our
                            website. Google may set cookies or read existing cookies to collect data. At such time,
                            information such as the URL of the page that you accessed and IP address will be
                            automatically sent to Google. We may use such information to comprehend the status of use of
                            our Services or use such information in our Services. For the processing of data by Google
                            in Google Analytics, please read the service terms of use of Google Analytics and Google’s
                            privacy policy.
                        </p>
                        <b>Information related to use of True Depth API provided by Apple
                        </b>
                        <p>Woooo may use Apple‘s TrueDepth camera to collect additional information from your device‘s
                            camera in order to improve the quality of camera and video call that we provide, or improve
                            function such as effects. However, this information is processed in real time for delivering
                            and improving these features only. This information is not saved on our servers or shared
                            with any third parties.
                        </p>


                        <h3 style={{textAlign: 'center', margin: 20,color:"rgb(32, 166, 232)"}}>9.Other important information
                        </h3>
                        <p>We may modify this Policy. The latest version of the Policy will always be posted on this
                            site, and we encourage you to review the contents of the latest version of the Policy
                            carefully. When we make any material changes to this Policy, we will notify you through
                            reasonable means.
                        </p>
                        <b>Privacy of children
                        </b>
                        <p>The Services are intended for a general audience. If a minor is to use our Services and
                            provides Personal Data, the minor must do so with the consent of his/her guardian. You
                            represent and warrant that you have the right capacity and legal capacity required for using
                            our Services. If you are a minor, you represent and warrant that you are using our Services
                            with the consent of your guardian. We have voluntarily imposed age restrictions on certain
                            Services in cases where we are unable to confirm that you are of a certain age. <br/>
                            We understand the special necessity to protect the Personal Data collected from minors in
                            the U.S., Europe, or Thailand (under the age of 13 in the U.S., under the age of 16 in
                            Europe, and under the age of 11 in Thailand) using our Services, and we do not knowingly
                            collect any Personal Data from minors. <br/>
                            If, however, you believe that we have collected Personal Data from minors, then please let
                            us know via the Contact Form or by writing to the address indicated below. If we learn that
                            we have inadvertently collected Personal Data from minors, we will deactivate the relevant
                            Account(s) and will take reasonable measures to promptly delete such Personal Data from our
                            records.
                        </p>
                        <b>Inquiries</b>
                        <p>Other than as referred to herein, we will be the data controller of Personal Data collected
                            through our Services to which this Policy applies. If you have any questions or inquiries
                            about this Policy or have any questions, complaints, concerns or comments about the
                            processing of Personal Data in our Services, please contact us via the Contact Form or by
                            writing to:
                        </p>
                        <h4>Woooo Corporation
                        </h4>
                        <b>Changes to this Policy
                        </b>
                        <p>We may modify this Policy from time to time, and the latest version of the privacy policy
                            will always be posted on this site. When we make any material changes to this Policy, we
                            will notify you on our Services, or by other reasonable means. We encourage you to review
                            the contents of the latest version of the privacy policy carefully. <br/>
                            If you do not agree to any of the changes and you no longer wish to use our Services, you
                            may choose to close your Account. Continuing to use our Services after a notice of changes
                            has been notified to you or published on our Services constitutes your acceptance of the
                            changes and consent to the modified version of this Policy. We will seek your consent when
                            required under</p>
                        <b>Applicable Laws:
                        </b>

                        < h4>Woooo Corporation
                        </h4>


                    </div>
                    <FooterSection/>
                </div>
            </div>
        );
    }
}

export default Privacy;
