import React, { Component } from 'react';
import Header from "../HeaderSection/Header";
import Breadcrumb from "../Blogs/Breadcrumb";
import getStart from "./get-start.png";
import FooterSection from "../FooterSection/Footer";
import {Helmet} from "react-helmet";


class GettingStarted extends Component {

    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up"/>
                </div>
                <div className="main">
                    <Helmet>
                        <title>Woooo - Get Started</title>
                        <link rel="canonical" href="https://woooo.world/getting-started" />
                    </Helmet>
                    <Header imageData={"/img/logo-white.png"}/>
                    <Breadcrumb title="Get Started"/>
                    <section style={{background:'#fff'}} className="section service-area bg-gray overflow-hidden ptb_100">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-12 col-lg-6 order-2 order-lg-1">
                                    {/* Service Text */}
                                    <div className="service-text pt-4 pt-lg-0">
                                        <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-4">Get Started with Woooo - Your Gateway to Rewards and Free Communication!</h2>
                                        {/*<p style={{marginBottom:40}}>*/}
                                        {/*    WOOOO One click Phone Dialing, Chat With friends and Family or Live Broadcast your show to the whole world.</p>*/}
                                        {/*/!* Service List *!/*/}
                                        <ul className="service-list">

                                                    <div style={{marginBottom:18}} >
                                                        <li className="single-service media py-2">

                                                            <div className="service-text media-body">
                                                                <h4 style={{marginBottom:6}}>Download the App:</h4>
                                                                <p>
                                                                    Visit the app store on your Android or iOS device. <br/>
                                                                    Download the Woooo app, your key to unlocking a world of free communication and Woooo coins.</p>
                                                            </div>
                                                        </li>
                                                    </div>

                                            <div style={{marginBottom:18}} >
                                                <li className="single-service media py-2">

                                                    <div className="service-text media-body">
                                                        <h4 style={{marginBottom:6}}>Sign Up and Login:
                                                        </h4>
                                                        <p>
                                                            Once downloaded, sign up using your mobile phone and email ID. <br/>
                                                            Login to your account, and you're ready to dive into the Woooo experience.</p>
                                                    </div>
                                                </li>
                                            </div>

                                            <div style={{marginBottom:18}} >
                                                <li className="single-service media py-2">

                                                    <div className="service-text media-body">
                                                        <h4 style={{marginBottom:6}}>Available on All Platforms:
                                                        </h4>
                                                        <p>
                                                            Woooo is accessible on Android, iOS, and web versions, ensuring you can connect with anyone, anywhere.</p>
                                                    </div>
                                                </li>
                                            </div>

                                            <div style={{marginBottom:18}} >
                                                <li className="single-service media py-2">

                                                    <div className="service-text media-body">
                                                        <h4 style={{marginBottom:6}}>Web3 Wallet Creation:
                                                        </h4>
                                                        <p>
                                                            Upon signing up, a Web wallet is automatically generated. <br/>
                                                            Safeguard the private key associated with your wallet in a secure place. Losing it may result in losing your Woooo coins.</p>
                                                    </div>
                                                </li>
                                            </div>

                                            <div style={{marginBottom:18}} >
                                                <li className="single-service media py-2">

                                                    <div className="service-text media-body">
                                                        <h4 style={{marginBottom:6}}>Start Calling and Chatting:
                                                        </h4>
                                                        <p>
                                                            Begin your journey by making free calls and engaging in chats <br/>
                                                            Each call and chat session contributes to your daily Woooo coin mining.</p>
                                                    </div>
                                                </li>
                                            </div> <div style={{marginBottom:18}} >
                                                <li className="single-service media py-2">

                                                    <div className="service-text media-body">
                                                        <h4 style={{marginBottom:6}}>Share the App:

                                                            </h4>
                                                        <p>
                                                            Spread the joy of free communication and Woooo coin mining
                                                            by sharing the app with your friends and family.</p>
                                                    </div>
                                                </li>
                                            </div> <div style={{marginBottom:18}} >
                                                <li className="single-service media py-2">

                                                    <div className="service-text media-body">
                                                        <h4 style={{marginBottom:6}}>Mine Woooo Coins Daily:
                                                        </h4>
                                                        <p>
                                                            Stay connected, communicate for free, and watch as your</p>
                                                    </div>
                                                </li>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block get-start-image">
                                    {/* Service Thumb */}
                                    <div style={{width:600}} className="service-thumb mx-auto">
                                        <img src={getStart} alt="getStart" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterSection/>
                </div>
            </div>

        );
    }
}

export default GettingStarted;
