import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper/core';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import image1 from "./imgs/0a04f368-4f56-4c12-0bfa-93b14bb20800.png"
import image2 from "./imgs/7a788c03-daf7-4d93-fa3a-f94e2b719900.png"
import image3 from "./imgs/35f9c46e-cc57-4aa7-315d-e6ccb2a1d600.png"
import image4 from "./imgs/48ea5de9-869a-4994-2402-97afba060900.jpeg"
import image5 from "./imgs/73dc6b30-b644-46e6-020c-5926851df600.png"
import image6 from "./imgs/322bd6f0-09b5-4595-cb15-0dfab8054800.png"
import image7 from "./imgs/f400f6c2-ca6c-487b-654d-e119af247500.png"
import image8 from "./imgs/eae63a23-c7ba-4f7e-24b3-e6fc69215d00.png"
import image9 from "./imgs/19923b08-7208-4539-9c2d-c43db22bce00.png"
import image10 from "./imgs/2103feda-4fc8-4635-76a7-02a4ed998000.jpeg"
import image11 from "./imgs/e6cff623-9671-4a39-acc7-1c2292d7e100.png"
import image12 from "./imgs/73dc6b30-b644-46e6-020c-5926851df600.png"



import img1 from "./img2/0dafcaab-0852-47f7-85dd-436b86491d00.png"
import img2 from "./img2/2cd67b4c-282b-4809-e7c0-a88cd5116f00.png"
import img3 from "./img2/6f913b80-86c0-46f9-61ca-cc90a1805900.png"
import img4 from "./img2/5b1cddfb-056e-4e78-029a-54de5d70c500.jpeg"
import img5 from "./img2/26a8f588-3231-4411-60ce-5bb6b805a700.jpeg"
import img6 from "./img2/fe1b9394-55af-4828-a70d-5c5b7de6b200.png"
import img7 from "./img2/dda0f0fb-34e8-4a57-dcea-b008e7d1ff00.png"
import img8 from "./img2/f3119826-4ef5-4d31-4789-d4ae5c18e400.png"
import img9 from "./img2/7c5ff577-a68d-49c5-02cd-3d83637b0b00.jpeg"
import img10 from "./img2/45f2f08e-fc0c-4d62-3e63-404e72170500.png"
import img11 from "./img2/a78c4d48-32c1-4a9d-52f2-ec7ee08ce200.png"
import img12 from "./img2/dfe0e3e3-5746-4e2b-12ad-704608531500.png"



const images = [
    {img: image1, link: 'https://www.argent.xyz/'},
    {img: image2, link: 'https://www.assure.pro/'},
    {img: image3, link: 'https://web3.bitget.com/'},
    {img: image4, link: 'https://token.im/'},
    {img: image5, link: 'https://metamask.io/'},
    {img: image6, link: 'https://www.pillar.fi/'},
    {img: image7, link: 'https://rainbow.me/'},
    {img: image8, link: 'https://www.spot-wallet.com/'},
    {img: image9, link: 'https://trustwallet.com/'},
    {img: image10, link: 'https://unstoppable.money/'},
    {img: image11, link: 'https://zengo.com/'},
    {img: image12, link: 'https://zerion.io/'},
]
const images2 = [
    {img: img1, link: ''},
    {img: img2, link: ''},
    {img: img3, link: ''},
    {img: img4, link: ''},
    {img: img5, link: ''},
    {img: img6, link: ''},
    {img: img7, link: ''},
    {img: img8, link: ''},
    {img: img9, link: ''},
    {img: img10, link: ''},
    {img: img11, link: ''},
    {img: img12, link: ''},
]

// Install modules
SwiperCore.use([Navigation, Autoplay]);

class CardSwiper extends Component {
    render() {
        return (
            <Swiper
                freeMode={true}
                freemodemomentum={true}
                freemodemomentumratio={1}
                freemodemomentumbounce={true}
                freemodemomentumbounceratio={1}
                freemodemomentumvelocityratio={1}
                freemodesticky={false}
                grabCursor={true}
                loop={true}
                autoplay={{
                    delay: 0,
                    disableOnInteraction: true,
                    reverseDirection:this.props.direction
                }}
                speed={this.props.speed}
                slidesPerView={10}
                slidesPerGroup={this.props.slidesPerGroup}
                spaceBetween={30}
                breakpoints={{
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    },
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 5,
                        spaceBetween: 20
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 6,
                        spaceBetween: 25
                    },
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 8,
                        spaceBetween: 28
                    },
                    // when window width is >= 1024px
                    1024: {
                        slidesPerView: 10,
                        spaceBetween: 30
                    }
                }}
    >
                { this.props.direction ? images2.map((_, i) => (
                    <SwiperSlide key={i}>
                        <div className="wallet_card" rel="noreferrer"><img src={_?.img} alt="wallet_image"/></div>
                    </SwiperSlide>
                )) : images.map((_, i) => (
                    <SwiperSlide key={i}>
                        <div className="wallet_card" rel="noreferrer"><img src={_?.img} alt="wallet_image"/></div>
                    </SwiperSlide>
                ))

            }
            </Swiper>
        );
    }
}

export default CardSwiper;
