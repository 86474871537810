import React, {Component} from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import FooterSection from '../FooterSection/Footer';
import {Helmet} from "react-helmet";

class Terms extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up"/>
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Helmet>
                        <title>Woooo - TERMS AND SERVICES</title>
                        <link rel="canonical" href="https://woooo.world/terms" />
                    </Helmet>
                    <Header imageData={"/img/logo-white.png"}/>
                    <Breadcrumb title="TERMS AND SERVICES"/>
                    <div style={{
                        padding: '2em',
                        width: '100%',
                        maxWidth: '1440px',
                        background: '#fff',
                        borderRadius: 20,
                        position: 'relative',
                        zIndex: 9,
                        margin: "0px auto",
                        marginTop: '-6em',
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <section id="about-us" className="main-section center-align" data-scroll-index="7">

                            <div>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Introduction</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    Welcome! Woooo is your place to talk and hang out. We’re happy you’re here.
                                </p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    These terms set forth our legal obligations to each other. They apply to your use of
                                    our services.
                                </p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    When we say “Woooo,” “we,” “us,” and “our” in these terms, we mean Woooo Inc., its
                                    subsidiaries, and its related companies.
                                </p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    When we say “services” in these terms, we mean Woooo’s services, apps, websites, and
                                    other products.
                                </p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    When we say “you” or “your,” we mean you. If you’re accessing our services on behalf
                                    of a legal entity (like your employer), you agree that you have the authority to
                                    bind that entity to these terms, and “you” and “your” will refer to that entity.
                                </p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    We also have a Privacy Policy, Community Guidelines, and other policies that apply
                                    to your use of our services and are incorporated into these terms. You should read
                                    these policies—we’ve worked hard to make them simple and clear, and they contain
                                    important information about your use of our services. Together, these rules make
                                    Woooo possible, and they matter to us. If you believe others aren’t following them,
                                    please let us know:
                                </p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    <strong>IMPORTANT NOTE:</strong> The section titled “Settling Disputes Between You
                                    and Woooo” contains an arbitration clause and class-action waiver that applies to
                                    all Woooo users. Please read this section carefully as it may significantly affect
                                    your legal rights.
                                </p>
                                <p className="p-set">
                                    <div>
                                        <h4 className="agr-pset sbh-set cPrivacyHead">Who we are</h4>
                                        <p className="agr-pset sbh-set cPrivacyPara">
                                            We provide services that allow you to interact with other Woooo users and
                                            participate in public and private communities (or “servers”). Our services may also
                                            include access to certain software, features, and content that you can purchase from
                                            us or others.
                                        </p>

                                        <h4 className="agr-pset sbh-set cPrivacyHead">Age requirements and responsibility of parents and guardians</h4>
                                        <p className="agr-pset sbh-set cPrivacyPara">
                                            By accessing our services, you confirm that you’re at least 13 years old and meet
                                            the minimum age of digital consent in your country. We maintain a list of minimum
                                            ages around the world as a resource for you, but we aren’t able to guarantee that it
                                            is always accurate. <br />
                                            By using the App, you agree that Woooo may collect and use technical data and
                                            related information including but not limited to technical information about your
                                            device, system, and Application software that is gathered contemporarily to help
                                            improve the software and services. <br />
                                            If you are old enough to access our services in your country, but not old enough to
                                            have authority to consent to our terms, your parent or guardian must agree to our
                                            terms on your behalf. Please ask your parents or guardians to read these terms with
                                            you. If you’re a parent or legal guardian, and you allow your teenager to use the
                                            services, then these terms also apply to you and you’re responsible for your
                                            teenager’s activity on the services.
                                        </p>

                                        <h4 className="agr-pset sbh-set cPrivacyHead">Consent</h4>
                                        <p className="agr-pset sbh-set cPrivacyPara">
                                            By using the App, you agree that Woooo may collect and use technical data and
                                            related information including but not limited to technical information about your
                                            device, system, and Application software that is gathered contemporarily to help
                                            improve the software and services. <br />
                                            You provide us the phone numbers of the Woooo users and your other contacts in your
                                            mobile phone address book on a regular basis. You confirm that you are authorized to
                                            provide us such numbers to allow us to provide our Services.
                                        </p>

                                        <h4 className="agr-pset sbh-set cPrivacyHead">What you can expect from us</h4>
                                        <p className="agr-pset sbh-set cPrivacyPara">
                                            We’re actively developing new features and products to improve Woooo. As part of
                                            these efforts, we may add or remove features, start offering new services, or stop
                                            offering old services. While we try to avoid disruptions, we cannot guarantee that
                                            there will not be an outage or change to the services, and your content may not be
                                            retrievable due to such outages or changes. We are not liable for any such outages
                                            or service changes.
                                        </p>

                                        <h4 className="agr-pset sbh-set cPrivacyHead">Your Woooo account</h4>
                                        <p className="agr-pset sbh-set cPrivacyPara">
                                            To access the services on an ongoing basis, you will need to create a Woooo account.
                                            You can provide an email and password, and a way of contacting you (such as an email
                                            address and/or phone number). To access certain features or communities, you may
                                            need to verify your account or add other information to your account. Our Privacy
                                            Policy discusses what information we collect and how we use this information in more
                                            detail. <br />
                                            You are responsible for the security of your account, and you agree to notify us
                                            immediately if you believe your account has been compromised. If you use a password,
                                            it must be strong, and we (strongly) recommend that you use that password only for
                                            your Woooo account and that you enable two-factor authentication. If your account is
                                            compromised, we may not be able to restore it to you. <br />
                                            You are also responsible for maintaining the accuracy of the contact information
                                            associated with your account. If you get locked out of your account, we’ll need to
                                            contact you at the email or phone number associated with your account, and we may
                                            not be able to restore your Woooo account to you if you no longer have access to
                                            that email account or phone number. We may also assume that any communications we’ve
                                            received from your account or the associated contact information have been made by
                                            you. <br />
                                            You agree not to license, sell, or transfer your account without our prior
                                            written approval.
                                        </p>
                                    </div>

                                    <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Content in Woooo’s services</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    <strong>Your Content : </strong>
                                    When we say “your content” in these terms, we mean all the things you add (upload,
                                    post, share, or stream) to our services. This may include text, links, GIFs, emoji,
                                    photos, videos, documents, or other media. If we come up with another way for you to
                                    add content to the services, it includes that too.<br />
                                    You don’t have any obligation to add content to the services. If you choose to add
                                    content to the services, you are responsible for ensuring that you have the right to
                                    do so, that you have the right to grant the licenses in the terms, and that your
                                    content is lawful. We take no responsibility for any of your content, and we are not
                                    responsible for others’ use of your content.
                                    <br />
                                        Our services allow users to add content in a number of different ways, including
                                        via direct messages and in smaller and larger communities. Some of these spaces
                                        are public, and if you share content within them, that content may be accessed
                                        by people you do not know. Please understand the difference between posting in
                                        public and private spaces on Woooo, and choose the right space, features, and
                                        settings for you and your content. To understand how we treat your personal
                                        information, see our Privacy Policy<br />
                                        Your content is yours, but you give us a license to it when you use Woooo. Your
                                        content may be protected by certain intellectual property rights. We don’t own
                                        those. But by using our services, you grant us a license—which is a form of
                                        permission—to do the following with your content, in accordance with applicable
                                        legal requirements, in connection with operating, developing, and improving our
                                        services:

                                </p>
                                <ul className="p-set" >
                                    <li className="agr-pset sbh-set"><span className="span2">⚬</span>Use, copy, store,
                                        distribute, and communicate your content in manners consistent with your use of
                                        the services. (For example, we can store and display your content.)
                                    </li>
                                    <li className="agr-pset sbh-set "><span className="span2">⚬</span>Publish, publicly
                                        perform, or publicly display your content if you’ve chosen to make it visible to
                                        others. (For example, we can display your messages if you post them in public
                                        servers.)
                                    </li>
                                    <li className="agr-pset sbh-set "><span className="span2">⚬</span>Monitor, modify,
                                        translate, and reformat your content. (For example, so we can resize an image
                                        you post to fit on a mobile device.)
                                    </li>
                                    <li className="agr-pset sbh-set "><span className="span2">⚬</span>Sublicense your
                                        content, to allow our services to work as intended. (For example, we can store
                                        your content with our cloud service providers.)
                                    </li>
                                </ul>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    This license is worldwide, non-exclusive (which means you can still license your
                                    content to others), royalty-free (which means there are no fees for this license),
                                    transferable, and perpetual.<br />
                                    We reserve the right to block, remove, and/or permanently delete your content for
                                    any reason, including breach of these terms, our Community Guidelines, our other
                                    policies, or any applicable law or regulation.<br />
                                    We welcome feedback on our services. By sending us feedback, you grant us a
                                    non-exclusive, perpetual, irrevocable, transferable license to use the feedback and
                                    ideas generated from the feedback without any restrictions, attribution, or
                                    compensation to you.
                                </p>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Woooo’s content</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    Our services include some content that belongs to us. You may use this content as
                                    permitted by these terms, but we retain all intellectual property rights in our
                                    content.

                                </p>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Other content</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    Other people’s content. Our services might also provide you with access to other
                                    people’s content. You may not use this content without that person’s consent, or as
                                    allowed by law. Other people’s content is theirs and doesn’t necessarily reflect
                                    Woooo’s own views. Woooo doesn’t endorse or verify the accuracy or reliability of
                                    content shared by Woooo users. We work hard to try to make Woooo a safe, positive,
                                    and inclusive place, but cannot prevent you from encountering content that you may
                                    find objectionable or offensive. You agree we will not be liable for any harm caused
                                    by that content. You may report content that you think violates any of our policies.<br />
                                    We have the right, but not the obligation, to review such reports and block or
                                    remove content at our discretion.
                                    Third party features and content. Our services may also allow you to access
                                    third-party websites, features, apps, or other content. We provide you access only
                                    as a convenience to you, and are not responsible for the content or services
                                    available from these websites or resources.

                                </p>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Software in Woooo’s services</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    License to our software. Some of our services allow you to download software. So
                                    long as you comply with these terms, we grant you a worldwide, non-exclusive,
                                    personal, and non-assignable license to download, install, and run that software,
                                    solely to access our services.<br />
                                    You may not copy, modify, create derivative works based upon, distribute, sell,
                                    lease, or sublicense any of our software or services. You also may not reverse
                                    engineer or decompile our software or services, attempt to do so, or assist anyone
                                    in doing so, unless you have our written consent or applicable law permits it.<br />
                                    Although we are granting you this license, we retain any intellectual property
                                    rights we have in our software and services.

                                </p>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Copyright</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    We respect the intellectual property of others and expect our users to do the same.
                                    See our Copyright Policy for information on how to file a copyright complaint.
                                </p>

                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Woooo’s paid services</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    We won’t charge you a fee to use the basic functionality of our services, but you
                                    may be able to pay for additional features and products. Woooo’s Paid Services Terms
                                    also apply to any purchase you make through Woooo, and you may also be asked to
                                    agree to separate terms before purchasing or selling new offerings through Woooo.
                                </p>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Woooo’s Activation Key</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    We will charge a fee for activation relating to mining Woooo assets this will grant
                                    users the functionality to transfer their Woooo assets to other users the pricing of
                                    the Woooo is determined by Woooo company itself and have the ability to update and
                                    modify the price, currently the activation key resides in $12 USD this will be
                                    equivalent to all countries in their native exchanged rates.
                                </p>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Restrictions on your use of Woooo’s
                                services</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    When using our services, you must comply with these terms and all applicable laws,
                                    rules, and regulations, and you must only use the services for authorized and
                                    acceptable purposes. You must also adhere to our Community Guidelines and other
                                    policies, which contain more detailed rules about your content and behavior when
                                    using Woooo. Fundamentally, do not do, try to do, or encourage or help others to do
                                    any of the following:
                                </p>
                                <ul className="p-set" >
                                    <li className="agr-pset sbh-set"><span className="span2">⚬</span>Don’t use the
                                        services to do harm to yourself or others.
                                    </li>
                                    Among other things, this includes trying to gain access to another user’s account or
                                    any non-public portions of the services, infringing anyone else’s intellectual
                                    property rights or any other proprietary rights, harassing, bullying, spamming,
                                    auto-messaging, or auto-dialing people through our services.
                                    <li className="agr-pset sbh-set "><span className="span2">⚬</span>Don’t use the
                                        services to do harm to Woooo.</li>Among other things, this includes trying to
                                    gain access to or attacking our systems, scraping us, transmitting viruses or other
                                    malicious code to our services, abusing or defrauding us or our payment systems,
                                    copying our product or using our intellectual property without permission, and
                                    misusing our reporting or customer service mechanisms.
                                    <li className="agr-pset sbh-set "><span className="span2">⚬</span>Don’t use the
                                        services to do anything else that’s illegal.</li>This includes using the
                                    services to commit any crime or infringe anyone’s intellectual property rights.

                                </ul>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    We encourage you to report content or conduct that you believe violates these
                                    restrictions.
                                </p>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Termination</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    <strong>Your right to terminate:</strong>
                                    You’re free to stop using Woooo’s services at any time and for any reason. You can
                                    delete your Woooo account through the User Settings page in the Woooo app. You can
                                    also disable your account, which restricts the processing of your personal
                                    information as described in our Privacy Policy. Disabling your account does not
                                    terminate this agreement.
                                    <strong>Our right to terminate:</strong>
                                    Subject to applicable law, we reserve the right to suspend or terminate your account
                                    and/or your access to some or all of our services with or without notice, at our
                                    discretion, including if:
                                </p>
                                <ul className="p-set"
                                >
                                    <li className="agr-pset sbh-set"><span className="span2">⚬</span>You breach these
                                        terms, our policies, or additional terms that apply to specific products.
                                    </li>
                                    <li className="agr-pset sbh-set "><span className="span2">⚬</span>We’re required to
                                        do so to comply with a legal requirement or court order.
                                    </li>
                                    <li className="agr-pset sbh-set "><span className="span2">⚬</span>We reasonably
                                        believe termination is necessary to prevent harm to you, us, other users, or
                                        third parties.
                                    </li>
                                    <li className="agr-pset sbh-set "><span className="span2">⚬</span>Your account has
                                        been inactive for more than two years.
                                    </li>
                                </ul>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    However, we will give you advance notice if reasonable to do so or required by
                                    applicable law. You can appeal any enforcement action we take under these terms
                                    here: https://dis.gd/request.
                                </p>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Indemnity</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    If you are using the services on behalf of a business or legal entity and not in an
                                    individual capacity, then you will indemnify and hold Woooo and its officers,
                                    directors, employees and agents harmless from and against any claims, liabilities,
                                    damages, and costs (including reasonable legal and accounting fees) related to (a)
                                    your access to or use of our services, (b) your content, or (c) your violation of
                                    these terms.
                                </p>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Services “AS IS”</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    We work hard to offer great services, but there are certain aspects that we can’t
                                    guarantee. TO THE FULLEST EXTENT PERMITTED BY LAW, Woooo, ITS AFFILIATES, AND THEIR
                                    RESPECTIVE SUPPLIERS MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE
                                    SERVICES. THE SERVICES ARE PROVIDED “AS IS.” WE ALSO DISCLAIM ANY IMPLIED WARRANTIES
                                    OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, AND
                                    NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF
                                    TRADE. THE LAWS OF CERTAIN JURISDICTIONS OR STATES DO NOT ALLOW LIMITATIONS ON
                                    IMPLIED WARRANTIES. TO THE EXTENT SUCH WARRANTIES CANNOT BE DISCLAIMED UNDER THE
                                    LAWS OF YOUR JURISDICTION, WE LIMIT THE DURATION AND REMEDIES OF SUCH WARRANTIES TO
                                    THE FULL EXTENT PERMISSIBLE UNDER THOSE LAWS.<br />
                                    This warranty does not affect any consumer rights you might have under applicable
                                    law.
                                </p>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Limitation of liability</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    We don’t exclude or limit our liability to you where it would be illegal to do so.
                                    in countries where the below types of exclusions aren’t allowed, We’re responsible
                                    to you only for losses and damages that are a reasonably foreseeable result of our
                                    failure to use reasonable care and skill or our breach of our contract with you.
                                    This paragraph doesn’t affect consumer rights that can’t be waived or limited by any
                                    contract or agreement.
                                    <br />
                                        The information presented on or through the services is made available solely
                                        for informational purposes. we do not confirm the accuracy, completeness, or
                                        usefulness of the information. any reliance you place on such information is
                                        solely at your own risk.<br />
                                        In countries where exclusions or limitations of liability are allowed, neither
                                        Woooo, its affiliates, nor our suppliers involved in creating, producing, or
                                        delivering the services will be liable, to the maximum extent permitted under
                                        applicable law, for any incidental, special, exemplary, or consequential
                                        damages, or damages for lost profits, lost revenues, lost savings, lost business
                                        opportunity, loss of data or goodwill, service interruption, computer damage or
                                        system failure, or the cost of substitute services of any kind arising out of or
                                        in connection with these terms or from the use of or inability to use the
                                        services, whether based on warranty, contract, statute, tort (including
                                        negligence), product liability, or any other legal theory, and whether or not
                                        woooo or its suppliers have been informed of the possibility of such damage,
                                        even if a limited remedy provided in these terms is found to have failed of its
                                        essential purpose.<br />
                                        Woooo isn’t liable for the conduct or content, whether online or offline, of any
                                        user of our services.<br />
                                        The exclusions and limitations of damages set forth above are fundamental
                                        elements of the basis of the bargain between woooo and you.
                                </p>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Settling disputes between you and
                                Woooo</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    <strong>Informal resolution</strong>
                                    Most disputes can be resolved informally, so if you have an issue with the services,
                                    let’s consult with each other first. Before pursuing formal legal action, you agree
                                    to try to resolve a dispute with us informally by sending notice to <a
                                    href="mailto:disputes@Woooo.com">disputes@Woooo.com</a><br />
                                    <strong>Jurisdiction</strong>
                                    The Jurisdiction will be as followed:-<br />
                                    If you are a user/consumer accessing the Woooo Site/App and Services from the any
                                    country or territory or any other region with laws or regulations governing personal
                                    data collection, use, and disclosure, that differ from Japan’s laws, please be
                                    advised that through your continued use of the Woooo Site and Service, which are
                                    governed by Japan law, this Privacy Policy, and our Terms of Service, you are
                                    transferring your personal information to Japan and you expressly consent to that
                                    transfer and consent to be governed by Japan law for these purposes.
                                </p>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">More important stuff</h4>
                                <p></p>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    You have certain rights that, by law, can’t be limited by these terms, and we in no
                                    way intend to restrict those rights in these terms.<br />
                                    <strong>Entire agreement</strong>
                                    These terms cover the entire agreement between you and Woooo for your use of our
                                    services.<br />
                                    <strong>Additional terms</strong>
                                    Where additional terms apply to our products or services, the additional terms will
                                    control with respect to your use of that product or service to the extent of any
                                    conflict with these terms.<br />
                                    <strong>Bug reporting</strong>
                                    We support the responsible reporting of security vulnerabilities. To report a
                                    security issue, please visit https://Woooo.com/security. <br />
                                    <strong>Waiver, severability, and assignment</strong>
                                    If you fail to follow these terms and we don’t immediately act, that doesn’t mean
                                    we’re giving up any of our legal rights (such as acting in the future). If any part
                                    of these terms ends up being invalid or unenforceable based on a decision by any
                                    court or competent authority, the rest of these terms will not be affected. You may
                                    not assign these terms to anyone else without our written consent. We may assign our
                                    rights to any of our affiliates or subsidiaries, or to any successor in interest of
                                    any business associated with our services.<br />
                                    <strong>Survival</strong>
                                    Any part of these terms that by their nature should survive after termination of
                                    these terms will survive.<br />
                                    <strong>Updates to these terms</strong>
                                    We may decide to update these terms <br /><strong>(1)</strong> to reflect changes to
                                    our services or our business, <br /><strong>(2)</strong> for legal or regulatory
                                        reasons, or <br /><strong>(3)</strong> to prevent abuse on or of our services. If
                                            these changes materially affect your Woooo use or your legal rights, we’ll
                                            give you reasonable advance notice (unless the updates are urgent). If you
                                            continue to use our services after the changes have taken effect, it means
                                            that you agree to the changes. If you don’t agree, you must stop using our
                                            services.

                                </p>
                                <p className="p-set">
                                </p><h4 className="agr-pset sbh-set cPrivacyHead">Contacting each other</h4>
                                <p className="agr-pset sbh-set cPrivacyPara">
                                    If you have any questions about these terms, please contact us at <a
                                    href="mailto:support@woooo.com">support@woooo.com</a>. We may send you electronic
                                    communications related to our services. Where required, we’ll get your consent
                                    before sending you direct marketing messages, and we’ll make it easy for you to opt
                                    out.
                                </p>
                                </p>
                            </div>

                        </section>
                    </div>
                    <FooterSection/>
                </div>
            </div>
        );
    }
}

export default Terms;
