import React, { Component } from 'react';
import ParallexParticals from "./ParallexParticals";

const initData = {
    heading: "WOOOO BLOCKCHAIN",
    headingText: "we're dedicated to a future where your freedom and privacy matter most. We're building the world's largest blockchain network because we believe in the power of individuals to make their own choices. Embrace a decentralized world where you're in control.",
    headingSlug: "* Available on iPhone, iPad and all Android devices",
    playImg: "/img/google-play.png",
    appleImg: "/img/app-store.png",
}

class Download extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section style={{padding:"10em 0", position:'relative'}} className="section download-area ptb_100 custom-download">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Download Text */}
                        <div className="download-text text-center">
                        <h2 className="text-white">{this.state.initData.heading}</h2>
                        <h3 className="text-white my-3 ">{this.state.initData.headingText}</h3>
                        <p className="text-white my-3 ">{this.state.initData.headingTexttwo}</p>
                        {/* Store Buttons */}
                        <div className="button-group store-buttons d-flex justify-content-center">
                            <a href="https://block.woooo.world/" target={'_blank'} className="btn sApp-btn mt-4">Woooo Coin Explorer</a>
                            <a href="/img/whitepaper/WOOOO_WHITEPAPER.pdf" target={'_blank'} className="btn sApp-btn mt-4">Blockchain Whitepaper</a>
                            <a href="/blockchain" className="btn sApp-btn mt-4">Learn more</a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Download;
