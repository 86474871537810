import React, {Component} from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import FooterSection from '../FooterSection/Footer';
import Roadmap1 from "./roadmap1.png"
import Roadmap2 from "./roadmap2.png"
import Roadmap3 from "./roadmap3.png"
import Roadmap4 from "./roadmap4.png"
import Roadmap5 from "./roadmap5.png"
import Roadmap6 from "./roadmap6.png"
import {Helmet} from "react-helmet";

class Roadmap extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up"/>
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Helmet>
                        <title>Woooo - Roadmap</title>
                        <link rel="canonical" href="https://woooo.world/roadmap" />
                    </Helmet>
                    <Header imageData={"/img/logo-white.png"}/>
                    <Breadcrumb title="Roadmap"/>
                    <div className='roadmap_classs' style={{
                        padding: '6em',
                        width: '100%',
                        maxWidth: '1440px',
                        background: '#E1E1E1',
                        borderRadius: 20,
                        position: 'relative',
                        zIndex: 9,
                        margin: "0px auto",
                        marginTop: '-6em',
                        justifyContent: "center",
                        alignItems:'center',
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <img src={Roadmap1} alt="Roadmap" style={{width:'100%',height:'100%'}}/>
                        <img src={Roadmap2} alt="Roadmap" style={{width:'100%',height:'100%'}}/>
                        <img src={Roadmap3} alt="Roadmap" style={{width:'100%',height:'100%'}}/>
                        <img src={Roadmap4} alt="Roadmap" style={{width:'100%',height:'100%'}}/>
                        <img src={Roadmap5} alt="Roadmap" style={{width:'100%',height:'100%'}}/>
                        <img src={Roadmap6} alt="Roadmap" style={{width:'100%',height:'100%'}}/>
                    </div>
                    <FooterSection/>
                </div>
            </div>
        );
    }
}

export default Roadmap;
