import React, { Component } from 'react';
import Header from "../HeaderSection/Header";
import Breadcrumb from "../Blogs/Breadcrumb";
import FooterSection from "../FooterSection/Footer";
import {Helmet} from "react-helmet";


class UserGuide extends Component {

    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                <div className="main">
                    <Helmet>
                        <title>Woooo - User Guide</title>
                        <link rel="canonical" href="https://woooo.world/user-guide" />
                    </Helmet>
                    <Header imageData={"/img/logo-white.png"} />
                    <Breadcrumb title="Wallet Guide" />
                    <section className="privacy-page userguide relative">
                        <div className="small-pnl secnd-anime">
                            <div className="bg-layer"></div>
                        </div>
                        <div style={{
                            padding: '2em',
                            width: '100%',
                            maxWidth: '1440px',
                            background: '#fff',
                            borderRadius: 20,
                            position: 'relative',
                            zIndex: 9,
                            margin: "0px auto",
                            marginTop: '-6em',
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column"
                        }} className="container">
                            <h2 style={{textAlign:'center',marginTop:30,color:"rgb(32, 166, 232)"}}>
                                Get Rich While Using Free WOOOO
                            </h2>
                            <p style={{textAlign:'center',marginTop:10,marginBottom:40}}>
                                Ready to send funds using Woooo Wallet? Follow these straightforward <br/> steps to transfer your cryptocurrencies securely:
                            </p>

                            <div className="app-content">
                                <div className="content">
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>1. Open Your Woooo Wallet:</h2>
                                    <p className="description">
                                        - Launch the Woooo Wallet app on your device.
                                    </p>
                                </div>
                                <div className="phone-mockup">
                                    <img src="/img/guide/Picture1-portrait.png" alt="" />
                                </div>
                            </div>
                            <div style={{flexDirection:'row-reverse',color:"rgb(32, 166, 232)"}} className="app-content">
                                <div data-aos="fade-left" className="content">
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>2. Select Coin</h2>
                                    <p className="description">
                                        -Select chain you want to select.
                                    </p>
                                </div>
                                <div data-aos="fade-right" className="phone-mockup">
                                    <img src="/img/guide/Picture2-portrait.png" alt="" />
                                </div>
                            </div>

                            <div className="app-content">
                                <div data-aos="fade-right" className="content">
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>3. Navigate to "Send" Section:</h2>
                                    <p className="description">
                                        - Locate and select the "Send" option within the app.
                                    </p>
                                </div>
                                <div data-aos="fade-left" className="phone-mockup">
                                    <img src="/img/guide/Picture3-portrait.png" alt="" />
                                </div>
                            </div>

                            <div style={{flexDirection:'row-reverse'}} className="app-content">
                                <div data-aos="fade-left" className="content">
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>4. Specify the Amount:</h2>
                                    <p className="description">
                                        - Enter the precise amount you wish to send. Be attentive to include the correct decimals.
                                    </p>
                                </div>
                                <div data-aos="fade-right" className="phone-mockup">
                                    <img src="/img/guide/Picture4-portrait.png" alt="" />
                                </div>
                            </div>

                            <div className="app-content">
                                <div data-aos="fade-right" className="content">
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>5. Enter the Recipient's Address:</h2>
                                    <p className="description">
                                        - Input the recipient's wallet address accurately. You can either paste it or use the QR code scanner.
                                    </p>
                                </div>
                                <div data-aos="fade-left" className="phone-mockup">
                                    <img src="/img/guide/Picture5-portrait.png" alt="" />
                                </div>
                            </div>

                            <div style={{flexDirection:'row-reverse'}} className="app-content">
                                <div data-aos="fade-left" className="content">
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>6. Confirm Transaction Details:</h2>
                                    <p className="description">
                                        - Review the transaction details, ensuring the accuracy of the recipient's address and the amount to be sent.
                                    </p>
                                </div>
                                <div data-aos="fade-right" className="phone-mockup">
                                    <img src="/img/guide/Picture6-portrait.png" alt="" />
                                </div>
                            </div>

                            <div className="app-content">
                                <div data-aos="fade-right" className="content">
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>7. Confirm and Authenticate:</h2>
                                    <p className="description">
                                        - Confirm the transaction details and authenticate using your preferred security method, such as biometrics or PIN.
                                    </p>
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>8. Monitor Transaction Progress:</h2>
                                    <p className="description">
                                        - Once confirmed, monitor the transaction progress in the transaction history section of your wallet.
                                    </p>
                                </div>
                                <div data-aos="fade-left" className="phone-mockup">
                                    <img src="/img/guide/Picture7-portrait.png" alt="" />
                                </div>
                            </div>

                            <div style={{flexDirection:'row-reverse'}} className="app-content">
                                <div data-aos="fade-left" className="content">
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>9. You can see your transaction activity.</h2>
                                    <p className="description">
                                        - View the recent activities in detail.
                                    </p>
                                </div>
                                <div data-aos="fade-right" className="phone-mockup">
                                    <img src="/img/guide/Picture8-portrait.png" alt="" />
                                </div>
                            </div>


                            <div className="app-content">
                                <div data-aos="fade-right" className="content">
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>10. This is the transaction details:</h2>
                                    <p className="description">
                                        Here, you'll find a detailed record of your recent transactions, including status and timestamps.
                                    </p>
                                </div>
                                <div data-aos="fade-left" className="phone-mockup">
                                    <img src="/img/guide/Picture9-portrait.png" alt="" />
                                </div>
                            </div>


                            <p style={{textAlign:'center',marginTop:20}}>Now you can stay informed about your transaction activity, ensuring transparency and control over your digital coin.</p>
                            <h2 style={{textAlign:'center',marginTop:40,color:"rgb(32, 166, 232)"}}>
                                How to check Recovery Words?
                            </h2>
                            <p style={{textAlign:'center',marginTop:10,marginBottom:40}}>
                                Checking your recovery words is a critical step to ensure that you have recorded <br/>
                                them accurately. Here's a simple guide:
                            </p>


                            <div className="app-content">
                                <div data-aos="fade-right" className="content">
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>1. Open Woooo Wallet:</h2>
                                    <p className="description">
                                        Open Woooo Wallet:
                                    </p>
                                </div>
                                <div data-aos="fade-left" className="phone-mockup">
                                    <img src="/img/guide/Picture1-portrait.png" alt="" />
                                </div>
                            </div>


                            <div style={{flexDirection:'row-reverse'}} className="app-content">
                                <div data-aos="fade-left" className="content">
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>2. Access Settings:</h2>
                                    <p className="description">
                                        - Navigate to the "Settings" section of the app.
                                    </p>
                                </div>
                                <div data-aos="fade-right" className="phone-mockup">
                                    <img src="/img/guide/Picture11-portrait.png" alt="" />
                                </div>
                            </div>

                            <div className="app-content">
                                <div data-aos="fade-right" className="content">
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>3. Find Reveal Recovery Words:</h2>
                                    <p className="description">
                                        - Look for an option "Reveal Recovery Words” within the settings.
                                    </p>

                                </div>
                                <div data-aos="fade-left" className="phone-mockup">
                                    <img src="/img/guide/Picture12-portrait.png" alt="" />
                                </div>
                            </div>

                            <div style={{flexDirection:'row-reverse'}} className="app-content">
                                <div data-aos="fade-left" className="content">
                                    <h2 style={{color:"rgb(32, 166, 232)"}}>4. View Recovery Words:</h2>
                                    <p className="description">
                                        - Once inside the recovery words section, you should be able to either view or confirm your existing recovery words.
                                    </p>

                                    <h2 style={{color:"rgb(32, 166, 232)"}}>5. Store Securely:</h2>
                                    <p className="description">
                                        - Make sure to store them securely and consider double-checking periodically.
                                    </p>
                                </div>
                                <div data-aos="fade-right" className="phone-mockup">
                                    <img src="/img/guide/Picture13-portrait.png" alt="" />
                                </div>
                            </div>


                            <h1 style={{textAlign:'center',marginTop:40,color:"rgb(32, 166, 232)"}}>
                                Important Note:
                            </h1>
                            <p style={{textAlign:'center',marginTop:6}}>
                                - Never share your recovery words with anyone.
                            </p>
                            <p style={{textAlign:'center',marginTop:6}}>
                                - If you suspect your recovery words have been compromised, consider generating a new wallet and transferring your funds.
                            </p>
                            <p style={{textAlign:'center',marginTop:6,marginBottom:100}}>
                                By periodically verifying your recovery words, you enhance the security of your Woooo Wallet and reduce the risk of potential issues in the future.
                            </p>

                        </div>
                    </section>
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default UserGuide;
