import React, { Component } from 'react';

import Header from '../components/HeaderSection/Header';
import HeroSection from '../components/HeroSection/HeroFour';
import FeatureSection from '../components/Features/FeatureFour';
import FeatureSectionCoin from '../components/Features/FeatureOne';
import ServiceSection from '../components/ServiceSection/ServiceFour';
import DiscoverSection from '../components/DiscoverSection/DiscoverFour';
import Work from '../components/WorkSection/Work';
import ScreenshotSection from '../components/ScreenshotSection/ScreenshotsTwo';
import FaqSection from '../components/FaqSection/FaqOne';
import Download from '../components/DownloadSection/Download';
import Subscribe from '../components/SubscribeSection/Subscribe';
import ContactSection from '../components/ContactSection/Contact';
import FooterSection from '../components/FooterSection/Footer';
import DownloadNew from "../components/DownloadSection/DownloadNew";
import {Helmet} from "react-helmet";
import StartModal from '../components/StartModal/StartModal';

class ThemeFour extends Component {
    // constructor(){
    //     super()
    //     this.state = {
    //       startModalOpen : false
    //     }
    //     this.visited = React.createRef();
    // }

    // componentDidMount(){
    //     this.setState({
    //         startModalOpen: true
    //     })
    // }

    render() {
        return (
            <div style={{position:'relative',width:"100%"}} className="homepage-4">
                {/* {this.state.startModalOpen && <StartModal closeModal={()=>{
                    this.setState({startModalOpen:false})
                    if(!this.visited.current){
                        setTimeout(()=>{
                            this.setState({startModalOpen:true})
                           },20000)
                    }
                }
                }
                Visited={this.visited}
                />} */}
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Helmet>
                        <title>Woooo - Chat, Call, Meet and Mine Coins With Realtime Translation</title>
                        <link rel="canonical" href="https://woooo.world/" />
                    </Helmet>
                    <Header imageData={"/img/logowhite.png"} />
                    <HeroSection />
                    <ServiceSection />
                    <DiscoverSection />
                    <FeatureSection />
                    <Work />
                    <FeatureSectionCoin />
                    <Download />
                    <ScreenshotSection />
                    {/*<ReviewSection />*/}
                    {/*<PricingSection />*/}
                    <DownloadNew />
                    <FaqSection />
                    <Subscribe />
                    <ContactSection />
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default ThemeFour;
