import React, {Component} from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import FooterSection from '../FooterSection/Footer';
import getStart from "./mining-55.gif";
import blockChain from "./blockchain.png";
import vyv from "./vyv.png";
import pos from "./pos.png";
import nf from "./nf.png";
import df from "./df.png";
import pou from "./pou.png";
import mp from "./mp.png";
import {Helmet} from "react-helmet";

class Blockchain extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up"/>
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Helmet>
                        <title>Woooo - Blockchain, Shaping To Be World Biggest Network Of Blockchain</title>
                        <link rel="canonical" href="https://woooo.world/blockchain" />
                    </Helmet>
                    <Header imageData={"/img/logo-white.png"}/>
                    <Breadcrumb title="Shaping To Be World Biggest Network Of Blockchain"/>
                    <div style={{
                        padding: '2em',
                        width: '100%',
                        maxWidth: '1440px',
                        background: '#fff',
                        borderRadius: 20,
                        position: 'relative',
                        zIndex: 9,
                        margin: "0px auto",
                        marginTop: '-6em',
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column"
                    }}>

{/*                        <section style={{background:'#fff'}} className="section service-area bg-gray overflow-hidden ptb_100">*/}
{/*                            <div className="container">*/}
{/*                                <div className="row justify-content-between">*/}
{/*                                    <div className="col-12 col-lg-6 order-2 order-lg-1">*/}
{/*                                        /!* Service Text *!/*/}
{/*                                        <div className="service-text pt-4 pt-lg-0">*/}
{/*                                            <h3 className="text-capitalize mb-2">Call and Mine</h3>*/}
{/*                                            <p style={{marginBottom:20}}>*/}
{/*                                                WOOOO One click Phone Dialing, Chat With friends and Family or Live Broadcast your show to the whole world.</p>*/}

{/*                                            <h3 className="text-capitalize mb-2">What is a Node?</h3>*/}
{/*                                            <p style={{marginBottom:20}}>*/}
{/*                                                Your Mobile device or your PC are the Nodes in our Extensive system of Smart blockchain. They will recognize process and authenticate the blockchain data in our active WOOOO network.*/}
{/*<br/>*/}
{/*                                                All the devices linked to our Woooo blockchain network are nodes, with basic benefits you can always access the setting menu to change the status of your Node to Master node for more rewards.*/}

{/*                                            </p>*/}

{/*                                            <h3 className="text-capitalize mb-2">What is Master Node?</h3>*/}
{/*                                            <p style={{marginBottom:20}}>*/}
{/*                                                The master node will not only produce more hash power to drill more woo coin but still produce MN rewards. Our eco system is farther sharing the stacking rewards (POS) between miners.*/}
{/*                                            </p>*/}

{/*                                        </div>*/}
{/*                                    </div>*/}
{/*                                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block get-start-image">*/}
{/*                                        /!* Service Thumb *!/*/}
{/*                                        <div style={{width:400}} className="service-thumb mx-auto">*/}
{/*                                            <img src={getStart} alt="getStart" />*/}
{/*                                        </div>*/}
{/*                                    </div>*/}
{/*                                </div>*/}
{/*                            </div>*/}
{/*                        </section>*/}

                        {/*<h2 style={{textAlign: 'center', margin: 20}}>Woooo DEX*/}
                        {/*</h2>*/}
                        {/*<p style={{marginTop:10, maxWidth:1000,textAlign:'center',margin:'0px auto'}}>*/}
                        {/*    Elevate Your Crypto Adventure! Immerse yourself in the magic of direct crypto swaps—swift, secure, and sensational.WithWoooo DEX, innovation meets autonomy, bringing you a trading experience like never before. Say goodbye to intermediaries, embrace real-time rates, and step into the future of decentralized excitement. Your crypto journey just got a whole lot more thrilling*/}
                        {/*</p>*/}


                        <h2 style={{textAlign: 'center', marginTop: 50,color:"rgb(32, 166, 232)"}}>Empower Your Vision with EVM Technology
                        </h2>
                        <p style={{maxWidth:1000,textAlign:'center',margin:'20px auto'}}>
                            Embark on a journey into the Woooo Blockchain Universe—an avant-garde realm of seamless and secure decentralized transactions, powered by the fusion of A.I and EVM (Ethereum Virtual Machine) blockchain technology. This dynamic ecosystem, curated by a trailblazing team of experts, is not merely a platform; it's a catalyst for a new era. Woooo integrates EVM technology, revolutionizing how we experience blockchain. From fostering global collaboration to unveiling unrivaled transparency and pioneering tokenomics, Woooo Blockchain orchestrates a symphony of boundless possibilities in the ever-evolving landscape of decentralized innovation
                            <br/>
                            EVM technology reinforces the security of your transactions on Woooo, granting you peace of mind as you confidently explore the decentralized environment, assured of the integrity of your digital interactions.
                        </p>


                        <section style={{background:'#fff'}} className="section service-area bg-gray overflow-hidden ptb_100">
                            <div className="container">
                                <div style={{alignItems:'center'}} className="row justify-content-between">
                                    <div className="col-12 col-lg-8 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-4 pt-lg-0">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-2">Woooo Blockchain</h2>

                                            <b className="text-capitalize mb-2">Connect, chat, and call with WOOOO—the magic happens when your phone becomes a Node on our brilliant blockchain network. Instant conversations become mining adventures, turning every interaction into a rewarding journey!</b>
                                            <p style={{marginTop:20}}>
                                                Woooo Blockchain is a cutting-edge platform designed for seamless and secure decentralized transactions. It leverages innovative technologies like A.I and blockchain to create a dynamic ecosystem
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block get-start-image">
                                        {/* Service Thumb */}
                                        <div style={{width:250}} className="service-thumb mx-auto">
                                            <img src={blockChain} alt="blockChain" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section style={{background:'#fff'}} className="section service-area bg-gray overflow-hidden ptb_60">
                            <div className="container">
                                <div style={{alignItems:'center'}} className="row justify-content-between">
                                    <div className="col-12 col-lg-8 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-4 pt-lg-0">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-2">Woooo Ecosystem</h2>

                                            <b className="text-capitalize mb-2">When you use the Woooo app daily, your everyday activities fuel the Proof-of-Use engine in our blockchain. Your energy behind this engine mines Woooo coin. To safeguard your privacy and ensure a secure online presence, we take a small share from your daily mining. This contribution helps us protect your privacy and keeps the app free for everyone online, forever.</b>
                                            <p style={{marginTop:20}}>
                                                Unleash the Extraordinarily Private and Totally Free Woooo Experience!
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block get-start-image">
                                        {/* Service Thumb */}
                                        <div style={{width:250}} className="service-thumb mx-auto">
                                            <img src={blockChain} alt="blockChain" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section style={{background:'#fff'}} className="section service-area bg-gray overflow-hidden ptb_100">
                            <div className="container">
                                <div style={{alignItems:'center'}} className="row justify-content-between">
                                    <div className="col-12 col-lg-8 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-4 pt-lg-0">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-2">Variable Yield Value</h2>

                                            <b className="text-capitalize mb-2">What is Woooo Variable Yield Value?</b>
                                            <p style={{marginTop:20}}>
                                                represents a dynamic measure of the benefits users gain through their network participation. This term highlights that the value given to users can change over time, reflecting the dynamic nature of our system and its adaptability to various factors.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block get-start-image">
                                        {/* Service Thumb */}
                                        <div style={{width:250}} className="service-thumb mx-auto">
                                            <img src={vyv} alt="vyv" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section style={{background:'#fff'}} className="section service-area bg-gray overflow-hidden ptb_60">
                            <div className="container">
                                <div style={{alignItems:'center'}} className="row justify-content-between">
                                    <div className="col-12 col-lg-8 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-4 pt-lg-0">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-2">Proof of Stake (POS)</h2>

                                            <b className="text-capitalize mb-2">What is Woooo Proof-of-Stake (POS) ?</b>
                                            <p style={{marginTop:20}}>
                                                Explore the Woooo Blockchain Upgrade: Leveraging POS for staking in a contract, coupled with our unique Proof-of-Use feature. The more calls and system use, the more we mine from the staked supply. Simple, efficient, and innovative!
                                            </p>

                                            <h4 style={{marginTop:20,color:"rgb(32, 166, 232)"}}>Tokenomics Perfected: Engineered for Brilliance!
                                                With a solid 60% securely staked in our mining pool,
                                                we guarantee scarcity and set the stage for a potential surge in value.</h4>
                                            </div>
                                    </div>
                                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block get-start-image">
                                        {/* Service Thumb */}
                                        <div style={{width:250}} className="service-thumb mx-auto">
                                            <img src={pos} alt="pos" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section style={{background:'#fff'}} className="section service-area bg-gray overflow-hidden ptb_100">
                            <div className="container">
                                <div style={{alignItems:'center'}} className="row justify-content-between">
                                    <div className="col-12 col-lg-8 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-4 pt-lg-0">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-2">Network Fee</h2>

                                            <b className="text-capitalize mb-2">What is Woooo Network fee ?</b>
                                            <p style={{marginTop:20}}>
                                                Explore the Woooo Blockchain Upgrade: Leveraging POS for staking in a contract, coupled with our unique Proof-of-Use feature. The more calls and system use, the more we mine from the staked supply. Simple, efficient, and innovative!
                                            </p>

                                            </div>
                                    </div>
                                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block get-start-image">
                                        {/* Service Thumb */}
                                        <div style={{width:250}} className="service-thumb mx-auto">
                                            <img src={nf} alt="nf" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section style={{background:'#fff'}} className="section service-area bg-gray overflow-hidden ptb_60">
                            <div className="container">
                                <div style={{alignItems:'center'}} className="row justify-content-between">
                                    <div className="col-12 col-lg-8 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-4 pt-lg-0">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-2">Difficulty Gauge</h2>

                                            <b className="text-capitalize mb-2">What is Woooo Difficulty Gauge?</b>
                                            <p style={{marginTop:20}}>
                                                Unveiling Woooo Blockchain's Cryptic Symphony: A Masterful Dance with Network Difficulty! As it effortlessly navigates the challenge of accommodating more miners, it emerges as the maestro orchestrating blockchain stability and reliability with unparalleled finesse.
                                            </p>
                                            <h4 style={{marginTop:20,color:"rgb(32, 166, 232)"}}>Revolutionizing EVM Blockchain Machines
                                                with a Dazzling Network Value Feature for an Unmatched Experience!</h4>
                                            </div>
                                    </div>
                                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block get-start-image">
                                        {/* Service Thumb */}
                                        <div style={{width:250}} className="service-thumb mx-auto">
                                            <img src={df} alt="df" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section style={{background:'#fff'}} className="section service-area bg-gray overflow-hidden ptb_100">
                            <div className="container">
                                <div style={{alignItems:'center'}} className="row justify-content-between">
                                    <div className="col-12 col-lg-8 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-4 pt-lg-0">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-2">Proof of Use</h2>

                                            <b className="text-capitalize mb-2">What is Woooo Proof of Use?</b>
                                            <p style={{marginTop:20}}>
                                                Embodied by our users, the pulsating heartbeat of Woooo, who ardently embrace daily tasks, affirming their Proof of Use. In the tapestry of our exhilarating journey, they inject life, energy, and an electrifying spark, casting a spell of excitement over every moment of our thrilling adventure together!
                                            </p>
                                            </div>
                                    </div>
                                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block get-start-image">
                                        {/* Service Thumb */}
                                        <div style={{width:250}} className="service-thumb mx-auto">
                                            <img src={pou} alt="pou" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section style={{background:'#fff'}} className="section service-area bg-gray overflow-hidden ptb_60">
                            <div className="container">
                                <div style={{alignItems:'center'}} className="row justify-content-between">
                                    <div className="col-12 col-lg-8 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-4 pt-lg-0">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-2">Mining Pool</h2>

                                            <b className="text-capitalize mb-2">What is Woooo Mining Pool?</b>
                                            <p style={{marginTop:20}}>
                                                Dive into the Symphony of Unity: A Grand Welcome to the Mining Pool Extravaganza! Envision miners seamlessly combining strengths to unlock the blockchain's treasures together. It's more than just mining; it's a harmonious symphony of collaboration, where every participant's note contributes to the grand melody of success.
                                            </p>
                                            </div>
                                    </div>
                                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block get-start-image">
                                        {/* Service Thumb */}
                                        <div style={{width:250}} className="service-thumb mx-auto">
                                            <img src={mp} alt="mp" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <FooterSection/>
                </div>
            </div>
        );
    }
}

export default Blockchain;
