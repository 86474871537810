import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneServiceSection";

class DiscoverSection extends Component {
    state = {
        data: {},
        serviceData: []
    }
    // componentDidMount(){
    //     axios.get(`${BASE_URL}`)
    //         .then(res => {
    //             this.setState({
    //                 data: res.data,
    //                 serviceData: res.data.serviceData
    //             })
    //             // console.log(this.state.data)
    //         })
    //     .catch(err => console.log(err))
    // }
    render() {
        return (
            <section style={{position:'relative',zIndex:4, background:"#f6f9fe"}} className="section discover-area overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                    <div data-aos="zoom-out" className="col-12 col-md-8 col-lg-6 order-2 order-lg-1">
                        {/* Discover Thumb */}
                        <div className="service-thumb discover-thumb mx-auto text-center pt-5 pt-lg-0">
                        <img src='/img/secure-transparent.gif' alt="" />
                        </div>
                    </div>
                    <div data-aos="fade-up" className="col-12 col-lg-6 order-1 order-lg-2">
                        {/* Discover Text */}
                        <div className="discover-text pt-4 pt-lg-0 px-0 px-lg-4">
                        <h2 style={{color:"rgb(32, 166, 232)"}} className="pb-4">My Data, My Rules</h2>
                            <h3 style={{marginBottom:10}}>Seize Command of Your Personal Data with Woooo Blockchain. </h3>
                        {/* Service List */}

                                        <div className="service-text">
                                            <p>Empowered by cutting-edge blockchain technology, you hold the reins to bestow or revoke data access. This exceptional control guarantees transparency and reinforces your consent in every data-sharing venture</p>
                                        </div>
<h3 style={{marginTop:14}}>Join the movement and redefine communication</h3>
                            <a href="/privacy" className="btn sApp-btn mt-4 learnBtn">Learn More</a>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default DiscoverSection;
