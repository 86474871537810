import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server/themeOneFaqSection";

const FAQs = [
    { id: 1,
        question: "What is Woooo?",
        answer: "Woooo is an all-in-one communication app that seamlessly integrates five cutting-edge technologies to cater to your communication needs:",
        li:[ "1.Sign Up: Click on the \"Sign Up\" button on the Woooo app or website.", "2.Provide Information: Enter your email address and mobile phone number.", "3.Complete Your Profile: Fill in additional details, such as your address and profile picture.","4.Verify: Follow any verification steps required for your account.", "5.Get Started: Once you've completed these steps, you're all set to start using Woooo for seamless communication."],
        li1: "In short, Woooo is the ultimate solution for seamless, secure, and multilingual communication."
    },
    { id: 2,
        question: "How Do I Create an Account on Woooo?",
        answer: "Creating an account on Woooo is quick and easy. Just follow these steps:",
        li:[ "1.Sign Up: Click on the \"Sign Up\" button on the Woooo app or website.","2.Provide Information: Enter your email address and mobile phone number.","3.Complete Your Profile: Fill in additional details, such as your address and profile picture.","4.Verify: Follow any verification steps required for your account.","5.Get Started: Once you've completed these steps, you're all set to start using Woooo for seamless communication."],
        li1: "It's that simple! Enjoy the benefits of Woooo and stay connected with ease."
    },
    { id: 3,
        question: "Can I Use Woooo for Both Personal and Professional Meetings?",
        answer: "Absolutely! Woooo is designed to cater to both personal and professional communication needs. Here's why it's perfect for everyone:",
        li:["1.Free for All: Woooo is completely free for both individuals and businesses. You can use it without any cost, making it accessible for everyone.","2.Data Security: We prioritize your data security. Woooo is built to ensure your calls, chats, and important meetings are all conducted in a secure environment.","3.Collaborative Ecosystem: By using Woooo, you become a part of our ecosystem, contributing to creating a safer and more secure space for communication." ],
        li1: "So, whether you need to connect with friends and family or conduct important business meetings, Woooo has got you covered. Enjoy secure and hassle-free communication for both personal and professional purposes."
    },
    { id: 4,
        question: "What Are the Fees Associated with Using Woooo?",
        answer: "Great news! Currently, there are no fees associated with using Woooo—it's entirely free for everyone. We believe in providing accessible and seamless communication solutions to our users.",
        li:[ "Future Plans: While Woooo is free at the moment, we are exploring options for a pro version in the future. However, please note that these plans are still in the pipeline and will be communicated to our users well in advance. Rest assured, any future pricing will be designed to provide added value without compromising the quality of our free services. Your satisfaction and ease of communication remain our top priorities."],
        li1: ""
    },
    { id: 5,
        question: "How Can I Get Customer Support or Assistance If I Encounter Issues?",
        answer: "Getting the help you need is easy with Woooo. Here are your options:",
        li:[ "1.Email Support: You can reach out to our dedicated customer support team via email. Send your questions or concerns to support@woooo.com, and we'll get back to you as soon as possible.", "2.In-App Bug and Report Submission (Coming Soon): We're currently working on a feature that will allow you to submit bugs and reports directly through the Woooo app. This will make it even more convenient for you to get assistance when you encounter any issues." ],
        li1: "At Woooo, we're committed to ensuring your experience is smooth and hassle-free. Feel free to reach out, and we'll be ready to assist you promptly."
    },
    { id:6,
        question: "Is My Personal Information and Data Secure When Using Woooo?",
        answer: "Absolutely! At Woooo, privacy and security are our top priorities. Here's why you can trust us with your personal information and data:",
        li:[ "1.Blockchain-Based Security: Woooo is built on blockchain technology, which is known for its robust security features. This ensures that your data is protected by cutting-edge encryption methods.","2.Privacy-Centric: We take your privacy seriously and have implemented stringent measures to safeguard your personal information. Your data is handled with the utmost care and confidentiality.","3.Continuous Improvements: We are dedicated to maintaining the highest security standards. Our team continually works to enhance security measures, keeping your information safe from potential threats."],
        li1: "Rest assured that when you use Woooo, your personal information and data are in secure hands. Your trust is important to us, and we are committed to upholding the highest standards of privacy and security."
    },
    { id: 7,
        question: "Are There Any Upcoming Features or Updates I Should Know About?",
        answer: "Yes, there are exciting developments in the pipeline at Woooo to enhance your experience:",
        li:["1.Expanded Language Models: We are continuously improving our AI with additional language models, making real-time translation even more accurate and comprehensive. Stay tuned for updates on new languages added.","2.Enhanced User Experience: Our team is hard at work on several new features aimed at providing a better user experience and improved security. We'll keep you informed about these exciting additions in the near future." ],
        li1: "At Woooo, we're committed to staying at the forefront of innovation to ensure you have the best communication experience possible. Be sure to check for updates regularly, as we have some exciting improvements coming your way!"
    },
    { id: 8,
        question: "How Do I Report Bugs or Provide Feedback About the Woooo App?",
        answer: "Your feedback is invaluable to us! Here's how you can report bugs or share feedback regarding the Woooo app:",
        li:[ "1.Contact Us or Support Page: You can reach out to us through our dedicated \"Contact Us\" or \"Support\" page on our website. Feel free to describe the issue you're facing or provide your feedback, and our team will assist you promptly.","2.In-App Bug Reporting (Coming Soon): We're actively working on implementing a bug reporting feature directly within the app. This will make it even more convenient for you to report issues and provide feedback without leaving the Woooo environment. Stay tuned for this upcoming feature!"],
        li1: "Your input helps us improve and ensure that Woooo meets your expectations. We appreciate your active involvement in making our app even better."
    },
    { id: 9,
        question: "What Sets Woooo Apart from Other Similar Apps in the Market?",
        answer: "Woooo stands out from the competition for several compelling reasons:",
        li:[ "1.All-in-One Communication: Unlike traditional apps that specialize in either calling, chatting, or meetings, Woooo seamlessly integrates all these essential communication features into a single platform. It's your one-stop solution for all communication needs.","2.Woooo EVM-Based Blockchain: Woooo is powered by its own EVM-based blockchain, which not only ensures secure and transparent transactions but also offers a decentralized wallet for storing cryptocurrencies. Plus, you have the unique opportunity to mine and earn Woooo coins on a daily basis, becoming a part of one of the world's largest blockchain networks.", "3.Simplified Connectivity: Woooo brings people from all around the world together, breaking down language barriers with real-time translation. No need to switch between multiple apps for multilingual communication—Woooo has you covered."],
        li1: "By choosing Woooo, you're joining a revolutionary communication platform that combines convenience, security, and the exciting potential of blockchain technology. Welcome to the future of communication!"
    },
    { id: 10,
        question: "Is Woooo Available for All Platforms (iOS, Android, Desktop)?",
        answer: "Absolutely! Woooo is designed to be accessible across various platforms:",
        li:[ "1.Android: You can easily download and use the Woooo app on Android devices from the Google Play Store.", "2.iOS: Woooo is also available on iOS devices, so you can enjoy seamless communication on iPhones and iPads via the App Store.", "3.Desktop (Web Access): For desktop users, you can access Woooo through the Chrome browser. It's user-friendly and provides the full app experience.", "Upcoming Platforms: We're continually working to expand our reach. Stay tuned for native software versions for Windows, Linux, and Mac, which are currently in development. These will further enhance your Woooo experience on desktop platforms."],
        li1: "Woooo is committed to providing a versatile and user-friendly experience across a wide range of devices and operating systems. Keep an eye out for exciting updates in the near future!"
    },
    { id: 11,
        question: "What are the security features of the decentralized wallet?",
        answer: "Security Features of the Decentralized Wallet:",
        li:[ "1.Blockchain Encryption: Your wallet data is protected by robust blockchain encryption, ensuring it remains tamper-proof and secure.", "2.Private Key Control: You have full control over your private keys, safeguarding access to your funds.", "3.Multi-Factor Authentication: Enhance security with multi-factor authentication, adding an extra layer of protection to your wallet.", "4.Decentralization: The wallet operates on a decentralized network, reducing the risk of centralized vulnerabilities.", "5.Immutable Ledger: Transactions are recorded on an immutable ledger, preventing unauthorized alterations."],
        li1: ""
    },
    { id: 12,
        question: "Can I Connect My Existing Crypto Wallet to Woooo?",
        answer: "Certainly! Woooo offers compatibility with over 300 active wallets. Please review the provided list to check if your wallet is supported for seamless integration. Connecting your existing wallet to Woooo is made easy to enhance your crypto experience.",
        wallets : [
            "MetaMask", "Crypto.com (DeFi)", "Ledger Live", "Rainbow", "Trust Wallet",
            "Argent", "Gnosis Safe multisig", "Pillar", "imToken", "ONTO",
            "TokenPocket", "MathWallet", "BitPay", "WallETH", "Authereum",
            "Dharma", "1inch Wallet", "Huobi Wallet", "Eidoo", "MYKEY",
            "Loopring Wallet", "TrustVault", "Atomic", "Coin98", "CoolWallet",
            "Alice", "AlphaWallet", "D’CENT Wallet", "ZelCore", "Nash",
            "Coinomi", "GridPlus", "CYBAVO Wallet", "Tokenary", "Torus",
            "Spatium", "SafePal", "Infinito", "wallet.io", "Infinity Wallet",
            "Ownbit", "EasyPocket", "Bridge Wallet", "SparkPoint", "ViaWallet",
            "BitKeep", "Vision", "PEAKDEFI Wallet", "Unstoppable Wallet", "HaloDeFi Wallet",
            "Dok Wallet", "AT.Wallet", "Midas Wallet", "Ellipal", "KEYRING PRO",
            "Aktionariat", "Talken Wallet", "XinFin XDC Network", "Flare Wallet", "KyberSwap",
            "AToken Wallet", "Tongue Wallet", "RWallet", "Plasma Pay", "O3Wallet",
            "HashKey Me", "Jade Wallet", "Guarda Wallet", "Defiant", "Trustee Wallet",
            "CoinUs", "cmorq", "Valora", "QuiverX", "Celo Wallet",
            "Elastos Essentials", "fuse.cash", "Bitpie", "Rabby", "Stasis",
            "JulWallet", "f(x) Wallet", "Bull App", "Anybit", "bitpie",
            "Minerva Wallet", "ArchiPage", "Tangem", "Chainge Finance", "ioPay",
            "Coinhub", "Go Pocket", "Wallet 3", "yiToken", "DID Wallet",
            "StarBase", "Shinobi Wallet", "Steakwallet", "GD Wallet", "Binana",
            "AirGap", "PayTube", "SecuX", "BlockBank", "Orange",
            "NEFTiPEDiA", "Avacus", "Krystal", "Linen", "CeloTerminal",
            "Ambire Wallet", "Spot", "Frontier"
        ],
        li1: "This extensive list ensures that you can easily connect your preferred crypto wallet to Woooo for a seamless and secure experience."
    },
    { id: 13,
        question: "Is My Data and Communication Secure on Woooo?",
        answer: "Absolutely! Your security is our top priority. At Woooo, we implement stringent security measures to safeguard your data and communication. You can trust us to provide extreme levels of security and protection for your peace of mind.",
    },
    { id: 14,
        question: "Is There a Referral Program or Rewards System for Users Who Invite Others to Use Woooo?",
        answer: "Indeed, we have exciting plans in the pipeline regarding referral and rewards programs. We're actively considering various options to enhance your experience and reward your support for Woooo. Stay tuned for updates, as we'll keep you informed about these upcoming programs very soon. Your loyalty and referrals mean a lot to us!",
    },
    { id: 15,
        question: "How and Where Can I Buy Woooo Coin?",
        answer: "Purchasing Woooo coin is a straightforward process. Currently, you can acquire Woooo coins through our official website during the ongoing pre-sale period. Additionally, we have plans to launch our Initial Coin Offering (ICO) on major online exchanges in the near future. This will provide you with more options for acquiring Woooo coins through established cryptocurrency platforms.",
        li1: "Keep an eye on our website and official announcements for updates on when and where you can buy Woooo coins. We appreciate your interest and participation in the Woooo ecosystem!"
    },
    { id: 16,
        question: "What Are the System Requirements for Using Woooo?",
        answer: "Woooo is designed to be accessible and efficient across a wide range of devices:",
        li:[ "Android and iOS Devices: Woooo is compatible with most Android and iOS devices, ensuring you can use it seamlessly on your smartphone or tablet without requiring high-end specifications.","Desktop Browsers: You can access Woooo through the Google Chrome browser on PC, Mac, and Linux platforms, making it convenient for desktop users."],
        li1: "Woooo is intentionally designed to be lightweight and energy-efficient, prioritizing battery conservation on mobile devices. This means you can enjoy the benefits of Woooo without the need for extensive computing power, ensuring a smooth experience on a variety of platforms."
    },
    { id: 17,
        question: "Is There a community or Forum Where Users Can Interact and Share Experiences?",
        answer: "Absolutely! Woooo has ambitious plans to bring together a thriving community of users who share a vision for a decentralized ecosystem. With a goal of reaching 10 million users within two years, we aim to create a community where you, the users, play a central role in shaping and operating this ecosystem. Our community will be at the heart of this revolution, where participation, mining, and user-driven governance will be key principles. We're dedicated to creating a future where we collectively contribute to the success of Woooo without relying on traditional advertising or sales methods.",
        li1: "Stay tuned to join the Woooo community and be a part of this exciting journey toward a new future!"
    },
    { id: 18,
        question: "How Do I Download and Install Woooo?",
        answer: "Downloading and installing Woooo is a breeze! Here's how to get started on your preferred device:",
        li:[ "Android: Visit the Google Play Store, search for \"Woooo,\" and click \"Install\" to download the app on your Android device.", "iOS (Apple): Go to the App Store, search for \"Woooo,\" and tap \"Download\" to install Woooo on your Apple device.", "Desktop (Web Access): To access Woooo on your desktop, simply open the Google Chrome browser and log in to our app. For even more convenience, stay tuned for upcoming native software releases for Windows, Linux, and Mac platforms."],
        li1: "Woooo is designed to be accessible on a wide range of devices, ensuring you can enjoy seamless communication no matter your preferred platform. Keep an eye out for our upcoming desktop software releases!"
    },
    { id: 19,
        question: "Can I Use Woooo on Multiple Devices?",
        answer: "Yes, you can use Woooo on multiple devices with a single user ID. However, please note that you can only use Woooo on one mobile phone and one PC simultaneously with the same user ID. This ensures flexibility in how you access and utilize Woooo while maintaining security and user convenience.",
    },
    { id: 20,
        question: "Can I Make International Calls with Woooo?",
        answer: "Certainly! With Woooo, you can make international calls to anywhere in the world, as long as the person you're calling is also using our app.",
        li1: "The best part is that you can use the live translation feature during your call, even if the person you're calling speaks a different language. This makes international communication smoother and more accessible, ensuring that language barriers won't stand in the way of your conversations."
    },
    { id: 21,
        question: "Can I export or backup my wallet data? ",
        answer: "Yes, you can easily export or backup your wallet data using MetaMask, ensuring the safety and security of your cryptocurrency holdings.",
    },
    { id: 22,
        question: " What blockchain networks are supported by the decentralized wallet? ",
        answer: "Our decentralized wallet, like MetaMask, supports a wide range of blockchain networks, particularly those based on the Ethereum Virtual Machine (EVM). This compatibility allows you to interact with various blockchain ecosystems seamlessly.",
    },
    { id: 23,
        question: "Can I connect my existing crypto wallet to Woooo?",
        answer: "Yes, you can connect your existing decentralized crypto wallet, such as MetaMask, to our app. This integration enables you to leverage your current wallet for interactions within the Web3 environment.",
    },
    { id: 24,
        question: "How does the decentralized Web 3 crypto wallet work within the app? ",
        answer: "How does the decentralized Web 3 crypto wallet work within the app? ",
    },
    { id: 25,
        question: "What blockchain networks are supported by Woooo's wallet?",
        answer: "Our wallet supports a wide array of blockchain networks, particularly those built on the Ethereum Virtual Machine (EVM). By connecting through MetaMask and other wallets, you gain access to these diverse blockchain ecosystems.",
    },
    { id: 27,
        question: "How Do I Invite Friends to Join Woooo?",
        answer: "Inviting friends to join Woooo is easy and rewarding:",
        li:[ "1.Share Link: Inside the app, you'll find a simple way to share Woooo with your friends. Just use the provided link to invite them.","2.Upcoming Rewards: We have exciting plans in the works! Stay tuned for updates on rewards and incentives for those who introduce Woooo to their friends and family. Your support will be acknowledged and appreciated in a special way."],
        li1: "Get ready to share the Woooo experience and be a part of our growing community!"
    },
    { id: 26,
        question: "Can I Use Woooo on Wi-Fi?",
        answer: "Absolutely, you can use Woooo on Wi-Fi. In fact, we recommend using Wi-Fi, as it can help you conserve your mobile data usage, particularly when engaging in activities like video calls or sending media-rich messages. Enjoy seamless communication without worrying about your data plan.",
    },
    { id: 28,
        question: "What Happens if I Lose My Phone or Get a New One?",
        answer: "Losing your phone or upgrading to a new one is not a problem with Woooo. Simply reinstall the app on your new device and log in using your phone number. Your chat history and contacts will seamlessly transfer over, ensuring you can pick up right where you left off.",
        li1: "Additionally, our smart contract on the blockchain helps users secure their personal chat data on the Woooo blockchain. This means you always have access to your own data, anytime, using your private keys, further enhancing your control and data security."
    },
    { id: 29,
        question: "How Does Real-Time Translation Work on Woooo?",
        answer: "Woooo leverages cutting-edge machine learning and language processing technologies to deliver real-time translation. During a meeting or conversation, when someone speaks in a language different from that of the other participants, the platform works its magic. It automatically translates the speaker's words into the selected language of the listeners, ensuring that communication is seamless, inclusive, and effortless for everyone involved.",
    },
    { id: 30,
        question: "What Languages Are Supported for Real-Time Translation on Woooo?",
        answer: "What Languages Are Supported for Real-Time Translation on Woooo?",
    },
    { id: 31,
        question: "How Can I Enable Real-Time Translation in a Meeting on Woooo?",
        answer: "Enabling real-time translation in a meeting on Woooo is straightforward. Follow these steps:",
        li:[ "1.During the meeting, look for the AI icon. When it's set to red, it indicates that live translation is enabled.","2.Additionally, you can customize your translation experience by selecting your preferred language from the available options in the meeting settings."],
        li1: "With these simple steps, the platform will automatically translate spoken content into your chosen language, ensuring seamless and inclusive communication during the meeting."
    },
    { id: 32,
        question: "Is the Real-Time Translation on Woooo Accurate?",
        answer: "At Woooo, we are committed to delivering accurate real-time translations, and our technology is continuously advancing. While it provides precise translations in most scenarios, it's important to note that, like any automated translation system, it may not be flawless for highly technical or specialized content. We are dedicated to enhancing accuracy and user experience with ongoing improvements.",
    },
    { id: 33,
        question: "Do I Need to Download Any Additional Software or Apps for Real-Time Translation?",
        answer: "No, there's no need to download any additional software or apps for real-time translation on Woooo. We've seamlessly integrated this feature into our platform, providing you with a hassle-free and user-friendly experience. Everything you need is built-in, ensuring you get the best of the best without any extra downloads.",
    },
    { id: 34,
        question: "Can I Use Woooo for Both Business and Personal Meetings with Real-Time Translation?",
        answer: "Absolutely! Woooo is incredibly versatile and suitable for various types of meetings, whether it's business conferences, educational webinars, or personal gatherings. Real-time translation is a valuable feature that enhances communication in any setting. The best part is that it's all free to use, with no limitations on time or translation. Enjoy seamless communication for both your professional and personal needs with Woooo.",
    },
    { id: 35,
        question: "Is Woooo Compatible with Different Devices and Operating Systems?",
        answer: "Absolutely! Woooo is engineered to be fully compatible with a wide array of devices and operating systems. You can seamlessly access and enjoy Woooo from your computer, smartphone, or tablet, regardless of whether you're using iOS, Android, Windows, or macOS. Our aim is to provide a flexible and accessible experience for all users.",
    },
    { id: 36,
        question: "How Can I Get Started with Woooo and Experience Real-Time Translation for Myself?",
        answer: "Getting started with Woooo and experiencing the power of real-time translation is easy:",
        li:[ "1.Sign up for an account on Woooo.", "2.Schedule or join a meeting.","3.Explore the real-time translation feature."],
        li1: "We're confident that once you experience it for yourself, you'll discover how Woooo can truly transform the way you communicate globally. Welcome to a new era of seamless and inclusive communication!"
    },
    { id: 37,
        question: "What Is Real-Time Translation in One-to-One and Group Chats?",
        answer: "Real-time translation in one-to-one and group chats is a remarkable feature that effortlessly breaks down language barriers. It allows users to communicate seamlessly with others who speak different languages. With just a simple click on the AI button and a momentary wait for it to turn red, real-time translation kicks in, automatically translating messages in real time.",
        li:[ "This feature ensures that language differences are no longer an obstacle to effective communication. In group chats, you can have participants from over 12 countries, each speaking their own language, all interacting effortlessly with just one click. It's the magic of global communication at your fingertips."],
    },
    { id: 38,
        question: "How Does Real-Time Translation Work in One-to-One Chats?",
        answer: "How Does Real-Time Translation Work in One-to-One Chats?",
        li:[ "1.The platform automatically detects the language of the received message.","2.It then translates the message into the language of your choice."],
        li1: "This seamless process ensures that you can have a fluid and natural conversation with someone who speaks a different language, eliminating any language barriers."
    },
    { id: 39,
        question: "How Does Real-Time Translation Work in Group Chats?",
        answer: "In group chats, it's as simple as clicking the AI icon, and when it turns red, you're ready to communicate with your group members in over 12 languages simultaneously. Real-time translation operates seamlessly in this setting. When a message is sent in a different language, the platform automatically translates it for all participants in the group chat. This ensures that everyone can understand and actively engage in the conversation, regardless of their language. It's a powerful feature that fosters inclusive communication in diverse group settings.",
    },
    { id: 40,
        question: "Can I Choose My Preferred Language for Real-Time Translation?",
        answer: "Yes, you can select your preferred language for real-time translation in both one-to-one and group chats. This customization allows you to receive messages in a language you are comfortable with.",
    },
    { id: 41,
        question: "Can I Disable Real-Time Translation if I Don't Need It?",
        answer: "Absolutely, you have the freedom to enable or disable real-time translation based on your preferences. To do this, simply click on the AI icon. When it's blue, it indicates that the translation service is off, and your messages will not be automatically translated. This level of control allows you to customize your chat experience and use the feature when it's most beneficial for you. You can conveniently manage this setting in the chat settings menu.",
    },
    { id: 42,
        question: "What Is a Web 3 Crypto Wallet?",
        answer: "A Web 3 crypto wallet is a digital wallet tailored for the Web 3.0 era, marked by decentralized, blockchain-based technologies. This type of wallet enables you to securely store, manage, and engage in transactions involving cryptocurrencies and digital assets. It's your gateway to the decentralized digital economy of the future.",
    },
    { id: 43,
        question: "How Does a Web 3 Crypto Wallet Work?",
        answer: "A Web 3 crypto wallet, like MetaMask, is designed to interact with blockchain networks and enable users to securely manage their cryptocurrencies and decentralized applications (DApps) through a web browser extension or a mobile app. Here's how a Web 3 crypto wallet like MetaMask typically works:",
        li:[ "Installation and Setup:\n" +
        "        ◦ Users install the Web 3 crypto wallet as a browser extension (e.g., for Chrome or Firefox) or as a mobile app (e.g., for iOS or Android).\n" +
        "        ◦ During the initial setup, users create a wallet or import an existing one using a recovery seed phrase (also known as a mnemonic phrase). This seed phrase is a series of words that serves as the master key to the wallet.", "Connection to Blockchain Networks:\n" +
        "        ◦ The wallet allows users to connect to various blockchain networks, such as Ethereum, Binance Smart Chain, or others.\n" +
        "        ◦ When a user selects a specific network, the wallet connects to a blockchain node (a computer on the network) to retrieve real-time blockchain data and interact with the network."," Wallet Dashboard:\n" +
        "        ◦ Once connected to a network, users access a wallet dashboard within the Web 3 wallet interface.\n" +
        "        ◦ The dashboard displays the user's cryptocurrency balances, transaction history, and other relevant information for the selected network.", "Interacting with DApps:\n" +
        "        ◦ One of the primary functions of a Web 3 crypto wallet is to enable users to interact with decentralized applications (DApps).\n" +
        "        ◦ When a user accesses a DApp that supports Web 3 wallet integration, the DApp requests permission to connect to the user's wallet.\n" +
        "        ◦ Users can grant or deny access to their wallet, ensuring they have control over which DApps can access their funds.","Transaction Processing:\n" +
        "        ◦ Users can initiate cryptocurrency transactions directly from their wallet interface.\n" +
        "        ◦ When sending cryptocurrency, users specify the recipient's address, the amount to send, and any additional transaction details.\n" +
        "        ◦ The wallet generates the transaction and signs it with the user's private key (stored securely within the wallet).\n" +
        "        ◦ The signed transaction is then broadcasted to the blockchain network for processing.", "Smart Contract Interaction:\n" +
        "        ◦ Many DApps use smart contracts to execute various functions and transactions.\n" +
        "        ◦ The Web 3 wallet provides a user-friendly interface for interacting with these smart contracts. Users can approve token transfers, stake tokens, participate in liquidity pools, and more.", "Security and Private Keys:\n" +
        "        ◦ The wallet is designed to keep users' private keys secure and never exposes them to external parties.\n" +
        "        ◦ Users' private keys remain on their local device and are used to sign transactions locally, ensuring that users maintain full control over their funds.", " Backup and Recovery:\n" +
        "        ◦ Users are strongly advised to securely back up their wallet's recovery seed phrase. Losing access to this seed phrase can result in permanent loss of funds.\n" +
        "        ◦ The recovery phrase allows users to regain access to their wallet in case of loss, theft, or device failure."],
        li1: " Backup and Recovery:\n" +
            "        ◦ Users are strongly advised to securely back up their wallet's recovery seed phrase. Losing access to this seed phrase can result in permanent loss of funds.\n" +
            "        ◦ The recovery phrase allows users to regain access to their wallet in case of loss, theft, or device failure."
    },
    { id: 44,
        question: "Is My Private Key Safe in a Web 3 Crypto Wallet?",
        answer: "Your private key is a critical component of your Web 3 crypto wallet's security. Web 3 crypto wallets, like MetaMask, employ robust encryption and security measures to protect your private key. However, it's essential to understand that the safety of your private key relies on a combination of factors, including the wallet's design and your own actions.",
        li:[ "Local Storage: Web 3 crypto wallets store your private key locally on your device, enhancing security by reducing the exposure to remote attacks.", "Encryption: MetaMask, for instance, requires you to set up a strong password or PIN, adding an extra layer of security to protect your private key. This means that even if someone gains access to your device, they would need both the password and the encrypted private key to access your funds.", "Seed Phrase Backup: Upon creating a wallet, you'll receive a recovery seed phrase (mnemonic phrase) consisting of 12 or 24 words. This is your lifeline in case you lose access to your wallet. Safeguard it diligently, as anyone with your seed phrase can recover your wallet."],
    },
    { id: 45,
        question: "Is a Web 3 Crypto Wallet Different from Traditional Wallets?",
        answer: "Is a Web 3 Crypto Wallet Different from Traditional Wallets?",
        li:[ "Decentralization: Web 3 crypto wallets are decentralized and user-controlled, granting you complete ownership and control of your funds. In contrast, traditional wallets like bank accounts are centralized and managed by financial institutions."],
        li1: "The shift to Web 3 crypto wallets represents a fundamental change in how individuals manage and control their digital assets, placing power and autonomy firmly in the hands of the user."
    },
    { id: 46,
        question: "Can I Access My Web 3 Crypto Wallet on Multiple Devices?",
        answer: "Most Web 3 crypto wallets offer multi-device access. You can use the same wallet on different devices, such as your mobile phone and desktop computer, by importing your private key or using a seed phrase for recovery.",
    },
    { id: 47,
        question: "What Are Seed Phrases and Why Are They Important?",
        answer: " A seed phrase is a series of words that serve as a backup for your wallet. It allows you to recover your wallet and funds in case you lose access to your private key or device. Safeguarding your seed phrase is crucial for wallet security.",
    },
    { id: 48,
        question: " Can I Use a Web 3 Crypto Wallet for Multiple Cryptocurrencies?",
        answer: " Many Web 3 crypto wallets support a variety of cryptocurrencies. You can use a single wallet to manage multiple digital assets, making it convenient for diversifying your crypto portfolio.",
    },
    { id: 49,
        question: "How Can I Send and Receive Cryptocurrencies with My Web 3 Wallet?",
        answer: "Sending and receiving cryptocurrencies with a Web 3 wallet is straightforward. You provide the recipient's wallet address and specify the amount you want to send. Confirm the transaction, and it will be recorded on the blockchain.",
    },
    { id: 50,
        question: "Is Using a Web 3 Crypto Wallet Secure?",
        answer: "Answer: Web 3 crypto wallets prioritize security, but users must also take precautions. Follow best practices such as enabling two-factor authentication, keeping your private key and seed phrase secure, and verifying the authenticity of wallet software.",
    },
    { id: 51,
        question: "Can I Access My Web 3 Crypto Wallet on Multiple Devices?",
        answer: " Most Web 3 crypto wallets offer multi-device access. You can use the same wallet on different devices, such as your mobile phone and desktop computer, by importing your private key or using a seed phrase for recovery.",
    },
    { id:52,
        question: "What is Woooo Mining?",
        answer: "Woooo Mining is the fundamental process through which new cryptocurrency coins are generated and transactions are verified within the Woooo blockchain ecosystem. Miners actively engage in a concept known as \"PROOF OF USE\" by performing daily tasks such as making five calls to five different people, engaging in five chats, conducting a live meeting once a week, and daily logins. Each of these actions contributes to the mining of Woooo coins on every occasion, while simultaneously enhancing the security and integrity of the network.",
    },
    { id: 53,
        question: "How Do I Start Mining Woooo?",
        answer: "Initiating Woooo mining is as straightforward as performing daily tasks to contribute to the ecosystem's growth. Here's how you can get started:",
        li:[ "1.Make five calls to five different people.","2.Engage in five chat sessions.","3.Conduct a live meeting at least once a week.","4.Log in daily to stay active within the Woooo network."],
        li1: "By consistently carrying out these actions, you'll automatically mine Woooo coins on each occasion. For more detailed instructions and access to mining software, you can typically refer to the Woooo community or mining pool websites. They often provide comprehensive guidance to help you kickstart your mining journey."
    },
    { id: 54,
        question: "What Hardware Do I Need for Woooo Mining?",
        answer: "Woooo mining is designed to be accessible and lightweight, requiring no special hardware. The Woooo blockchain is optimized to operate on a wide range of devices, ensuring that you can contribute to the ecosystem's growth without the need for specialized equipment. This approach is aimed at making mining as inclusive as possible in the current ecosystem.",
    },
    { id: 55,
        question: "Can I Mine Woooo with Regular Computers?",
        answer: "Yes, you absolutely can mine Woooo using regular computers. Woooo is intentionally designed to be lightweight, and when you log in to your Woooo account through a web browser like Chrome, all you need to do is complete your daily and weekly tasks. Each confirmed transaction contributes to the mining of Woooo coins, making it accessible to anyone with a standard computer setup.",
    },
    { id: 56,
        question: "How Long Does It Take to Earn Woooo Through Mining?",
        answer: "Earning Woooo through mining is a straightforward and swift process. The moment you complete your daily tasks, such as making a free call, the mining of Woooo coins begins. Transactions are quickly confirmed, and you'll receive the coins in your wallet along with a notification. It's designed for efficiency and ease of use, ensuring that you can see the results almost instantly.",
    },
    { id: 57,
        question: "How Long Does It Take to Earn Woooo Through Mining?",
        answer: "Earning Woooo through mining is a straightforward and swift process. The moment you complete your daily tasks, such as making a free call, the mining of Woooo coins begins. Transactions are quickly confirmed, and you'll receive the coins in your wallet along with a notification. It's designed for efficiency and ease of use, ensuring that you can see the results almost instantly.",
        li1: "As a company, we take a nominal share to ensure that we can operate the app ad-free while safeguarding your important data. Meanwhile, all users actively contribute to the digital universe by communicating in real life and simultaneously mining Woooo coins. This approach emphasizes a collaborative and rewarding ecosystem where everyone plays a part in its growth and success."
    },
    { id: 58,
        question: "What Is Mining Difficulty in Woooo?",
        answer: ": The mining difficulty in Woooo is determined by several factors, including network conditions and overall network activity. These dynamic factors are considered to ensure the mining process remains robust and secure, adapting to the evolving demands and conditions of the network\n",
    },
    { id: 59,
        question: "Are There Any Risks Associated with Woooo Mining?",
        answer: "Woooo mining is virtually risk-free as it does not involve any additional hardware costs. By participating in the mining process, you are simply becoming a valuable part of the future ecosystem, where we place a high value on your daily usage and contribution to the digital world while communicating. It's a seamless and rewarding way to be a part of the future of communication and digital currency.",
    },
    { id: 60,
        question: "Can I Mine Woooo on Mobile Devices or Laptops?",
        answer: "Yes, you absolutely can! Whether you're using an Android or iOS device or logging in on your desktop, every time you perform a task, you'll be actively mining Woooo coins. The mining process is designed to be accessible and seamless across various devices, ensuring that you can earn rewards wherever you are.",
    },
    { id: 61,
        question: "How Can I Stay Informed About Woooo Mining Updates?",
        answer: "Staying informed about Woooo mining updates is made easy. After you complete your daily or weekly tasks, you will receive a notification immediately, and the Woooo coins you've mined will be transferred directly to your wallet. Additionally, you can always check the Woooo explorer, where live transaction details will be displayed for confirmation on the Woooo blockchain. This ensures that you have real-time access to all the latest mining updates and transactions",
    },
    { id: 62,
        question: "How Can I Start Mining on the Woooo Network?",
        answer: "To begin mining on the Woooo network, all you need to do is sign up for a Woooo account and start using the app for your daily communication needs. Make free calls, engage in chats, and participate in online live meetings with your business associates and friends. Your active participation in the daily app usage will automatically contribute to mining Woooo coins, making it a seamless and rewarding experience.",
    },
    { id: 63,
        question: "Can Anyone Start Woooo Mining?",
        answer: "Absolutely! Anyone who wants to chat and call their friends while utilizing live translation can simply download the app, sign up for an account, and commence mining Woooo coins. It's an inclusive and user-friendly process that's open to all.",
    },
    { id: 64,
        question: "How Do I Receive the Rewards from Woooo Mining?",
        answer: "Receiving your mining rewards is straightforward. They are sent directly to your Woooo wallet address, and you can verify the transaction details through the Woooo blockchain explorer. This transparency ensures that you have a clear record of your mining rewards and can easily access them in your wallet.",
    },
    { id: 65,
        question: "Is Woooo Mining Environmentally Friendly?",
        answer: "Yes, Woooo mining is environmentally friendly. Since it doesn't require any additional hardware or machines, you can participate using just your mobile phone or a desktop with a Chrome browser. This approach minimizes the carbon footprint associated with traditional mining processes, making it a sustainable and eco-conscious choice.",
    },
    { id: 66,
        question: "Is Woooo Secure for Calls and Messages?",
        answer: "Absolutely. Security is paramount to us. Woooo employs robust end-to-end encryption for all calls and messages, guaranteeing the utmost privacy and security for your conversations.",
    },
    { id: 67,
        question: "Can I Make Group Calls with Woooo?",
        answer: "Currently, Woooo is tailored for one-to-one encrypted communication. However, we're actively developing a group call feature to enhance your experience. In the meantime, you can leverage the Woooo live meeting feature to include multiple participants in a live meeting on your mobile or PC, allowing you to connect with a group as needed. It's important to note that Woooo is the only calling service offering one-to-one real-time translation, setting us apart from the rest.",
    },
    { id: 68,
        question: "How Does Voice and Video Quality Compare to Other Apps?",
        answer: "At Woooo, we place a strong emphasis on delivering high-quality voice and video calls. While the quality of your calls may be influenced by your internet connection, our commitment is unwavering—to provide you with the best communication experience possible.",
    },
    { id: 69,
        question: "Can I Customize Notifications and Privacy Settings?",
        answer: "Certainly! Woooo offers a range of customization options for your notification preferences and privacy settings. This enables you to personalize the app according to your specific requirements and preferences.",
    },
    { id: 70,
        question: "How Do I Make Use of Real-Time Translation in a One-to-One Direct Call on Woooo?",
        answer: "Using real-time translation in a one-to-one direct call on Woooo is simple. Just follow these steps:",
        li:[ "1.While making a call, look for the AI button and click on it.","2.When the AI icon turns red, it indicates that real-time translation is active.","3.Your speech will now be automatically translated into the recipient's native language, as specified in their Woooo app settings."],
        li1: "This feature ensures seamless communication even when speaking different languages."
    },
    {
        id: 71,
        question: "What is the Woooo app?",
        answer: "Woooo is a multifunctional app that allows users to chat, call, meet, and mine coins, with real-time translation capabilities. It's designed to facilitate seamless communication across different languages and provide a rewarding user experience."
    },
    {
        id: 72,
        question: "How do I download and install the app?",
        answer: "You can download the Woooo app from the Apple App Store for iOS devices or the Google Play Store for Android devices. Search for 'Woooo,' and follow the installation instructions."
    },
    {
        id: 73,
        question: "What platforms are supported?",
        answer: "Woooo is available on both iOS and Android platforms."
    },
    {
        id: 74,
        question: "How do I create an account?",
        answer: "To create an account, download the app and follow the on-screen prompts to sign up using your email address or social media accounts."
    },
    {
        id: 75,
        question: "How can I reset my password?",
        answer: "If you forget your password, click on the 'Forgot Password' link on the login screen, and follow the instructions to reset it via email."
    },
    {
        id: 76,
        question: "How do I update my profile information?",
        answer: "To update your profile information, go to the 'Profile' section in the app, and click on 'Edit Profile.'"
    },
    {
        id: 77,
        question: "How does the real-time translation work?",
        answer: "The app uses advanced algorithms to translate text and voice messages in real-time, enabling smooth communication between users speaking different languages."
    },
    {
        id: 78,
        question: "What are Woooo coins and how do I earn them?",
        answer: "Woooo coins are a reward currency that users can earn by engaging with the app, such as participating in chats, calls, and meetings. Specific actions and activities will earn you coins, which can be used within the app."
    },
    {
        id: 79,
        question: "How do I make a call or start a chat?",
        answer: "To make a call or start a chat, navigate to the 'Contacts' section, select the person you want to communicate with, and choose either the call or chat option."
    },
    {
        id: 80,
        question: "What should I do if the app crashes?",
        answer: "If the app crashes, try restarting it. If the problem persists, check for updates or reinstall the app. Contact support if needed."
    },
    {
        id: 81,
        question: "How do I report a bug?",
        answer: "To report a bug, go to the 'Settings' section and click on 'Report a Problem.' Provide a detailed description of the issue."
    },
    {
        id: 82,
        question: "How can I contact customer support?",
        answer: "You can contact customer support through the 'Help' section in the app, or by visiting the Woooo support page."
    },
    {
        id: 83,
        question: "What is the Woooo Wallet?",
        answer: "The Woooo Wallet is a feature within the Woooo app that allows users to store and manage their Woooo coins, which can be earned through various activities on the app."
    },
    {
        id: 84,
        question: "How do I access my Woooo Wallet?",
        answer: "You can access your Woooo Wallet by navigating to the 'Wallet' section in the app, where you can view your coin balance and transaction history."
    },
    {
        id: 85,
        question: "How do I earn Woooo coins?",
        answer: "Woooo coins can be earned by engaging in activities such as chatting, calling, participating in meetings, and other interactive features within the app."
    },
    {
        id: 86,
        question: "What can I do with Woooo coins?",
        answer: "Woooo coins can be used within the app for various purposes, such as accessing premium features, sending gifts, and participating in exclusive events or promotions."
    },
    {
        id: 87,
        question: "Is my Woooo Wallet secure?",
        answer: "Yes, the Woooo Wallet is designed with robust security measures to protect your coins and transactions. Always ensure you keep your app updated for the latest security enhancements."
    },
    {
        id: 88,
        question: "Can I transfer Woooo coins to other users?",
        answer: "Currently, Woooo coins are designed for use within the app and cannot be transferred to other users. Check for updates on new features that may allow this in the future."
    },
    {
        id: 89,
        question: "What is a 12-word seed phrase and why is it important?",
        answer: "A 12-word seed phrase is a set of words that acts as a backup for your Woooo Wallet. It is crucial for recovering your wallet if you lose access to the app."
    },
    {
        id: 90,
        question: "What should I do if I lose my 12-word seed phrase?",
        answer: "If you lose your 12-word seed phrase, you will not be able to recover your Woooo Wallet or its contents. We are not responsible for lost seed phrases, so ensure you store them securely and keep multiple backups."
    },
    {
        id: 91,
        question: "What should I do if my Woooo Wallet is hacked or stolen?",
        answer: "If your Woooo Wallet is hacked or stolen, immediately contact customer support for assistance. However, note that we are not responsible for lost funds due to compromised accounts."
    }
];
class FaqSection extends Component {
    static FAQ_BATCH_SIZE = 14;

    state = {
        data: {},
        visibleFaqs: FAQs.slice(0, FaqSection.FAQ_BATCH_SIZE),
        currentBatch: 1,
        showAll: false,
        allowFetchPrevious: false

    }

    fetchPrevious = () => {
        if (this.state.currentBatch > 1) {
            this.setState(prevState => ({
                visibleFaqs: prevState.visibleFaqs.slice(0, prevState.visibleFaqs.length - FaqSection.FAQ_BATCH_SIZE),
                currentBatch: prevState.currentBatch - 1
            }));
        }
    }


    toggleFAQ = id => {
        this.setState(prevState => ({
            activeId: prevState.activeId === id ? null : id
        }));
    }

    loadMore = () => {
        if (this.state.currentBatch * FaqSection.FAQ_BATCH_SIZE < FAQs.length) {
            this.setState(prevState => ({
                visibleFaqs: FAQs.slice(0, (prevState.currentBatch + 1) * FaqSection.FAQ_BATCH_SIZE),
                currentBatch: prevState.currentBatch + 1
            }));
        } else {
            this.setState({ showAll: true });
        }
    }

    revertToFirstBatch = () => {
        this.setState({
            visibleFaqs: FAQs.slice(0, FaqSection.FAQ_BATCH_SIZE),
            currentBatch: 1,
            showAll: false
        });
    }

    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({ data: res.data });
            })
            .catch(err => console.log(err))
    }

    render() {
        const half = Math.ceil(this.state.visibleFaqs.length / 2);
        const leftFaqs = this.state.visibleFaqs.slice(0, half);
        const rightFaqs = this.state.visibleFaqs.slice(half);

        return (
            <section id='faq' className="section faq-area style-two ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            <div className="section-heading text-center">
                                <h2 className="text-light text-capitalize mb-4">Frequently Asked Questions</h2>
                                <p className="d-block text-light">Want to know more about our services?</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion" id="sApp-accordion">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="faq" id="accordion">
                                    {leftFaqs.map((faq, index) => this.renderFAQ(faq, index))}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="faq" id="accordion">
                                    {rightFaqs.map((faq, index) => this.renderFAQ(faq, index + half))}

                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3 mb-5">

                            {this.state.currentBatch > 1 &&
                                <button onClick={this.fetchPrevious} style={{marginRight:20}} className="btn sApp-btn mt-4">Go Back</button>
                            }
                            {!this.state.showAll && FAQs.length > this.state.currentBatch * FaqSection.FAQ_BATCH_SIZE &&
                                <button onClick={this.loadMore} className="btn sApp-btn mt-4 mr-2">View More</button>
                            }

                            {/*{this.state.showAll &&*/}
                            {/*    <button onClick={this.revertToFirstBatch} className="btn sApp-btn mt-4">Go Back</button>*/}
                            {/*}*/}
                        </div>


                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="faq-content">
                                <div className="row justify-content-center">
                                    <p className="text-light text-center pt-4 fw-5">{this.state.data.faqText} <a style={{color:"white",textDecoration:"underline"}} href="/#">{this.state.data.faqTextLink}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    renderFAQ(faq, index) {
        return (
            <div  key={faq.id} className="card">
                <div className="card-header" id={`faqHeading-${faq.id}`}>
                    <div className="mb-0">
                        <h3 style={{fontSize:16, fontWeight:'500'}} className="faq-title" data-toggle="collapse" data-target={`#faqCollapse-${faq.id}`} aria-expanded="true" aria-controls={`faqCollapse-${faq.id}`}>
                            <span className="badge">{index + 1}</span>{faq.question}
                        </h3>
                    </div>
                </div>
                <div id={`faqCollapse-${faq.id}`} className="collapse" aria-labelledby={`faqHeading-${faq.id}`} data-parent="#sApp-accordion">
                    <div className="card-body">
                        <p style={{ color: '#000' }}>{faq.answer}</p>
                        {faq?.li &&
                            <ol>
                                {faq?.li?.map((item, idx) => {
                                    const [boldText, restText] = item.split(':');
                                    return (
                                        <li style={{ fontSize: 14, color: '#000', paddingLeft: '20px' }} key={idx}>
                                            <strong>{boldText}:</strong>{restText}
                                        </li>
                                    );
                                })}
                            </ol>
                        }
                        {faq?.wallets &&
                            <div className="row">
                                {faq.wallets.map((wallet, idx) => (
                                    <div className="col-md-6" key={idx}>
                                        <div style={{ fontSize: 14, color: '#000', paddingLeft: '20px', fontWeight:500 }} className="wallet-item">{wallet}</div>
                                    </div>
                                ))}
                            </div>
                        }
                        <p>{faq?.li1}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default FaqSection;

