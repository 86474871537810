import React, { Component } from 'react';

const initData = {
    heading: "Introducing Stealth Chat",
    headingText: "Your Ultimate Privacy Solution!",
    headingTexttwo: "Activate Stealth Mode with Woooo for impenetrable online protection of your chat. With Stealth Mode on, we create a secure tunnel network around you, making it impossible for anyone to track or trace you. Your chat data and personal information remain hidden from prying eyes.",
    headingSlug: "* Available on iPhone, iPad and all Android devices",
    playImg: "/img/google-play.png",
    appleImg: "/img/app-store.png",
}

class DownloadNew extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <>
            <section style={{padding:"10em 0"}} className="section download-area ptb_100 custom-download-neww">
                <div className="container">
                    <div style={{ background: "rgba(255,255,255,0.1)",
                        backdropFilter: 'blur(10px)',
                        borderRadius: '30px',  // Optional, for rounded corners
                        padding: '40px 20px',      // Optional, for some spacing
                        boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.1)'}} className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Download Text */}
                        <div className="download-text text-center">
                        <h2 className="text-white">{this.state.initData.heading}</h2>
                        <b className="text-white">{this.state.initData.headingText}</b>

                        {/* Store Buttons */}
                        <div className="button-group store-buttons d-flex justify-content-center">
                            <h4 className="text-white">{this.state.initData.headingTexttwo}</h4>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        <section style={{position:'relative',zIndex:4}} className="section service-area overflow-hidden ptb_100">
            <h2 style={{textAlign:'center',marginBottom:80}}>Our Team</h2>
            <div className="container">
                <div className="row justify-content-center flex-direction-mobile">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        {/* Service Text */}
                        <div className="service-text pt-5 pt-lg-0 px-0 px-lg-4">
                            <h2 className="text-capitalize mb-4">Meet Our
                                Exceptional Team of Engineers and Scientists</h2>


                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 order-1 order-lg-2">
                        <div className="service-text">
                            Behind the scenes, our in-house team comprises over 100 brilliant engineers and scientists who are experts in cutting-edge technologies such as A.. and blockchain.
                            After three years of relentless dedication and hard work, they have given life to Woooo, a platform we are immensely proud to introduce to you.
                        </div>
                        {/*<a href="/ceo" className="btn sApp-btn mt-4">LET’S MEET OUR CEO</a>*/}
                    </div>
                </div>
            </div>
        </section>
            </>
        );
    }
}

export default DownloadNew;
