import React, { Component } from 'react'
import CardSwiper from "./CardSwiper";
import video from "./bg.mp4"
import connect from "./connect.png"
import authenticate from "./authenticate.png"
import connected from "./connected.png"

const initData = {
    preHeading: "Awesome",
    preHeadingspan: "Interface",
    heading: "Discover Collect and Sell WOOOO NFT",
    headingText: "WOOOO NFT A non-fungible token (NFT) is a unique identifier that can cryptographically assign and prove ownership of WOOOO digital coin.",
}

const data = [
    {
        image: "/img/nft1.jpeg"
    },
    {
        image: "/img/nft2.jpeg"
    },
    {
        image: "/img/nft3.jpeg"
    },
    {
        image: "/img/nft4.jpeg"
    },
    {
        image: "/img/nft5.jpeg"
    },
    {
        image: "/img/nft6.jpeg"
    },
    {
        image: "/img/nft7.jpeg"
    },
    {
        image: "/img/nft8.jpeg"
    },
    {
        image: "/img/nft9.jpeg"
    },

]

class ScreenshotSection extends Component {
    state = {
        initData: {},
        data: [],
        activeIndex: 0,
        intervalId: null
    }
    componentDidMount(){
        this.setAutoSwipe();
        this.setState({
            initData: initData,
            data: data
        })
    }
    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    setAutoSwipe = () => {
        const intervalId = setInterval(() => {
            this.setState(prevState => ({
                activeIndex: (prevState.activeIndex + 1) % 11 // We have 11 cards
            }));
        }, 2000); // Change every 2 seconds

        this.setState({ intervalId });
    };

    stopAutoSwipe = () => {
        clearInterval(this.state.intervalId);
    };
    render() {
        const { activeIndex } = this.state;
        return (
            <div style={{width:'100%',height:'fit-content',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <section id="screenshots" className="section screenshots-area ptb_100 mobile_apearence">
                <div className="container">
                    <div className="row">

                        {/* Content Section (35% width) */}
                        <div className="col-12 col-lg-4">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-12">
                                    {/* Section Heading */}
                                    <div className="section-heading text-center">
                                        <h2 className="text-capitalize">{this.state.initData.heading}</h2>
                                        <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p>
                                        <p className="d-block d-sm-none mt-4">{this.state.initData.headingTexttwo}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <a href="/nft" className="btn sApp-btn">Explore More</a>
                            </div>
                        </div>

                        {/* Slider Section (65% width) */}
                        <div className="col-12 col-lg-8">
                            {/* App Screenshot Slider Area */}
                            <div className="app-screenshots">
                                {/* Single Screenshot Item */}
                                {data.map((item, idx) => {
                                    return (
                                        <div key={`so_${idx}`} className="single-screenshot">
                                            <a href={item.image} data-fancybox="images">
                                                <img src={item.image} alt="" />
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                    </div>
                </div>
            </section>

                {/*<div className="video-container">*/}
                {/*    <video autoPlay loop muted>*/}
                {/*        <source src={video} type="video/mp4" />*/}
                {/*    </video>*/}

                {/*    <div className="content-on-top">*/}
                {/*        <h2 style={{marginBottom:'3em', marginTop:'4em',color:"#124759"}}>Are you on the hunt for a decentralized <br/> wallet?</h2>*/}
                {/*        <div className='wallet_animated_card_wrapper'>*/}
                {/*            <div className="wallet_card-v1">*/}
                {/*                <img style={{width:90,marginBottom:18}} src="/img/logoDar.png" alt="logo"/>*/}
                {/*                <h3>DECENTRALIZED WALLET</h3>*/}
                {/*            </div>*/}
                {/*            <div className="wallet_card-v1">*/}
                {/*                <div className="card-inner">*/}
                {/*                    <div className="card-front">*/}
                {/*                        <div style={{position:'absolute',top:20,fontSize:26}}>STEP 1</div>*/}
                {/*                        <h3>SELECT YOUR WALLET</h3>*/}
                {/*                    </div>*/}
                {/*                    <div className="card-back">*/}
                {/*                        <img src={connect} alt="connect" style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:20}}/>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="wallet_card-v1">*/}
                {/*                <div className="card-inner">*/}
                {/*                    <div className="card-front">*/}
                {/*                        <div style={{position:'absolute',top:20,fontSize:26}}>STEP 2</div>*/}
                {/*                        <h3>AUTHENTICATE</h3>*/}
                {/*                    </div>*/}
                {/*                    <div className="card-back">*/}
                {/*                        <img src={authenticate} alt="authenticate" style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:20}}/>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="wallet_card-v1">*/}
                {/*                <div className="card-inner">*/}
                {/*                    <div className="card-front"> <div style={{position:'absolute',top:20,fontSize:26}}>STEP 3</div>*/}
                {/*                            <h3>CONNECT</h3></div>*/}
                {/*                    <div className="card-back">*/}
                {/*                        <img src={connected} alt="connected" style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:20}}/>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}

                {/*            <div className="wallet_card-v1">NOW.*/}
                {/*                EFFORTLESSL*/}
                {/*                Y INTEGRATE*/}
                {/*                YOUR EXISTING WEBB WALLET WITH WOOOO*/}
                {/*                FOR*/}
                {/*                ENHANCED*/}
                {/*                SECURITY AND*/}
                {/*                USER-FRIENDLY CONVENIENCE.</div>*/}
                {/*            <div className="wallet_card-v1">BEGIN BY CHOOSING*/}
                {/*                YOUR*/}
                {/*                DECENTRALI*/}
                {/*                ED WALLET*/}
                {/*                FROM THE LIST PROVIDED.</div>*/}
                {/*            <div className="wallet_card-v1">COMPLETE*/}
                {/*                THE*/}
                {/*                AUTHENTICATI ON PROCESS*/}
                {/*                FOR YOUR SELECTED WALLET.</div>*/}
                {/*            <div className="wallet_card-v1">ESTABLISH A*/}
                {/*                SECURE*/}
                {/*                CONNECTION*/}
                {/*                BETWEEN*/}
                {/*                YOUR CHOSEN WALLET AND W0000.</div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="swiper-container-wrapper" style={{ width: '100%', maxWidth: 1360, padding: '10em 2em', zIndex:2 }}>
                    <h3 style={{textAlign:"center"}}>Woooo: Seamlessly Connected with over 200 Leading Blockchain Companies</h3>
                    {/* <CardSwiper direction={false} slidesPerGroup={2} speed={4000} /> */}
                    <CardSwiper direction={true} slidesPerGroup={2} speed={2800} />
                    <p style={{fontSize:18,textAlign:'center',marginTop:50}}>Woooo's extensive network span over 200 premier blockchain enterprises, <br/> ensuring you access the pinnacle of innovation <br/> and excellence </p>
                </div>

            </div>
        );
    }
}

export default ScreenshotSection;
