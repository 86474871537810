import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server-1/themeOneFooterSection";

class FooterSection extends Component {
    state = {
        data: {},
        iconList: [
            {
                "id": 1,
                "link": "facebook",
                "href": "https://www.facebook.com/wooooofficial",
                "iconClass": "fab fa-facebook-f"
            },
            {
                "id": 2,
                "link": "twitter",
                "href": "https://twitter.com/woooo_official",
                "iconClass": "fab fa-twitter"
            },
            {
                "id": 4,
                "link": "instagram",
                "href": "https://www.instagram.com/woooo_official/",
                "iconClass": "fab fa-instagram"
            },
            {
                "id": 4,
                "link": "discord",
                "iconClass": "fab fa-discord",
                "href": "https://discord.gg/Bv6AQyt6",

            },
            {
                "id": 5,
                "link": "telegram",
                "iconClass": "fab fa-telegram",
                "href": "https://t.me/boost/W_WOOOO",
            },
            {
                "id": 6,
                "link": "youtube",
                "iconClass": "fab fa-youtube",
                "href": "https://www.youtube.com/@woooo3317",

            }
        ],
        footerList_1: [
            {
                "id": 1,
                "text": "Home",
                "href":"/#"
            },
            {
                "id": 3,
                "text": "Features",
                "href":"/#features"
            },
            {
                "id": 5,
                "text": "Contact",
                "href":"/#contact"
            },
            {
                "id": 3,
                "text": "Support",
                "href":"/#contact"
            },
        ],
        footerList_2: [
            {
                "id": 1,
                "text": "FAQ",
                "href":"/#faq"

            },
            {
                "id": 2,
                "text": "Privacy Policy",
                "href":"/privacy"
            },
            {
                "id": 2,
                "text": "Terms and Services",
                "href":"/terms"
            },
            {
                "id": 4,
                "text": "Getting Started",
                "href":"/getting-started"
            },

        ],
        footerList_3: [
            {
                "id": 1,
                "image": "/img/google-play-black.png",
                "href":"https://play.google.com/store/apps/details?id=com.wgroup.woooo_app"
            },
            {
                "id": 2,
                "image": "/img/app-store-black.png",
                "href":"https://apps.apple.com/pk/app/woooo/id6476143922"
            }
        ]
    }

    render() {
        return (
            <div>
                <div className="height-emulator d-none d-lg-block" />
                <footer className="footer-area footer-fixed">
                    {/* Footer Top */}
                    <div className="footer-top ptb_100">
                        <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Logo */}
                                <a className="navbar-brand" href="/#">
                                <img className="logo" style={{width:120}} src='/img/logoDar.png' alt="" />
                                </a>
                                <p className="mt-2 mb-3">All-in-one communication app with free unlimited meetings, real-time translation, chat, and call features, and the abilitly to mine Woooo coins.
                                </p>
                                {/* Social Icons */}
                                <div className="social-icons d-flex">
                                    {this.state.iconList.map((item, idx) => {
                                        return(
                                            <a key={`fi_${idx}`} className={item.link} href={item.href} target={'_blank'}>
                                                <i className={item.iconClass} />
                                                <i className={item.iconClass} />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.data.linkText_1}</h3>
                                <ul>
                                    {this.state.footerList_1.map((item, idx) => {
                                        return(
                                            <li key={`flo_${idx}`} className="py-2"><a href={item.href}>{item.text}</a></li>
                                        );
                                    })}
                                </ul>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.data.linkText_2}</h3>
                                <ul>
                                    {this.state.footerList_2.map((item, idx) => {
                                        return(
                                            <li key={`flt_${idx}`} className="py-2"><a href={item.href}>{item.text}</a></li>
                                        );
                                    })}
                                </ul>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.data.linkText_3}</h3>
                                {/* Store Buttons */}
                                <div className="button-group store-buttons store-black d-flex flex-wrap">
                                    {this.state.footerList_3.map((item, idx) => {
                                        return(
                                            <a key={`flth_${idx}`} href={item.href} target={'_blank'}>
                                                <img src={item.image} alt="" />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* Footer Bottom */}
                    <div className="footer-bottom">
                        <div className="container">
                        <div className="row">
                            <div className="col-12">
                            {/* Copyright Area */}
                            <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                {/* Copyright Left */}
                                <div className="copyright-left">© Copyrights {new Date().getFullYear()} Woooo. All rights reserved.</div>

                                {/* Copyright Right */}
                                <div className="copyright-right">Developed By <a target='_blank' href="https://wgroup.tech/">W Group</a></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default FooterSection;
