import React, { Component } from 'react';


class FeatureSection extends Component {

    render() {
        return (
            <section id="woooAsset" style={{position:'relative',zIndex:4}} className="section features-area features-area-new style-two overflow-hidden ptb_100">
                <div className="container">
                    {/*<div className="row justify-content-center">*/}
                    {/*    <div className="col-12 col-md-10 col-lg-7">*/}
                    {/*        /!* Section Heading *!/*/}
                    {/*        <div className="section-heading text-center">*/}

                    {/*            <h2 style={{marginBottom:30}}>Simplify Woooo Coin Mining! With our free app</h2>*/}
                    {/*            <h3> it's as easy as 1, 2, 3, and 4</h3>*/}

                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row align-items-center">

                        {/* Cards on the Left */}
                        <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between'}} className="col-12 col-md-6 col-lg-6">
                            {/* Card 1 */}
                            <div style={{width:'48%',}} className="res-margin">
                                <div style={{
                                    height:250,
                                    marginBottom:40,
                                    background: '#20A6E8',
                                    borderRadius: '10px',
                                    backdropFilter: 'blur(10px)',
                                    cursor:'pointer'
                                }} className="image-box text-center icon-1 p-3">
                                    <div className="icon-text">
                                        <h2 style={{color:'#fff'}} className="mb-2">1.</h2>
                                        <h4 style={{color:'#fff',fontSize:16}}>Connect with 5 different people in 24 hours and earn a reward of 0.0021 coins for each call. Make every conversation count!</h4>
                                    </div>
                                </div>
                            </div>

                            {/* Card 2 */}
                            <div style={{width:'48%',}} className="res-margin">
                                <div style={{
                                    height:250,
                                    marginBottom:40,
                                    background: '#20A6E8',
                                    borderRadius: '10px',
                                    backdropFilter: 'blur(10px)',
                                    cursor:'pointer'
                                }} className="image-box text-center icon-1 p-3">
                                    <div className="icon-text">
                                        <h2 style={{color:'#fff'}} className="mb-2">2.</h2>
                                        <h4 style={{color:'#fff', fontSize:16}}>Engage in vibrant conversations with 5 different people within 24 hours and earn a rewarding 0.0031 coins for each chat. Every connection brings its own treasure!</h4>
                                    </div>
                                </div>
                            </div>

                            {/* Card 3 */}
                            <div style={{width:'48%',}} className="res-margin">
                                <div style={{
                                    height:250,
                                    marginBottom:40,
                                    background: '#20A6E8',
                                    borderRadius: '10px',
                                    backdropFilter: 'blur(10px)',
                                    cursor:'pointer'
                                }} className="image-box text-center icon-1 p-4">
                                    <div className="icon-text">
                                        <h2 style={{color:'#fff'}} className="mb-2">3.</h2>
                                        <h4 style={{color:'#fff',fontSize:16}}>Host a weekly gathering with your business or friends and earn 0.004 coins as a token of appreciation for your meetings!</h4>
                                    </div>
                                </div>
                            </div>

                            {/* Card 4 */}
                            <div style={{width:'48%',}} className="res-margin">
                                <div style={{
                                    height:250,
                                    marginBottom:40,
                                    background: '#20A6E8',
                                    borderRadius: '10px',
                                    backdropFilter: 'blur(10px)',
                                    cursor:'pointer'
                                }} className="image-box text-center icon-1 p-3">
                                    <div className="icon-text">
                                        <h2 style={{color:'#fff'}} className="mb-2">4.</h2>
                                        <h4 style={{color:'#fff',fontSize:16}}>Continuous log in for 7 days (0.021 coin reward)</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Text and Video on the Right */}
                        <div className="col-12 col-md-6 col-lg-6">
                            {/* Text */}
                            <div className="section-heading text-center mb-4">
                                <h2 style={{marginBottom:30}}>Simplify Woooo Coin Mining! With our free app</h2>
                                <h3> it's as easy as 1, 2, 3, and 4</h3>
                            </div>

                            {/* Video */}
                            <div style={{marginLeft:100}} className="coin-video-section">
                                <video src='/img/mining.mp4' autoPlay muted loop style={{width: '90%', height: '100%'}} />
                            </div>
                        </div>
                    </div>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <a href="/woooo-coin" className="btn sApp-btn mt-4">Read More</a>
                        </div>
                    <h2 style={{width:'90%',textAlign:'center',margin:'1em auto',marginTop:'3em'}} >Start Your Woooo Coin Mining Journey Today!</h2>

                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <a className='link' style={{marginRight:10}} href="https://play.google.com/store/apps/details?id=com.wgroup.woooo_app" target={'_blank'}>
                            <img src="/img/google-play-black.png" alt="play-icon"/>
                        </a>
                        <a className='link' href="https://apps.apple.com/pk/app/woooo/id6476143922" target="_blank">
                            <img src="/img/app-store-black.png" alt="play-icon"/>
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}

export default FeatureSection;
