import React, { Component } from 'react';

class ServiceSection extends Component {
    state = {
        data: {},
        serviceData: []
    }
    // componentDidMount(){
    //     axios.get(`${BASE_URL}`)
    //         .then(res => {
    //             this.setState({
    //                 data: res.data,
    //                 serviceData: res.data.serviceData
    //             })
    //             // console.log(this.state.data)
    //         })
    //     .catch(err => console.log(err))
    // }
    render() {
        return (
            <section style={{position:'relative',zIndex:4}} className="section service-area overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center flex-direction-mobile">
                    <div data-aos="fade-right" className="col-12 col-lg-6 order-2 order-lg-1">
                        {/* Service Text */}
                        <div className="service-text pt-5 pt-lg-0 px-0 px-lg-4">
                        <h2 className="text-capitalize mb-4">Introducing Live Translation</h2>

                                        <div className="service-text">
                                            A revolutionary solution that breaks language barriers during meetings and your chat. Engage with individuals from across the globe, transcending language differences effortlessly.
                                        </div>

                                <h3 style={{margin:"20px 0"}} className="service-text">
                                    Unlock a New Era of Global Connection
                                </h3>
                                <div className="service-text">
                                    In today's interconnected world, breaking language barriers is the key to unlocking boundless opportunities. <b>Our Live Translation feature supports over 15 languages, transforming your communication landscape.</b> Whether you're closing a deal, sharing ideas, or expanding your network, Woooo enables seamless conversations that span continents, cultures, and languages.
                                </div>
                        <a href="https://app.woooo.world/" target={'_blank'} className="btn sApp-btn mt-4">LET’S MEET LETS WOOOO</a>
                        </div>
                    </div>
                    <div data-aos="zoom-out-up" className="col-12 col-md-8 col-lg-6 order-1 order-lg-2">
                        {/* Service Thumb */}
                        <div style={{marginTop:'-5em'}} className="service-thumb service-thumb-image mx-auto">
                        <img src='/img/aiPic.png' alt="" />
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ServiceSection;
