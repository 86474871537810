import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAq8HhaOF8azJfHuTe7o53oKmPaz4Z7xec",
    authDomain: "woooo-firebase.firebaseapp.com",
    projectId: "woooo-firebase",
    storageBucket: "woooo-firebase.appspot.com",
    messagingSenderId: "408758855420",
    appId: "1:408758855420:web:41bfe9a6197a70a224d979",
    measurementId: "G-8XFWKCK0XF"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
