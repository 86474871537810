import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { analytics } from './utils/firebaseConfig'



// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes'

function App() {
    useEffect(()=>{
        AOS.init({
            duration: 500,
        });
    },[])
  return (
    <div>
      <MyRouts />
    </div>
  );
}

export default App;
