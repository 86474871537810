import React, {Component} from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import FooterSection from '../FooterSection/Footer';
import dex from "./dex.png";
import dexSec from "./explorer.png";
import {Helmet} from "react-helmet";

class WooooExplorerSection extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up"/>
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Helmet>
                        <title>Woooo - EXPLORER</title>
                        <link rel="canonical" href="https://woooo.world/woooo-explorer" />
                    </Helmet>
                    <Header imageData={"/img/logo-white.png"}/>
                    <Breadcrumb title="WOOOO EXPLORER"/>
                    <div style={{
                        width: '100%',
                        maxWidth: '1440px',
                        background: '#fff',
                        borderRadius: 20,
                        position: 'relative',
                        zIndex: 9,
                        margin: "0px auto",
                        marginTop: '-6em',
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column"
                    }}>

                        {/*<div style={{width:'60%'}} className="service-thumb mx-auto mobile-dex-img">*/}
                        {/*    <img src={dex} alt="dex" />*/}
                        {/*</div>*/}

                        <h4 style={{textAlign: 'center', marginTop: 80,color:"rgb(32, 166, 232)"}}>Unlock Your Conversations: Safeguard all your chat data and call logs in the Woooo Chain, accessible <br/> with your private key. Your data is exclusively yours—we pledge privacy at its finest!
                        </h4>



                        <section style={{background:'#fff'}} className="section service-area bg-gray overflow-hidden ptb_100">
                            <div className="container">
                                <div style={{alignItems:'center'}} className="row justify-content-between">
                                    <div className="col-12 col-lg-8 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-4 pt-lg-0">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-2">Woooo Explorer</h2>
                                            <p style={{marginTop:20,}}>
                                                Embark on aWoooo Coin Adventure! Our explorer unveils the magic of the blockchain—track transactions, explore blocks, and delve into the soul of addresses. Real-time insights, vibrant stats, and the heartbeat ofWoooo—all at your fingertips. Illuminate your journey withWoooo's captivating transparency
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block get-start-image">
                                        {/* Service Thumb */}
                                        <div style={{width:250}} className="service-thumb mx-auto">
                                            <img src={dexSec} alt="dex" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                    <FooterSection/>
                </div>
            </div>
        );
    }
}

export default WooooExplorerSection;
