import React, { Component } from 'react';
import image1 from "./app-features/chat.png"
import image2 from "./app-features/calling_750x1334.3ec99754a2e924992085-min.png"
import image3 from "./app-features/meeting_with_live_translation_750x1334.png"
import image4 from "./app-features/mining_750x1337.bf1c60d4a99c4bd5ac43-min.png"
import image5 from "./app-features/wallet_750x1337.a520c7d6855282d438dc-min.png"

const initData = {
    heading: "Get the App that Connects and Earns",
    headingText: "Unlock the Power of Woooo Blockchain: Let's Discover What Awaits You!",
}

const data = [
    {
        image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADEUlEQVR4nO2az2vUQBTHR6koKFpQkRa7b7IWD14VwV89iD/w4NF/QQpSBO1BT3tSENqDeBKV1W7fW1hE8UeLt54VL3pRBNFqd99LW62iPSjUyFgt67YpzXaTbNL5wINlJ5mX7/dNkkkmSlksFovFYomMnLdaD1a6AOWKJhky8ef3YKXLtKlUkvNWZ9E9CChXNXJZk3gLBZBMAPKAg3xSjXgtaiWI1v4xmTwzcssWnUAzcqGJbm4ztg3weiC+qIkrEYj2Ca4A8gVzLJGK7yxVtgLxy/iE11w8kV/sxPKWyAwA4idxi54XKMORiHeQ98Yu1ieyVNkTugEa5WzcQv2DeyIwgPviF+p3GnBf+AYQ9zfiYAFltGoqPNqgEdDf9AYAyjMzb6jt1ymWD2mS56k2AJAfdA6/WevXN+TfrQOSR6k0AEjeL2XCsqvkbgCUD6kzIEN8prqfTNHdr4nvmgCUfTU5etJnwAA7c+JRshpleq4dZXpee6oMQP5V/eACJN0LnCLd/9p3X/fWmH3SYwDxzH99FN3eedsU3d6aPDOpMQBInlb3kS193mSGeXWY/6q3MfukwwBk7ixMbgyaxxgCyJJ8A0jy9eYClNuJNwBIrtWby+xrDSA7Ajx7CpC9Bnj2Ikj2LuAl9DbIN+rPJTeb7y6AcjngROhVXSs4I14LkLwOaMAlFTZAcj7wHB3lVu1cf9Ec+alWM4MMmgdQzoWrXikFxcqJwAbMVmfGvBXSxG8Xi7/b1PEkaIx2j4dvQH6qFYh/1mdCeAEoP4KMsmWhZ19nN5cBJA9VVDgoR+IWXBsZ4sMqSjTK47hFR74wWk22VM6Yb3riFg8kbmfh43YVB87sas732MQjf1tolSlSMoPugeCvrhox7JnN2oJqBjpooj3KjyZMrkxhvE01Gw7yKY3yKUThXzXKaeV5q1SzkimMt5lF0DCqvqM41qGSgtOg0ZCIqoc1GhJX9UaNhkRX3Q9TySU+Qwyloup+6KJ7TBPfB+QvcxU3v5HvOSRH1Uqi/c7YZhNxH4fFYrFYLBa1IvkNPIxaNgKeBmQAAAAASUVORK5CYII=",
        title: "Woooo Chat With Translation",
        bold: "Unleash the Future of Chat: Where Style Meets Security!",
        subTitle: "Privacy-First Chatting Redefined",
        content: "Dive into a world of unparalleled conversation security with Woooo Chat & Translation!",
        onViewMore: "Elevate your chats to a whole new level as our EVM Blockchain turns every message into an unstoppable force. Immerse yourself in the glamour of data integrity, backed by our chic smart contract with elite access controls. Your privacy takes the spotlight, ensuring a fortress of security for every Woooo user. Redefine your chat with us - because at Woooo, it's not just a\n" +
            "conversation, it's an experience!"
    },
    {
        image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADQklEQVR4nO2aXYjMURTAzyryEUki2+6cO5OPrCIRko8XDx6keFG8kHgQD8ob2ZLy6EHJV03NzDmjoYQXSto8EPGiTfGC/ZhzZmazvsVaf92dtSQfu7N35z93+v/qPv87vzn3nDvnXoCIiIiIiIiIiIjKMCwrkeQqkrwzJN+Q5ZlhbZ2dkilQz8y51j3ZkJw0LP2GNfh9Icvj+dQ9E+oRzOYXDv7SwX/WbWgNxkE9ESfdYFh6hxF8ORNID0O9gJnCViTpG27wZQHSF8sWVoPvNLMsMqzvRxL8z3qgLzDZOx18BlluVBL8zyWXwVfMxcKS0QU/tPaAjxjSo04EkH6IpfMt4BuG5YqjDLAS2ptyHZPAJwzLPWcCykXxFPgEst53KcAemWOUXwa+gKxtTgWUu8Jp8AVDwq4FIMkd8AXDesJ5BpDcAl8wWdnmPANYjoEvJKg4z3H6v2vmUiN4QxA0IGuno9T/iqxbwDeQ5YwLAXGS/eAj8YxsciEgli7OAR9ZdjYYb0hk9FtA94KvGAftEEk/Ixc2go/ESBO2iLnoAnGSFeAjhiXjqB32ePnXOEHFeSOdC/6jJXZhVgz4hmFNOsoCux2eJlIy60/facm1T0CSXchyFklSdsJcEweoZi41IslrVxIM6aO56Z5pv34jQYU1f7l7+GRYjls54R6MKL/P8ZCkbWByHAQNdnZou8X/pIVbQ1qDcYblrlMJ5fvFrhFkzkfDcsBKC++ugPSDSwkVirsZWm3ArG4PW0B5SW+MdEc4EljOhy9gKBtSvxfUsReQfD7RFqWwgx9apO1V3xJz051NzmYGvo7ckItLkeVtzWyHtK6qugSTya+r9BbZ+coWDlVdgMVQfv1ATw9bAOsRCPNGGUk7whQQWlv8gR1/IemDUPY/y5emXMcMqIXXZEh6qfoCNAe1RCwju5HlTXUESH+cdTHUGjG7JVivj7kAkpNQ05csGdmJpC/HKPXve/EAA5PPJyLrQWQtOQue9MHfpks1y4ILpan2tsie30cXvKStVPCZeLZ7LZKcMyz54e93fWJYN0NdEQQNCc4vt0dZO4a3D6+RtDj4MPuVYX1oC52dG9bdW+SIiIiIiIgIqBbfAaePe4CfS0hxAAAAAElFTkSuQmCC",
        title: "Woooo's Secure Direct Calls with Live Translation",
        subTitle: "Elevate Your Calling Experience with Woooo",
        content: "Experience the magic of secure one to-one calls with Woooo! Immerse yourself in crystal clear conversations, fortified by top-notch encryption.",
        onViewMore: "Break language barriers effortlessly with live translation, connecting globally with ease.\n" +
            "Revolutionize your communication game - it's not just a call. it's a Woooo experience!"
    },
    {
        image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE+klEQVR4nO1ZW2gcZRSeVqv1hhfUUk3m/3cTI0YRNAoiXl8UitKCL7WgaMUHEURsS8Rq64OKqHi/FYRIMudMGdCCl75UrT6IUqrWeqlaS0ianXNmW62lXhPNyEm26e5mZjMzOxsC/T/4n5Zz9pzvnP9c/rEsAwMDAwMDAwMDAwMDAwMDg9mCFx7TBaUzezaEC6yjBUXv11MV8FoFtEMD/auRQw00rpF3aaTHC8iLrLmMTs8/SwP1KuR3NfCHCul1Df61SWQV8DIFXJ5wOuYopIMKaWUSfTb4PQr4BQ30gQZ+TwE9WvRKttUqFBy6SSMdiDaenM7NPx0fa6xDd2mk/xo5X0ME8NpYQ9aH8zXQc5XMqZUF/kMB3Zm780UIrlJIozMY7kY67wZXJpCtc4TGtcNLo/RpoGdmkrUhuCU/79eH8xXwN8kMD26skQ3DeQp4Wyrnj2TBUH1WFTfSRUkySQHx4ndKJ+biv3b8a1JE7u0aWTe4IYvzU444dEe1PoX8UmJZ5FvzIQCoNwUBVCvLLzdDwDRCkbenyKAXcyFAIT+Z4k//qSPv0+YI4MFaAmhPcnly8iJgVYq021srS7ubIgD5rxp9wJ+nyJ5ncyGgMOBfnpV1hbSzyQz4pS4YT6XIxmVWXtBInyVgfFwN8BW1cvx+cxlAX1Xrs4GLyVoq7cl1xG5HulABHUqbcgr4gWYIUEBPTwuGG6xufA1pNOl0muEqTC9CCmhM5niZF+plOtyRdgX8d7b0p3GF5UuibFHI92vgPyNkSFqv1SqovsGF0l818isKqF8BP1SE8nkNZWRezxJ9ZK+R3s6BvW2SYQr4TdlJZIc4/419p1hzDapvcKEG/iJd6vOQrMrWXEG3F5wsS4YGQgX0owL6TVJbAQ8r5E808sMypsbJt+O+czTQl8kiT7sLbrkrVpcbdGigNQppi8wJchUm6hPQzxrpLQXBPTYMnZ6L4z0bwgXinLSjJHdWI21Srn9BlK5F/XSSBn5CentMwRuTqxVnvHZ8Jddu6h2hcfE8JC2zqX2gc/KOpV9kZCV1eUWcXtV34DTtBrdNrLRIjkJ6XiPfXeyns+NkZMlKFITpdeQHe8DvTu18m7f/3MxTHPD3BaCbrRwhC1n2rZI4Livj11/kjzP80ZgGXmdtDY+N0Vxpi/6SShtbJ3uGvORMzAsOL5VBR1boSOEwnGcj3RvZ/mY+30kxtpJAIa3M8Af75dEkSp+9sXTpZJonXGSARqStFdzS1VH6pNhKl8iQmY8kJWBnOsVUiqraMhZr5I8yXaOprOJtUYONdBW53yntpG7v2+MaOl9wy12pDEQ6WEC+uFpHmzd8ggZ6Lc0bYILjyotyfVcQ8lPZ69D1jaMPtDxdhGh5tbw9UF6ctVglOLs6+oPOGntdui4V0W6wuiEBGoLbkxtEm6a/+dOOFjl/OI1H6p++NdKrKQL2YEMC7KTrpry81vVXDby5pc4fOdurO418TEm6cNWv65GYbDWNpy0Zf6tlZPCZJecPB2BN9f9LjZgpYBrpMSvN/m9PEtEbdeqLSdMvPxmGm+osKKJ/WZytGuk++d1qFdq84TNmNfqVk2nEbQXajnYCBAro69klgHz5vG7NFSjwl+Q8+MxUA/L/ANosKjPE7y11HGlUvlNYcxW2TILIqybf6djL7VTeHye2RQMDAwMDAwMDAwMDAwOrafwPIEt70jCUaeEAAAAASUVORK5CYII=",
        title: "Online Meeting",
        subTitle: "Engage in Live Meetings with Woooo",
        content: "Where Security Meets Real-time Translation Unleash the Magic of Secure and Unchanging Translation Records.",
        onViewMore: " By immortalizing translated content and its metadata within our blockchain. we shape an invincible and traceable legacy of translations. This underpins transparency and responsibility, forging groundbreaking norms in the realm of translation services during live meetings."
    },
    {
        image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADzUlEQVR4nO2aT2gdRRzHpzSGSpWaqg3W1515r6HaXEOh4klvImLBelDahmB7LUFo9VQ0xWBLDxXxIERK8vL7PVgPeihpPcjzoFKo0BgDkrZpm5q3v99Lix6kl7TpltnUqH270f33drfMB34QmLfzm993fjOz89sIYTAYDAaDwWAwRKaM3C1rzitloDfbYZ4v5G6RByTSkAS6rZDddpr2qX1nGryC5r52B95itebeDAXgeuYCAH+bnQBIs5kLgDSbWoC99nSnt8aRppaD/bdJpMWsBVgeg+/YpiTSh32fu49EFkAiDWUdYGyBgD6ILIACni6+APxLDAHocuEFQLoYWQAJ9HPWAcQ3uhBdAKSzPp3+qoDeu2+YeYBAsDIePbaWdp6ILIBC/szH4fzfGcIvxBj4GYV0UJsE+iZyild558p4geZblwB/GkeAA35Ot441e3R7yb6+MdKggd9t8QV0KEpfFsx16ef1mHzbx+mdyALImrPdNwDkwZXfIDdDrskfheuuaXHmumsk8LlwQhL/YxyDfr8p1xa2iTgov5MA+Kqoux33HX8XLvV5ONgXD4fsq+49WHc7FPIVH4FmYgWvUUgf+SqLvEe3S+TjoWYN6aQIQAJ/EnIpHfOeq/HbAb7i3xYrdsPyu+5K4AWvDgC8K6QAl+Spq+se9FOyrz8a+r1jnF+vjNEmvRR8/Cz2VH8riSSQSKMBKTixBW9sjnJ0dY/R+r/677Wbj0U5Uq3qwjMK+HRA+4hIiordsBTwrYAZHY12KyRHIdeWzfs77POzCvgL/6VBf5bsm8+KJCkD719lRu+EDyCerVaFkkADIg1UHt78/lMY/lKkhTz1xxMSaDLrIFdZFhcq9u8bRJpYMNelkH/KPtiWtJ/cBo2nRDvosZ2nFdAPOZr57zePzj8p2krd7dDVFoW0lFngQHf1y1OssldcLKSXs6gb6JSX4/SSyAW2u1ah0y+Bz6cfOJ/X3yO0T5FHFPCR9FKej4i806tL6UAzic98wB0il8gq70zym4F+67PGmy+KIiGB3k8uA+iwKCIK6ET81I9R08scV5e56Kg+r8NveHTXK2j4lc+KhkT6OkLafyUeBlRASe1/bn5HRZGRyG/F3wCdflE0yujs9moHAVWkcHsB39J9WdB8QxQBCXwsueOv5UT4WOQZ6X1ISfN2SEsSGs+LvCKBBtILPuV6XxKUgV5LWwAL6VWRV54bufG4BJ5LcQ+4pr8hiDxjgdOnb27JB08Xdd+iCPTa053lqrMjsX+PrTo7dJ9Zx2UwGAwGg8FgEA8R9wCJhgEG86AouQAAAABJRU5ErkJggg==",
        title: "Coin Mining",
        subTitle: "Unleash WOOOO's Blockchain Magic",
        bold: 'Mine WOOOO Coins and Elevate Your Experience! ',
        content: "Step into a world of excitement as WOOOO introduces WOOOO Coin mining - your ticket to a universe of rewards!",
        onViewMore: "Accomplish daily and weekly tasks, from dynamic chats to power-packed meetings, unlocking the treasures of W0000 Coins. Redefine your user experience with innovative mining - it's not just engagement, it's a journey of endless possibilities!"
    },
    {
        image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQ0lEQVR4nO2azWoUQRCAO4gHweADuGz1bCILnpTcFPHmScGIfy/gTUT2oogSEV9BJBAvZqsWBgQlN2+i4EX0LN7UbNUkkoskKJIdmVXXQ3SNgf7Z7fqgbgM99XVVD9PdxiiKoiiKoijKjmnka/vq7eIokMxmyOdcBaCcnnpYTJtYqGN3BkiWAOWrJSn9BT9qLqxOhsu8LCcsyU2L3POb+O8A4vlg+QPxnVCJDwQgfzZ5uct78ha7x0PO/CCQewfzYq9/AcQvgif/Yx144z35Rr5cj2P2ZaP66ngXkFH37D/68q0leeUu+tV3zy5+bHpPviJDvjykJF+acQdQWkPKct0S37XI11wGkFy1Hb7QwJUDcQmgMAthhnwqYQHys/r4/sx8uTtdAdRvwQdpCyApszafTFqARX6dtgCS0unvMoyAAEt8PnUBV5IWACgtFeAK0AoQbQEbQZ+nswYg94D4nSXupihgrd4pjvwa25Jc2s5u1NgIgD8kUp0HJCPAdooTWwXIXDoCSOa2js9P0xGAsgHIF6vDj1r+fk9/u22HrTOaAmggYh2Qv233+fETQP8XKgC1AlraAq6A1NeAbOjRWBxRvWOww1EbQUC7OONMQC3/tD+K4/G/BXJvevFDzbgEiJ9HO/skz4xrss7ysSirALnn7dIEIN+OT4DcMt4oywlAuWGJN4MnTrwJyNerdzK+AVo5DMhPAOWL78SrMYH4MbSLQyY0zYXVyWr7yvVV2f51WZLZaqywN0UVRVEURVEUM/J8BwyrKMGkarK/AAAAAElFTkSuQmCC",
        title: "WOOOO Wallet",
        bold: "Boost your Crypto Security with Woooo's Web 3 Wallet",
        content: "Your Fortress of Digital Asset Security, Unleash the Power of the Ultimate Crypto Wallet",
        onViewMore:  "Fortify your digital treasures with the WOOOO Wallet, setting the pinnacle of security standards in the crypto world. Seamlessly manage your BTC, ETH, XRP, Lite Coin, Binance Coin, Polygon, Bitcoin Cash, Dash, and the exclusive Woooo Coin. Effortlessly transfer and withdraw, knowing your assets are guarded by the most trusted shield available."
    }
]

const imageData = [
    {
        id: "1",
        image: image1
    },
    {
        id: "2",
        image:image2
    },
    {
        id: "3",
        image: image3
    },
    {
        id: "4",
        image: image4
    },
    {
        id: "5",
        image: image5
    }
]

class FeatureSection extends Component {
    state = {
        initData: {},
        data: [],
        imageData: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data,
            imageData: imageData
        })
    }
    render() {
        return (
            <section style={{position:'relative',zIndex:4}} id="features" className="section features-area overflow-hidden mt-5 ptb_100">
                <div className="container">
                    <div data-aos="fade-up" className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2>{this.state.initData.heading}</h2>
                        <h3 className=" mt-4">{this.state.initData.headingText}</h3>
                        <p className="d-block d-sm-none mt-4">{this.state.initData.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div data-aos="fade-right" className="col-12 col-md-6 d-flex align-items-center">
                            <div className="features-slider-wrapper overflow-hidden iphone-mockup"> {/* Added 'iphone-mockup' class */}
                                <ul className="features-slider owl-carousel screen-content"> {/* Added 'screen-content' class */}
                                    {this.state.imageData.map((item, idx) => {
                                        return(
                                            <li key={`fi_${idx}`} className="slide-item">
                                                <img src={item.image} alt="" />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div data-aos="fade-up" className="col-12 col-md-6 pt-4 pt-md-0">
                        <ul className="features-item">
                            {this.state.data.map((item, idx) => {
                                return(
                                    <li key={`ff_${idx}`} className="features-item-main">
                                        {/* Image Box */}
                                        <div className="image-box media icon-1 px-1 py-3 py-md-4">
                                        {/* Featured Image */}
                                        <div className="featured-img mr-3">
                                            <img className="avatar-sm" src={item.image} alt="" />
                                        </div>
                                        {/* Icon Text */}
                                            <div className="icon-text media-body align-self-center align-self-md-start">
                                                <h3 style={{fontSize: 26}} className="mb-2">{item.title}</h3>
                                                <p>
                                                    <div style={{fontWeight: 600, color: '#000', fontSize: 16}}>{item?.bold}</div>
                                                    {item.content}
                                                    <button
                                                        style={{background: 'transparent', border: 'none', marginLeft: 4, color: '#20A6E8', outline: 'none'}}
                                                        type="button"
                                                        className="view-more-button"
                                                        data-target={`#collapseContent${idx}`}
                                                        aria-expanded="false"
                                                        onClick={(e) => {
                                                            const target = document.querySelector(`#collapseContent${idx}`);
                                                            const isExpanded = target.classList.contains('show');
                                                            if (isExpanded) {
                                                                e.currentTarget.textContent = 'View More';
                                                                target.classList.remove('show');
                                                            } else {
                                                                e.currentTarget.textContent = 'Hide';
                                                                target.classList.add('show');
                                                            }
                                                        }}
                                                    >
                                                        View More
                                                    </button>
                                                </p>
                                                <div className="collapse" id={`collapseContent${idx}`}>
                                                    {item.onViewMore}
                                                </div>
                                                <h3 style={{marginTop: 12, fontSize: 16}}>{item.subTitle}</h3>
                                            </div>

                                        </div>
                                        {/*<a style={{marginLeft: 66,*/}
                                        {/*    padding: "12px 30px",*/}
                                        {/*    marginBottom: 10}} href="/" className="btn sApp-btn mt-4">Read More</a>*/}
                                    </li>

                                );
                            })}
                        </ul>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FeatureSection;
